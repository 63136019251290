import c from 'classnames';

import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './SlideArrows.module.scss';

export const SlideArrowRight = (clickHandler: () => void, hasNext: boolean) => {
  return hasNext ? (
    <div
      className={c(styles.ArrowContainer, styles.RightArrowContainer)}
      onClick={clickHandler}
      onKeyUp={(e) => isKeyboardEvent(e) && clickHandler()}
      role="button"
      tabIndex={0}
    >
      <ArrowRight className={styles.ArrowIcon} />
    </div>
  ) : null;
};

export const SlideArrowLeft = (clickHandler: () => void, hasPrev: boolean) => {
  return hasPrev ? (
    <div
      className={c(styles.ArrowContainer, styles.LeftArrowContainer)}
      onClick={clickHandler}
      onKeyUp={(e) => isKeyboardEvent(e) && clickHandler()}
      role="button"
      tabIndex={0}
    >
      <ArrowRight className={c(styles.ArrowIcon, styles.ArrowIconLeft)} />
    </div>
  ) : null;
};
