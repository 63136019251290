const Achievements = (props) => (
  <svg viewBox="0 0 23 30" height="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path stroke="#5A5665" d="M6.5 25.5h10v1h-10zm-1-25v15a6 6 0 1 0 12 0V.5h-12z" />
        <path d="M5.5 13.5h-1a4 4 0 1 1 0-8h1m12 8h1a4 4 0 1 0 0-8h-1" stroke="#F63A55" />
        <path stroke="#5A5665" d="M4.5 26.5h14v3h-14zm7-5h1v3h-1z" />
      </g>
    </g>
  </svg>
);

export default Achievements;
