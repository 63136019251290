import { PropsWithChildren } from 'react';

import { useDropzone } from 'react-dropzone';

import { ReactComponent as CloudUpIcon } from '../CloudUp.svg';

import styles from './MediaDropPanel.module.scss';

const MediaDropPanel = (props: PropsWithChildren<MediaProps>) => {
  const { children, onFileInputChange, isDisabled } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileInputChange,
    disabled: isDisabled,
  });

  const inputProps = {
    accept: 'image/*,video.*',
  };

  return (
    <div {...getRootProps()} className={styles.MediaDropPanel}>
      <div style={{ display: isDragActive ? '' : 'none' }} className={styles.DropMask}>
        <CloudUpIcon />
        <p>Drop your files here</p>
      </div>
      <input multiple accept="image/*,video.*" type="file" className={styles.MediaDropPanelInput} />
      {isDragActive && <input multiple {...getInputProps(inputProps)} />}
      {children}
    </div>
  );
};

interface MediaProps {
  mediaFiles: File[];
  onFileInputChange: (files: File[]) => void;
  isDisabled?: boolean;
}

export default MediaDropPanel;
