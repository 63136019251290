import { Activity, POST_TYPES } from '../Feed.types';

import styles from './FeedDeletePostModalContent.module.css';

export const DeletePostModalContent = (props: DeletePostModalContentProps) => {
  const { activity } = props;
  return (
    <span>
      {activity.type === POST_TYPES.USER_POST && activity.bountyCoinValue && (
        <span data-testid="coinInformation">
          You will loose{' '}
          <span className={styles.CoinValueText}>
            {activity.bountyCoinValue} coin{activity.bountyCoinValue > 1 ? 's ' : ' '}
          </span>
          from your stash.{' '}
        </span>
      )}
      All uploaded files will be lost.
    </span>
  );
};

export interface DeletePostModalContentProps {
  activity: Activity;
}
