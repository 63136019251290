import {
  ADD_TOAST,
  ADD_MODAL_TOAST,
  REMOVE_TOAST,
  REMOVE_ALL_TOASTS,
  REMOVE_MODAL_TOAST,
  REMOVE_ALL_MODAL_TOASTS,
} from 'consts/constants';

const initialState = {
  pageToasts: [],
  modalToasts: [],
};

const MAX_TOASTS = 5;
const MAX_MODAL_TOASTS = 3;

export function toasts(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_TOAST: {
      const toasts =
        state.pageToasts.length === MAX_TOASTS ? state.pageToasts.slice(1) : state.pageToasts;
      return {
        ...state,
        pageToasts: [...toasts, payload],
      };
    }

    case ADD_MODAL_TOAST: {
      const modalToasts =
        state.modalToasts.length === MAX_MODAL_TOASTS
          ? state.modalToasts.slice(1)
          : state.modalToasts;
      return {
        ...state,
        modalToasts: [...modalToasts, payload],
      };
    }

    case REMOVE_TOAST:
      const stateWithRemovedToast = state.pageToasts.filter((toast) => toast.id !== payload);
      return { ...state, pageToasts: stateWithRemovedToast };

    case REMOVE_ALL_TOASTS:
      return { ...state, pageToasts: [] };

    case REMOVE_MODAL_TOAST:
      const stateWithModalRemovedToast = state.modalToasts.filter((toast) => toast.id !== payload);
      return { ...state, modalToasts: stateWithModalRemovedToast };

    case REMOVE_ALL_MODAL_TOASTS:
      return { ...state, modalToasts: [] };

    default:
      return state;
  }
}
