const News = (props) => (
  <svg viewBox="0 0 25 22" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(0 -4)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path
          d="M4.498 5.444c.03 6.242.03 12.419-.004 18.56a1.58 1.58 0 0 1-.276.803l18.434.036h.003a1.5 1.5 0 0 0 1.5-1.5v-17.9H4.498z"
          stroke="#5A5665"
        />
        <path
          stroke="#5A5665"
          fill="#D8D8D8"
          d="M7.5 8.5h13v1h-13zm0 14h13v1h-13zm8-11h5v1h-5zm0 4h5v1h-5zm0 4h5v1h-5z"
        />
        <path stroke="#5A5665" d="M2.5 6.5h1v16h-1z" />
        <path d="M4.5 8h-4v14.863a2 2 0 1 0 4 0V8z" stroke="#5A5665" />
        <path stroke="#F63A55" d="M7.5 11.5h6v8h-6z" />
      </g>
    </g>
  </svg>
);

export default News;
