import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { usePrevious } from 'utils/hooks';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious<string>(pathname);

  useEffect(() => {
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname, prevPathname]);

  return null;
};

export default ScrollToTop;
