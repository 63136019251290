import { useEffect, useRef, forwardRef } from 'react';

import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './Toast.module.scss';

type ToastProps = {
  text: string;
  type?: 'error' | 'info' | 'warning' | 'success';
  autoClose?: number;
  toastWrapperClass?: string;
  onDismissClick: () => void;
};

const statusClasses = {
  success: styles.success,
  warning: styles.warning,
  info: styles.info,
  error: styles.error,
};

const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ text, type = 'error', autoClose = 5000, toastWrapperClass = '', onDismissClick }, ref) => {
    const timerRef = useRef<number>();
    useEffect(() => {
      clearTimeout(timerRef.current);
      if (autoClose) {
        timerRef.current = window?.setTimeout(onDismissClick, autoClose);
      }

      return () => {
        window?.clearTimeout(timerRef.current);
      };
    }, [onDismissClick, autoClose]);

    return (
      <div
        role="button"
        tabIndex={0}
        ref={ref}
        className={`${toastWrapperClass} ${statusClasses[type]} ${styles.ToastContainer}`}
        onClick={onDismissClick}
        onKeyUp={(e) => isKeyboardEvent(e) && onDismissClick()}
      >
        <p className={styles.ToastContent}>{text}</p>
      </div>
    );
  },
);

export default Toast;
