import { memo } from 'react';

import { Avatar } from 'design-system/Atoms/Avatar';
import { Card } from 'design-system/Atoms/Card';
import { Divider } from 'design-system/Atoms/Divider';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './ProfileInfo.module.scss';

export interface ProfileInfoProps {
  photoURL?: string;
  name: string;
  email: string;
  role: string;
}

export const ProfileInfo = memo<ProfileInfoProps>(({ photoURL, name, email, role }) => (
  <Card className={styles.Card}>
    <div className={styles.mainContent}>
      <Avatar imgSrc={photoURL} className={styles.avatar} />

      <div>
        <Paragraph variant="bold">{name}</Paragraph>
        <Paragraph className={styles.role}>{role}</Paragraph>
      </div>
    </div>

    <Divider className={styles.divider} />

    <Paragraph>{email}</Paragraph>
  </Card>
));
