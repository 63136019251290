const Add = (props) => (
  <svg viewBox="0 0 25 24" width="100%" {...props}>
    <g fill="#5A5665" fillRule="evenodd">
      <path d="M11 0h3v24h-3z" />
      <path d="M24.5 10.5v3H.5v-3z" />
    </g>
  </svg>
);

export default Add;
