import { memo } from 'react';

import cx from 'classnames';

import styles from './Divider.module.scss';

export interface DividerProps {
  className?: string;
}

export const Divider = memo<DividerProps>(({ className }) => {
  const classes = cx(styles.divider, className);
  return <span className={classes} />;
});
