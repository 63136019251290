import { SyntheticEvent, useState } from 'react';

import c from 'classnames';
import Avatar from 'react-avatar';

import { isAdmin, isPeopleOps, isCommunityTeam, isSuperAdmin } from 'components/Auth/authHelpers';
import { UserApiModel } from 'types/types';
import { useAllowedRoles } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import { Activity, FeedActivityProps } from '../Feed.types';
import FeedPostMenu from '../FeedPostMenu';
import { ReactComponent as MenuIcon } from '../MenuIcon.svg';
import PostMedia from '../PostMedia';

import styles from './PinnedPost.module.scss';

const PinnedPost = (props: PinnedPostProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const canManage = useAllowedRoles([isAdmin, isSuperAdmin, isCommunityTeam, isPeopleOps]);

  const {
    activity: {
      publisherAvatar,
      publisherName,
      mediaImages,
      mediaVideos,
      verb,
      title = '',
      message = '',
    },
    onClickPost,
  } = props;

  const onClickPostContainer = () => {
    onClickPost(props.activity);
  };

  const onClickMenu = (event: SyntheticEvent) => {
    event.stopPropagation();
    setIsMenuVisible(true);
  };

  const onCloseMenu = () => {
    setIsMenuVisible(false);
  };

  const onPostEdit = () => {
    props.onPostEdit(props.activity);
  };

  const onPostRemove = () => {
    props.onPostRemove(props.activity);
  };

  if (verb !== 'post') {
    return null;
  }

  const hasMedia = mediaImages?.length || mediaVideos?.length;

  return (
    <div
      className={styles.PinnedPost}
      onClick={onClickPostContainer}
      onKeyUp={(e) => isKeyboardEvent(e) && onClickPostContainer()}
      role="button"
      tabIndex={0}
    >
      <PostMedia activity={props.activity} pinned />
      <div className={c(styles.OverlayContainer, { [styles.OverlayMedia]: hasMedia })}>
        <div className={styles.TopRightContainer}>
          {canManage && (
            <>
              <button
                data-type="feed-menu-button"
                className={styles.MenuIcon}
                onClick={onClickMenu}
              >
                <MenuIcon />
              </button>
              {isMenuVisible && (
                <div className={styles.PostMenu}>
                  <FeedPostMenu
                    onMenuEdit={onPostEdit}
                    onMenuRemove={onPostRemove}
                    onCloseMenu={onCloseMenu}
                    canManage={canManage}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.OverlayContent}>
          <div className={styles.PostBody} dangerouslySetInnerHTML={{ __html: title || message }} />
          <div className={styles.AuthorContainer}>
            <Avatar round src={publisherAvatar} name={publisherName} maxInitials={2} size="32px" />
            <span>{publisherName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PinnedPostProps extends FeedActivityProps {
  user: UserApiModel;
  onPostEdit: (activity: Activity) => void;
  onPostRemove: (activity: Activity) => void;
  onClickPost: (activity: Activity) => void;
}

export default PinnedPost;
