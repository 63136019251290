import { CURRENCIES, DEFAULT_UNLEASH_BUDGET } from 'consts/constants';
import { formatCurrency } from 'utils/helpers';

export function getUnleashLeftValueFormatted(
  unleashBudget?: Partial<{ used: number; total: number; currency: string }>,
  returnString = false,
) {
  const unleashBudgetUsed = unleashBudget?.used || 0;
  const unleashBudgetTotal = unleashBudget?.total || DEFAULT_UNLEASH_BUDGET;
  const unleashBudgetCurrency = unleashBudget?.currency || CURRENCIES.USD;
  const remainingBudgetValue = unleashBudgetTotal - unleashBudgetUsed;

  return formatCurrency(remainingBudgetValue, unleashBudgetCurrency, returnString);
}
