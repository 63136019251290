import classNames from 'classnames';

import Text from 'components/Text';
import { IMG_LOADING, UnlockedBounties } from 'types/types';

import styles from './UnlockedBy.module.scss';

const TOP_UNLOCKED = 3;
const AVATAR_SIZE = 25;

export type Props = {
  unlockedByList: UnlockedBounties[];
  hasBorderTop?: boolean;
  defaultText?: string;
  shouldLazyLoad?: boolean;
};

export default function UnlockedBy({
  unlockedByList,
  hasBorderTop = true,
  defaultText = 'earned this',
  shouldLazyLoad = false,
}: Props) {
  return (
    <div className={classNames(styles.BountyUnlockedContainer, hasBorderTop && styles.BorderTop)}>
      {unlockedByList.length ? (
        <>
          <div className={styles.BountyUnlockedAvatars}>
            {unlockedByList
              ?.slice(0, TOP_UNLOCKED)
              .reverse()
              .map((granted) => (
                <span
                  className={styles.BountyUnlockedAvatar}
                  key={granted.id}
                  data-testid="userAvatar"
                >
                  <img
                    src={granted._grantedTo.avatarUrl}
                    loading={shouldLazyLoad ? IMG_LOADING.LAZY : undefined}
                    width={AVATAR_SIZE}
                    height={AVATAR_SIZE}
                    alt="avatar"
                  />
                </span>
              ))}
          </div>
          <span className={styles.BountyUnlockedAdditionalUsers}>
            <Text type="uiBold">
              {unlockedByList.length > TOP_UNLOCKED
                ? `+${unlockedByList.length - TOP_UNLOCKED} `
                : ''}
              {defaultText}
            </Text>
          </span>
        </>
      ) : (
        <span className={styles.BountyUnlockedAdditionalUsers}>
          <Text type="uiBold" className={styles.NobodyEarnedText}>
            Nobody earned this yet
          </Text>
        </span>
      )}
    </div>
  );
}
