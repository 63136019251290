import { Component } from 'react';

import { withScope, captureException, showReportDialog } from '@sentry/browser';

import Wrap from '../Wrap';

export class XhqErrorBoundary extends Component {
  state = { error: null, eventId: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <Wrap>
          <h1>Unexpected error occurred!</h1>
          <h2>name: {this.state.error.name}</h2>
          <p>message: {this.state.error.message}</p>
          <a
            href="/"
            onClick={(ev) => {
              ev.preventDefault();
              showReportDialog({ eventId: this.state.eventId });
            }}
          >
            Report a bug
          </a>
        </Wrap>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
