import { queryCache } from 'react-query';

import { definitions } from 'types/apiTypes';
import { Get, UserApiModel } from 'types/types';

import { getApiMutationHook } from './xhqFetcher';

export const useUnleashCorrectionMutation = getApiMutationHook<unknown, { userId: number }>(
  '/users/:userId/unleash-budget/correction',
  { method: 'POST' },
  {
    onSuccess(_result, variables) {
      const userId = variables.params.userId;
      queryCache.invalidateQueries(['/users/:userId/unleash-budget', { params: { userId } }]);
      queryCache.invalidateQueries(['/users/:userId/invoices/unleash', { params: { userId } }]);
      queryCache.invalidateQueries(['/me/unleash-budget']);
    },
  },
);

export const useObjectiveCreateMutation = getApiMutationHook(
  '/objectives',
  { method: 'POST' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/objectives']);
    },
    suspense: false,
    useErrorBoundary: false,
    throwOnError: false,
  },
);

export const useObjectiveUpdateMutation = getApiMutationHook<unknown, { objectiveId: number }>(
  '/objectives/:objectiveId',
  { method: 'PUT' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/objectives']);
    },
    suspense: false,
    useErrorBoundary: false,
    throwOnError: false,
  },
);

export const useObjectiveDeleteMutation = getApiMutationHook<unknown, { objectiveId: number }>(
  '/objectives/:objectiveId',
  { method: 'DELETE' },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: false,
    onSuccess() {
      queryCache.invalidateQueries(['/objectives']);
    },
  },
);

export const useObjectiveRewardTypeDeleteMutation = getApiMutationHook<
  unknown,
  { rewardTypeId: number }
>(
  '/objectives/rewards/types/:rewardTypeId',
  { method: 'DELETE' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/objectives/rewards/types']);
    },
  },
);

export const useObjectiveGrantMutation = getApiMutationHook<unknown, { objectiveId: number }>(
  '/objectives/:objectiveId/grants',
  { method: 'POST' },
);

export const useObjectiveUndoGrantMutation = getApiMutationHook<unknown, { objectiveId: number }>(
  '/objectives/:objectiveId/grants/undo',
  { method: 'POST' },
);

export const usePickTeamMutation = getApiMutationHook(
  '/me/teams/pick',
  { method: 'PATCH' },
  { suspense: false },
);

export const useFavoriteObjectiveCreateMutation = getApiMutationHook<
  unknown,
  { userId: number; objectiveId: number }
>(
  '/users/:userId/objectives/:objectiveId/favorites',
  { method: 'POST' },
  {
    onSuccess(_result, variables) {
      const { userId } = variables.params;
      queryCache.invalidateQueries(['/users/:userId/objectives/favorites', { params: { userId } }]);
    },
  },
);

export const useFavoriteObjectiveDeleteMutation = getApiMutationHook<
  unknown,
  { userId: number; objectiveId: number }
>(
  '/users/:userId/objectives/:objectiveId/favorites',
  { method: 'DELETE' },
  {
    onSuccess(_result, variables) {
      const { userId } = variables.params;
      queryCache.invalidateQueries(['/users/:userId/objectives/favorites', { params: { userId } }]);
    },
  },
);

export const useSendAuthEmail = getApiMutationHook<
  undefined,
  undefined,
  Get<definitions, ['postAuthSendEmailPayload']>
>('/sessions/send-email', { method: 'POST' });

export const useValidateEmailTokenLink = getApiMutationHook<
  Get<definitions, ['postAuthEmailResponse']>,
  undefined,
  Get<definitions, ['postAuthEmailPayload']>
>('/sessions/email', { method: 'POST' });

export const useFeedPost = getApiMutationHook<
  unknown,
  undefined,
  Get<definitions, ['feedPostSubmit']>
>('/feed/post', { method: 'POST' });

export const useFeedUserPostDeleteMutation = getApiMutationHook<unknown, { postId: string }>(
  '/feed/post/:postId',
  { method: 'DELETE' },
);

export const useFeedPostUpdateMutation = getApiMutationHook<
  unknown,
  { postId: string },
  { text: string }
>('/feed/post/:postId', { method: 'PUT' });

export const useFeedPinnedPostDeleteMutation = getApiMutationHook<unknown, { postId: string }>(
  '/feed/pinned/:postId',
  { method: 'DELETE' },
);

export const useFeedPinnedPostUpdateMutation = getApiMutationHook<
  unknown,
  { postId: string },
  Get<definitions, ['feedPinnedPostUpdate']>
>('/feed/pinned/:postId', { method: 'PUT' });

export const useGenerateCloudinaryAuthSignature = getApiMutationHook<
  Get<definitions, ['postFeedMediaAuthResponse']>
>('/feed/media/auth', { method: 'POST' });

export const useModifyTeamMembersMutation = getApiMutationHook<
  unknown,
  { teamId: number; operation: 'add' | 'remove' }
>(
  '/teams/:teamId/members/:operation',
  { method: 'PATCH' },
  { useErrorBoundary: false, throwOnError: true },
);

export const useCreateUserGroupMutation = getApiMutationHook<
  unknown,
  undefined,
  { name: string; _adminId: number; _membersIds: number[] }
>(
  '/user-groups',
  { method: 'POST' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/user-groups']);
    },
    onError(error) {
      throw error;
    },
  },
);

export const useUpdateUserGroup = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { name: string; _adminId: number }
>(
  '/user-groups/:userGroupId',
  { method: 'PUT' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/user-groups']);
    },
    onError(err) {
      throw err;
    },
  },
);

export const useCreateChat = getApiMutationHook<unknown, { userGroupId: number }>(
  '/user-groups/:userGroupId/chat',
  { method: 'POST' },
  {
    onSuccess() {
      queryCache.refetchQueries(['/user-groups']);
    },
    onError(err) {
      throw err;
    },
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const useAddOrRemoveUsersFromGroupChat = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { operation: 'add' | 'remove'; membersList: number[] }
>(
  '/user-groups/:userGroupId/chat/members',
  { method: 'PATCH' },
  {
    onSuccess(_, variables) {
      const { userGroupId } = variables.params;
      queryCache.invalidateQueries([
        '/user-groups/:groupId/chat/members',
        { params: { groupId: userGroupId } },
      ]);
    },
  },
);

export const useDeleteUserGroup = getApiMutationHook<unknown, { userGroupId: number }>(
  '/user-groups/:userGroupId',
  { method: 'DELETE' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/user-groups']);
    },
  },
);

export const useAddRemoveUserToGroup = getApiMutationHook<
  unknown,
  { userGroupId: number; userId: number },
  { operation: 'add' | 'remove' }
>(
  '/user-groups/:userGroupId/members/:userId',
  { method: 'PATCH' },
  {
    onSuccess() {
      queryCache.invalidateQueries(['/user-groups']);
    },
  },
);

export const useManageMultipleUsersToGroupMutation = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { operation: 'add' | 'remove'; membersList: number[] }
>(
  '/user-groups/:userGroupId/members',
  { method: 'PATCH' },
  {
    onSuccess(_, variables) {
      const { userGroupId } = variables.params;
      queryCache.invalidateQueries(['/user-groups/:groupId', { params: { groupId: userGroupId } }]);
      queryCache.invalidateQueries([
        '/user-groups/:groupId/members',
        { params: { groupId: userGroupId } },
      ]);
      queryCache.invalidateQueries(['/user-groups']);
    },
  },
);

export const useRunInvoicesPaymentMutation = getApiMutationHook<
  Get<definitions, ['putRunInvoicesPaymentResponse']>
>(
  '/invoices/run-payment',
  { method: 'PUT' },
  {
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.refetchQueries(['/invoices']);
    },
  },
);

export const useUpdateInvoiceMutation = getApiMutationHook<
  Get<definitions, ['putInvoicesInvoiceIdResponse']>,
  { invoiceId: number }
>(
  '/invoices/:invoiceId',
  { method: 'PUT' },
  {
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.refetchQueries(['/invoices']);
    },
  },
);

export const useDeleteInvoiceItemMutation = getApiMutationHook<unknown, { invoiceItemId: number }>(
  '/invoice-items/:invoiceItemId',
  { method: 'DELETE' },
);

export const useUpdateUserMutation = getApiMutationHook<
  Get<definitions, ['putUsersUserIdResponse']>,
  { userId: number },
  { userData?: Partial<UserApiModel>; updateSource?: 'accounting' | 'profile' }
>('/users/:userId', { method: 'PUT' }, { suspense: false });

export const usePutInvoiceItemMutation = getApiMutationHook<
  Get<definitions, ['putInvoiceItemsInvoiceItemIdResponse']>,
  { invoiceItemId: number },
  Get<definitions, ['putInvoiceItemsInvoiceItemIdPayload']>
>('/invoice-items/:invoiceItemId', { method: 'PUT' });

export const usePostInvoiceItemMutation = getApiMutationHook<
  Get<definitions, ['postInvoiceItemsResponse']>,
  undefined,
  Get<definitions, ['postInvoiceItemsPayload']>
>(
  '/invoice-items',
  {
    method: 'POST',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePostUserBountyListMutation = getApiMutationHook<
  Get<definitions, ['postUsersUserIdBountyListResponse']>,
  { userId: number | undefined }
>(
  '/users/:userId/bounty-list',
  {
    method: 'POST',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePutUserBountyListMutation = getApiMutationHook<
  Get<definitions, ['putUsersUserIdBountyListBountyListIdResponse']>,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId',
  {
    method: 'PUT',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePutUserBountyListBountiesMutation = getApiMutationHook<
  Get<definitions, ['putUsersUserIdBountyListBountyListIdBountiesResponse']>,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId/bounties',
  {
    method: 'PUT',
  },
  { suspense: false, useErrorBoundary: false },
);

export const useDeletetUserBountyListMutation = getApiMutationHook<
  unknown,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId',
  {
    method: 'DELETE',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePostLegendsMutation = getApiMutationHook<
  Get<definitions, ['postLegendsResponse']>,
  undefined,
  Get<definitions, ['postLegendsPayload']>
>(
  '/legends',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.invalidateQueries(['/legends'], { refetchInactive: true });
    },
  },
);

export const usePutLegendsMutation = getApiMutationHook<
  Get<definitions, ['putLegendsLegendIdResponse']>,
  { legendId: number },
  Get<definitions, ['putLegendsLegendIdPayload']>
>(
  '/legends/:legendId',
  {
    method: 'PUT',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.invalidateQueries(['/legends'], { refetchInactive: true });
    },
  },
);

export const useDeleteLegendsMutation = getApiMutationHook<unknown, { legendId: number }>(
  '/legends/:legendId',
  {
    method: 'DELETE',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.invalidateQueries(['/legends'], { refetchInactive: true });
    },
  },
);

export const useGetFeedPost = getApiMutationHook<
  { activity: Get<definitions, ['activity']> },
  { postId: string }
>('/feed/post/:postId', {
  method: 'GET',
});

export const useGetPostParticipants = getApiMutationHook<
  { participantIds: Get<definitions, ['participantIds']> },
  { postId: string }
>('/feed/post/:postId/participants', {
  method: 'GET',
});

export const requestAwStatus = getApiMutationHook<
  { updated: boolean },
  { invoiceId: string | number }
>(
  '/invoices/update-airwallex-invoice-status/:invoiceId',
  {
    method: 'GET',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess() {
      queryCache.refetchQueries(['/invoices']);
    },
  },
);
