import { memo, useRef, useEffect } from 'react';

import { isNil, get } from 'lodash';
import { withRouter } from 'react-router-dom';

import { Loading } from '../Loading';

/**
 * Higher Order Component used for async side effects such as API calls.
 * @param {Array} actionCreators List of functions to be called when props change.
 * @param {string[]=} paths List of names of props to watch for changes. Accepts `lodash.get`-compatible strings. `['location']` by default.
 * @param {{clearOnExit: boolean|undefined}=} options Pass clearOnExit: true in order to clear the redux store when the component is unmounted
 */
export const withApiData = (actionCreators, paths, options) => (WrappedComponent) => {
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const C = memo((props) => {
    const dependencies = Array.isArray(paths) ? paths.map((p) => get(props, p)) : [props.location];

    /**
     * @type MutableRefObject<Promise[]>
     */
    const requests = useRef([]);

    useEffect(() => {
      requests.current = actionCreators.map((actionCreator) => actionCreator(props));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    useEffect(() => {
      return () => {
        requests.current.forEach((promise) => {
          if (promise && promise.abortController) {
            promise.abortController.abort();
          }
          if (options && options.clearOnExit === true && promise.clearOnExit) {
            promise.clearOnExit();
          }
        });
        requests.current = [];
      };
    }, []);

    return <WrappedComponent {...props} />;
  });

  C.displayName = `withApiData(${wrappedComponentName})`;

  return withRouter(C);
};

export const waitForApi = (config) => (WrappedComponent) => {
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const C = memo((props) => {
    const allLoaded = config.every((item) => {
      if (isNil(props[item]) || (isNil(props[item].response) && isNil(props[item].error))) {
        return false;
      }
      return true;
    });

    if (!allLoaded) {
      return <Loading />;
    }

    const newProps = { ...props };
    const errors = {};
    const loadingApiRequests = {};

    for (const item of config) {
      const responseError = get(newProps[item], ['error']);
      const isResponseLoading = get(newProps[item], ['isLoading']);
      newProps[item] = get(newProps[item], ['response', 'data']);

      if (responseError) {
        errors[item] = responseError;
      }
      loadingApiRequests[item] = isResponseLoading;
    }

    return (
      <WrappedComponent errors={errors} loadingApiRequests={loadingApiRequests} {...newProps} />
    );
  });

  C.displayName = `waitForApi(${wrappedComponentName})`;
  return C;
};
