const Help = (props) => (
  <svg viewBox="0 0 30 25" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(0 -3)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M5.43 4h18.14C26.016 4 28 5.991 28 8.448v8.497c0 2.456-1.984 4.448-4.43 4.448H20.8v5.534l-5.513-5.534H5.43C2.984 21.393 1 19.4 1 16.945V8.448C1 5.99 2.984 4 5.43 4z"
        stroke="#5A5665"
        mask="url(#b)"
      />
      <text
        mask="url(#b)"
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={13}
        fontWeight={500}
        fill="#F63A55"
      >
        <tspan x={12.114} y={17}>
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default Help;
