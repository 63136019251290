import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './Text.module.scss';

const Text = ({
  type = 'regular',
  display = 'display',
  className,
  dataTestId = '',
  children,
}: PropsWithChildren<TextProps>) => {
  const typeStyles: TypeStylesInterface = {
    label: styles.Label,
    bold: styles.Bold,
    uiRegular: styles.UiRegular,
    uiBold: styles.UiBold,
    small: styles.Small,
    regular: styles.Regular,
  };

  const displayStyles: DisplayStylesInterface = {
    display: styles.DisplayBlock,
    displayInline: styles.DisplayInlineBlock,
  };
  return (
    <p
      data-testid={dataTestId}
      className={classNames(
        typeStyles[type] || typeStyles.regular,
        displayStyles[display],
        className,
      )}
    >
      {children}
    </p>
  );
};

export default Text;

export interface TextProps {
  type?: keyof TypeStylesInterface;
  display?: keyof DisplayStylesInterface;
  className?: string;
  dataTestId?: string;
}

interface TypeStylesInterface {
  label: string;
  bold: string;
  uiRegular: string;
  uiBold: string;
  small: string;
  regular: string;
}

interface DisplayStylesInterface {
  display: string;
  displayInline: string;
}
