import { memo } from 'react';

import spinnerAnimation from 'assets/spinner.svg';

import styles from './Loader.module.css';

export const Loader = memo(() => {
  return (
    <div className={styles.Loader}>
      <img src={spinnerAnimation} alt="loader" />
    </div>
  );
});

export default Loader;
