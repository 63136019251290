import { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { getVaultStashActionCreator } from 'store/actionCreators';
import { useCurrentUser } from 'utils/hooks';

type VaultStashContextData = {
  getVaultStash: () => Promise<void>;
  coins: number;
  stars: number;
};

interface VaultStashProviderProps {
  children: ReactNode;
}

export const VaultStashContext = createContext({} as VaultStashContextData);

export function VaultStashProvider({ children }: VaultStashProviderProps) {
  const [coins, setCoins] = useState(0);
  const [stars, setStars] = useState(0);
  const loggedUser = useCurrentUser();
  const dispatch = useDispatch();

  const getVaultStash = useCallback(async () => {
    const { data } = await dispatch(getVaultStashActionCreator(loggedUser?.id));

    setCoins(data.coins);
    setStars(data.stars);
  }, [dispatch, loggedUser?.id]);

  useEffect(() => {
    loggedUser && getVaultStash();
  }, [loggedUser, getVaultStash]);

  return (
    <VaultStashContext.Provider value={{ coins, stars, getVaultStash }}>
      {children}
    </VaultStashContext.Provider>
  );
}

export const useVaultStash = () => useContext(VaultStashContext);
