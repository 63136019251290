import invariant from 'invariant';
import { mapValues, isEmpty, isEqual } from 'lodash';
import * as pathToRegexp from 'path-to-regexp';
import QueryString from 'query-string';
import {
  useMutation,
  QueryConfig,
  queryCache,
  usePaginatedQuery,
  MutationConfig,
  QueryObserverConfig,
} from 'react-query';
import type { DefaultRootState } from 'react-redux';
import type { Store } from 'redux';

import { logOutActionCreator } from 'store/actionCreators';
import currentStore from 'store/currentStore';
import { Maybe } from 'types/types';

import { apiUrl } from '../config';
import { useCustomCompareMemo } from './hooks';

function getJSON<R>(res: Response) {
  const contentType = res.headers.get('Content-Type');
  const emptyCodes = [204, 205];

  if (!emptyCodes.includes(res.status) && contentType?.includes('json')) {
    return res.json() as Promise<R>;
  } else {
    return Promise.resolve();
  }
}

type ParamsObject<Params> = Params extends undefined
  ? {
      params?: Params;
    }
  : {
      params: Params;
    };

type XhqBaseQueryOptions<TResult, TError = unknown, TData = TResult> = QueryObserverConfig<
  TResult,
  TError,
  TData
> & { onResponse?: (arg: Response) => any | undefined };

export type XhqFetchOptions<
  Params extends Maybe<object> = undefined,
  Payload extends Maybe<object> = undefined
> = {
  body?: Payload extends object ? Payload : typeof FormData | object;
  query?: object | null;
} & ParamsObject<Params>;

export type XhqQueryConfig = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  baseQuery?: object | null;
  preventLogoutOnUnauthorized?: boolean;
};

const empty: XhqFetchOptions = Object.freeze({});
const createXhqFetcher = <
  Result,
  Params extends Maybe<object>,
  Payload extends Maybe<object> = undefined
>({
  method,
  baseQuery,
  preventLogoutOnUnauthorized,
  onResponse,
}: XhqQueryConfig & XhqBaseQueryOptions<Result>) => {
  const { dispatch, getState }: Store<DefaultRootState> = currentStore();

  return (endpoint: string, options?: Maybe<XhqFetchOptions<Params, Payload>>): Promise<Result> => {
    const { params = empty, body = undefined, query = null } =
      typeof options === 'object' && options ? options : empty;

    invariant(endpoint, 'Missing endpoint!');
    invariant(
      ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].includes(method),
      `Incorrect method: ${method}!`,
    );

    const endpointCompiler = pathToRegexp.compile(endpoint);

    const stringifiedParams = mapValues(params, (value) =>
      typeof value === 'string' ? value : JSON.stringify(value),
    );
    const combinedQuery = baseQuery || query ? { ...baseQuery, ...query } : null;

    const stringifiedQuery = !isEmpty(combinedQuery)
      ? '?' + QueryString.stringify(combinedQuery as {}, { arrayFormat: 'none' })
      : '';

    const isFormData = body instanceof FormData;
    const combinedEndpoint = apiUrl + endpointCompiler(stringifiedParams) + stringifiedQuery;

    const userToken = getState().session?.data?.id;
    const headers = {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      ...(userToken && { Authorization: `Bearer ${userToken}` }),
    };

    const abortController = new AbortController();
    const signal = abortController.signal;
    const promise = window
      .fetch(combinedEndpoint, {
        method,
        mode: 'cors',
        headers,
        body: isFormData ? (body as Blob) : JSON.stringify(body),
        signal,
      })
      .then(async (res: Response) => {
        if (onResponse) {
          return onResponse(res);
        }

        // @todo reconsider this
        if (!preventLogoutOnUnauthorized && res.status === 401) {
          return dispatch(logOutActionCreator() as any);
        }

        const json = await getJSON<Result>(res);
        if (res.status >= 400) {
          throw json;
        }
        return json;
      });

    promise.cancel = () => {
      abortController.abort();
    };

    return promise;
  };
};

type XhqFetcherOptionsParam<
  Params extends Maybe<object> = Record<string, any>,
  Payload extends Maybe<object> = undefined
> = Maybe<XhqFetchOptions<Params, Payload>>;

function prepareParams<Result, Params extends Maybe<object> = undefined>(
  endpoint: string,
  xhqFetcherConfig?: Omit<XhqQueryConfig, 'method'>,
  baseConfig?: Maybe<QueryConfig<Result>>,
  ...args: [(false | true | XhqFetcherOptionsParam<Params>)?, XhqBaseQueryOptions<Result>?]
) {
  const [xhqFetcherOptionsParam, baseReactQueryOptions] = args;
  const shouldWait = args.length > 0 && !xhqFetcherOptionsParam;
  const doFetch = createXhqFetcher<Result, Params>({
    method: 'GET',
    ...xhqFetcherConfig,
    ...baseReactQueryOptions,
  });

  // use just [endpoint] if options are not provided or equal true
  const key: [string, XhqFetchOptions<Params>?] =
    xhqFetcherOptionsParam && xhqFetcherOptionsParam !== true
      ? [endpoint, xhqFetcherOptionsParam]
      : [endpoint];
  return [
    !shouldWait && key,
    doFetch,
    {
      ...baseConfig,
      ...baseReactQueryOptions,
    },
  ] as const;
}

/**
 *
 * @param endpoint Relative path to the API endpoint. Parameters should be marked with `:` as in /users/:id/unleash
 * @param xhqFetcherConfig If you pass a falsy value, the query will not execute. If you don't want any options, pass nothing or `true`. @see https://github.com/tannerlinsley/react-query#dependent-queries
 * @param baseConfig @see https://github.com/tannerlinsley/react-query#usequery
 */
export const getApiQueryHook = <Result, Params extends Maybe<object> = undefined>(
  endpoint: string,
  xhqFetcherConfig?: Omit<XhqQueryConfig, 'method'>,
  baseConfig?: Maybe<QueryConfig<Result>>,
) => {
  const useXhqQuery = (
    ...args: [(false | true | XhqFetcherOptionsParam<Params>)?, XhqBaseQueryOptions<Result>?]
  ) => {
    // memoize created params
    const params = useCustomCompareMemo(
      () => prepareParams(endpoint, xhqFetcherConfig, baseConfig, ...args),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      args,
      isEqual,
    );
    return usePaginatedQuery(...params);
  };

  type XhqQueryHook = typeof useXhqQuery & {
    prefetch(
      xhqOptions?: false | true | XhqFetcherOptionsParam<Params>,
      queryOptions?: XhqBaseQueryOptions<Result>,
    ): Promise<any>;
  };
  /**
   * @description Allow for prefetching outside of React (i.e. before components are rendered). Useful for static data such as currencies, categories, t-shirt sizes etc.
   */
  const useXhqQueryWithPrefetch = useXhqQuery as XhqQueryHook;
  useXhqQueryWithPrefetch.prefetch = (...args) => {
    const params = prepareParams(endpoint, xhqFetcherConfig, baseConfig, ...args);
    return queryCache.prefetchQuery(...params);
  };
  return useXhqQueryWithPrefetch;
};

export const getApiMutationHook = <
  Result,
  Params extends Maybe<object> = undefined,
  Payload extends Maybe<object> = undefined
>(
  endpoint: string,
  xhqFetcherConfig: XhqQueryConfig,
  baseConfig?: Maybe<
    MutationConfig<Result, unknown, NonNullable<XhqFetcherOptionsParam<Params, Payload>>>
  >,
) => {
  const useXhqMutation = (
    config?: Maybe<
      MutationConfig<Result, unknown, NonNullable<XhqFetcherOptionsParam<Params, Payload>>>
    >,
  ) => {
    const doFetch = createXhqFetcher<Result, Params, Payload>(xhqFetcherConfig);

    const memoizedConfig = useCustomCompareMemo(
      () => ({ ...baseConfig, ...config }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [baseConfig, config],
      isEqual,
    );
    return useMutation(
      (xhqFetcherOptionsParam: NonNullable<XhqFetcherOptionsParam<Params, Payload>>) =>
        doFetch(endpoint, xhqFetcherOptionsParam),
      memoizedConfig,
    );
  };
  return useXhqMutation;
};
