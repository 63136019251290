const Dashboard = (props) => (
  <svg viewBox="0 0 30 19" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
      <path id="c" d="M0 0h30v22H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -4)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <g transform="translate(0 1)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <g mask="url(#d)">
              <g transform="translate(-3 3)">
                <circle stroke="#5A5665" cx={18} cy={15} r={14} />
                <circle stroke="#F63A55" fill="#F63A55" cx={18} cy={18} r={3} />
                <path stroke="#F63A55" fill="#F63A55" d="M18 12l1 7h-2z" />
                <path
                  d="M10 6l1.092 1.387M18 3v1.969M25.04 6L24 7.462m7.488 11.188l-2.846.023M4 18.5h27.92"
                  stroke="#5A5665"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path fill="#5A5665" d="M4 13h2v1H4zm20 0h2v1h-2z" />
    </g>
  </svg>
);

export default Dashboard;
