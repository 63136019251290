const Logout = (props) => (
  <svg viewBox="0 0 20 20" width="100%" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard-Icon-Copy-12" transform="translate(-2.000000, -6.000000)" stroke="#5A5665">
        <g id="Group-2" transform="translate(2.000000, 6.000000)">
          <g id="Dashboard-Icon">
            <path d="M11,4 L11,0.5 L0.5,0.5 L0.5,19 L11,19 L11,16 L11,15.0" id="Rectangle-10" />
          </g>
          <g id="Group" transform="translate(6.000000, 6.000000)" stroke="#F63A55">
            <rect id="Rectangle" x="1" y="3.5" width="10" height="0.1" />
            <polyline id="Triangle" points="8.47058824 0 12 3.5 12 3.5 8.47058824 7" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logout;
