import { PropsWithChildren } from 'react';

import c from 'classnames';

import Button from 'components/buttons/Button';

import { FeedModal } from '../FeedModal';

import styles from './FeedConfirmationModal.module.scss';

export const FeedConfirmationModal = (props: PropsWithChildren<FeedConfirmationModalProps>) => {
  return (
    <FeedModal onClose={props.onCancel}>
      <div className={styles.FeedModal}>
        <div>
          <div className={styles.Title}>{props.title}</div>
          <div className={styles.ContentText}>{props.children}</div>
          <div className={c(styles.ContentText, styles.ContentWarning)}>
            Warning: this cannot be undone.
          </div>
        </div>
        <div className={styles.ButtonGroup}>
          <Button isFormBtn isGray onClick={props.onCancel} className={styles.ActionButton}>
            {props.cancelButtonText}
          </Button>
          <Button
            isFormBtn
            isLoading={props.confirmDisabled || false}
            onClick={props.onConfirm}
            className={styles.ActionButton}
          >
            {props.confirmButtonText}
          </Button>
        </div>
      </div>
    </FeedModal>
  );
};

interface FeedConfirmationModalProps {
  title: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmDisabled?: boolean;
}
