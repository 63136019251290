function SvgTeams(props) {
  return (
    <svg
      viewBox="0 0 480 480"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M240 0c-26.51 0-48 21.49-48 48s21.49 48 48 48c26.499-.026 47.974-21.501 48-48 0-26.51-21.49-48-48-48zm0 80c-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zM272 104h-1.888l-1.688.84a63.773 63.773 0 01-56.848 0l-1.688-.84H208c-22.08.026-39.974 17.92-40 40v48c0 13.255 10.745 24 24 24h96c13.255 0 24-10.745 24-24v-48c-.026-22.08-17.92-39.974-40-40zm24 88a8 8 0 01-8 8h-96a8 8 0 01-8-8v-48c.002-12.592 9.735-23.042 22.296-23.936a80.061 80.061 0 0067.408 0c12.56.894 22.294 11.344 22.296 23.936v48zM408 264c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c-.026-26.499-21.501-47.974-48-48zm0 80c-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zM440 368h-1.888l-1.688.8a63.78 63.78 0 01-56.848 0l-1.688-.8H376c-22.08.026-39.974 17.92-40 40v48c0 13.255 10.745 24 24 24h96c13.255 0 24-10.745 24-24v-48c-.026-22.08-17.92-39.974-40-40zm24 88a8 8 0 01-8 8h-96a8 8 0 01-8-8v-48c.002-12.592 9.735-23.042 22.296-23.936a80.061 80.061 0 0067.408 0c12.561.894 22.294 11.344 22.296 23.936v48zM72 264c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c-.026-26.499-21.501-47.974-48-48zm0 80c-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zM104 368h-1.888l-1.688.8a63.78 63.78 0 01-56.848 0l-1.688-.8H40c-22.08.026-39.974 17.92-40 40v48c0 13.255 10.745 24 24 24h96c13.255 0 24-10.745 24-24v-48c-.026-22.08-17.92-39.974-40-40zm24 88a8 8 0 01-8 8H24a8 8 0 01-8-8v-48c.002-12.592 9.735-23.042 22.296-23.936a80.061 80.061 0 0067.408 0c12.561.894 22.294 11.344 22.296 23.936v48z"
        fill="#5A5665"
      />
      <path
        d="M319.372 406.276l-.012-.028a8 8 0 00-10.495-4.224 177.456 177.456 0 01-114.104 7.856l7.488-2.2a8.002 8.002 0 005.432-9.928 8.002 8.002 0 00-9.928-5.432l-32 9.384c-.12 0-.208.136-.32.176a7.928 7.928 0 00-2.064 1.144c-.278.15-.545.318-.8.504a7.75 7.75 0 00-1.96 2.72 8.012 8.012 0 00-.608 3.304c.195 1.083.462 2.152.8 3.2.048.112 0 .24.088.352l16 30.616a8.004 8.004 0 1014.192-7.408l-6.512-12.448a192.565 192.565 0 00130.552-7.104 8 8 0 004.251-10.484zM453.656 210.344a8 8 0 00-11.312 0l-11.32 11.32c-6.624-70.07-51.111-130.876-115.888-158.4a8 8 0 10-6.272 14.72c58.11 24.697 98.504 78.669 105.816 141.384l-9.024-9.024a8 8 0 00-11.312 11.312l24 24a8 8 0 0011.328 0l24-24a8 8 0 00-.016-11.312zM175.768 69.272a7.439 7.439 0 00-.208-1.384c0-.072-.088-.12-.12-.2-.032-.08 0-.136 0-.2a7.673 7.673 0 00-.752-1.104 8.076 8.076 0 00-.912-1.32 7.508 7.508 0 00-1.56-1.064 7.037 7.037 0 00-.896-.648l-32-14.616a8 8 0 00-6.64 14.544l16.568 7.576C86.875 104.237 47.955 169.256 48 240a8 8 0 0016 0c-.04-62.293 32.901-119.952 86.584-151.552l-5.784 12.088a8 8 0 003.736 10.664 7.876 7.876 0 003.464.8 8 8 0 007.2-4.536l16-33.384c0-.08 0-.168.064-.248a7.693 7.693 0 00.432-1.696c.137-.431.239-.872.304-1.32a7.977 7.977 0 00-.232-1.544z"
        fill="#F63A55"
      />
    </svg>
  );
}

export default SvgTeams;
