const SvgComponent = (props) => {
  const id = Math.random();

  return (
    <svg viewBox="0 0 30 30" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path
          d="M2.723,2.878 L0.908,4.105 L3.076,3.787 C6.789,3.242 10.382,3.873 13.408,5.692 C16.466,7.529 18.74,10.456 20.018,14.049 L20.76,16.139 L20.986,13.932 C21.539,8.519 19.761,3.496 16.059,1.271 C14.633,0.415 12.983,0.009 11.235,0.009 C8.472,0.009 5.466,1.024 2.723,2.878 Z M15.544,2.129 C18.378,3.832 19.99,7.462 20.08,11.636 C18.664,8.775 16.571,6.426 13.923,4.835 C11.302,3.259 8.29,2.52 5.146,2.604 C8.831,0.722 12.726,0.436 15.544,2.129 Z"
          id="path-1"
        />
        <path
          d="M2.54,8.195 C0.654,11.335 -0.146,14.821 0.033,18.208 C0.699,18.323 1.353,18.561 1.965,18.929 C2.548,19.279 3.045,19.719 3.449,20.22 C6.584,18.795 9.343,16.428 11.259,13.239 C13.721,9.142 14.332,4.455 13.333,0.17 C8.99,1.26 5.046,4.025 2.54,8.195 Z"
          id="path-3"
        />
        <path
          d="M1.374,9.508 L1.332,9.574 C1.353,9.571 1.372,9.568 1.392,9.565 C1.398,9.582 1.403,9.599 1.408,9.616 L1.446,9.557 C5.677,8.945 7.92,6.947 8.175,3.562 C7.387,5.108 5.816,6.06 3.459,6.42 C2.686,4.128 2.813,2.273 3.843,0.856 C0.942,2.619 0.12,5.504 1.374,9.508 Z"
          id="path-5"
        />
        <path
          d="M0.482,4.459 L0.46,4.494 C0.469,4.491 0.479,4.49 0.489,4.489 C0.492,4.498 0.495,4.507 0.497,4.516 L0.518,4.484 C2.558,4.123 3.662,3.096 3.827,1.402 C3.429,2.186 2.659,2.682 1.521,2.891 C1.179,1.767 1.269,0.846 1.791,0.128 C0.357,1.045 -0.08,2.489 0.482,4.459 Z"
          id="path-7"
        />
        <path
          d="M2.973,2.797 L0.994,3.81 L2.213,4.513 C2.329,4.433 2.743,4.211 3.457,3.846 L3.655,5.345 L4.8,6.007 L4.639,3.76 L6.665,2.775 C5.901,2.334 5.52,2.114 5.52,2.114 L4.196,2.568 C4.116,1.836 4.076,1.408 4.08,1.281 C3.267,0.813 2.86,0.578 2.86,0.578 C2.86,0.578 2.898,1.318 2.973,2.797 Z"
          id="path-9"
        />
      </defs>
      <g id="icon/achivements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <g id={`Group-3-${id}`} transform="translate(0.000000, 9.000000)">
            <mask id={`mask-2-${id}`} fill="#5A5665">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-2" />
            <polygon
              id="Fill-1"
              fill="#5A5665"
              mask={`url(#mask-2-${id})`}
              points="4.4669 -11.5268 32.9379 5.5802 22.4709 22.9992 -6.0001 5.8932"
            />
          </g>
          <g id={`Group-6-${id}`} transform="translate(9.000000, 0.000000)">
            <mask id={`mask-4-${id}`}>
              <use xlinkHref="#path-3" />
            </mask>
            <g id="Clip-5" />
            <polygon
              id="Fill-4"
              fill="#5A5665"
              mask={`url(#mask-4-${id})`}
              points="7.2355 -9.3271 24.4395 1.0099 7.7975 28.7069 -9.4065 18.3689"
            />
          </g>
          <path
            d="M9.02446432,18.038 C8.84546432,14.651 9.64546432,11.165 11.5324643,8.025 C14.0384643,3.855 17.5410675,1.91652068 21.8840675,0.825520683 C22.8830675,5.11052068 22.7124643,8.972 20.2514643,13.069 C18.3354643,16.259 15.5764643,18.626 12.4414643,20.051 C12.0374643,19.549 11.5404643,19.109 10.9574643,18.76 C10.3444643,18.392 9.69046432,18.153 9.02446432,18.038 Z"
            id="Stroke-7"
            stroke="#5A5665"
          />
          <g id={`Group-11-${id}`} transform="translate(3.000000, 20.000000)">
            <mask id={`mask-6-${id}`} fill="white">
              <use xlinkHref="#path-5" />
            </mask>
            <g id="Clip-10" />
            <path
              d="M1.3741,9.5083 C0.1191,5.5033 0.9421,2.6193 3.8431,0.8563 C2.8131,2.2733 2.6861,4.1273 3.4591,6.4203 C5.8151,6.0603 7.3871,5.1083 8.1751,3.5623 C7.9201,6.9463 5.6771,8.9463 1.4461,9.5573 L1.4081,9.6153 C1.4031,9.5993 1.3971,9.5813 1.3931,9.5653 C1.3721,9.5683 1.3531,9.5703 1.3321,9.5733 L1.3741,9.5083 Z"
              id="Stroke-9"
              stroke="#F63A55"
              strokeWidth="2"
              mask={`url(#mask-6-${id})`}
            />
          </g>
          <g id={`Group-14-${id}`} transform="translate(7.000000, 20.000000)">
            <mask id={`mask-8-${id}`} fill="white">
              <use xlinkHref="#path-7" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M0.4816,4.4585 C-0.0804,2.4885 0.3566,1.0455 1.7906,0.1285 C1.2686,0.8465 1.1786,1.7675 1.5206,2.8915 C2.6596,2.6825 3.4286,2.1855 3.8276,1.4025 C3.6616,3.0965 2.5586,4.1235 0.5176,4.4835 L0.4976,4.5155 C0.4956,4.5065 0.4926,4.4975 0.4896,4.4885 C0.4796,4.4895 0.4686,4.4915 0.4596,4.4935 L0.4816,4.4585 Z"
              id="Stroke-12"
              stroke="#F63A55"
              strokeWidth="2"
              mask={`url(#mask-8-${id})`}
            />
          </g>
          <g id={`Group-17-${id}`} transform="translate(13.000000, 6.000000)">
            <mask id={`mask-10-${id}`} fill="white">
              <use xlinkHref="#path-9" />
            </mask>
            <g id="Clip-16" />
            <polygon
              id="Fill-15"
              fill="#F63A55"
              mask={`url(#mask-10-${id})`}
              points="1.0304 -6.2519 13.4954 0.9451 6.6304 12.8361 -5.8356 5.6401"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgComponent;
