const Questions = (props) => (
  <svg viewBox="0 0 28 28" width="100%" {...props}>
    <g transform="translate(1)" fill="none" fillRule="evenodd">
      <circle stroke="#5A5665" cx={13} cy={14} r={13} />
      <text fontFamily="Helvetica" fontSize={14} letterSpacing={1.75} fill="#F63A55">
        <tspan x={9.732} y={19}>
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default Questions;
