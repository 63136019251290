import { useState } from 'react';

import Button from 'components/buttons/Button';
import { useAdminSetting } from 'utils/hooks';

import VideoModal from '../VideoModal';

import styles from './HomeVideoModal.module.scss';

const HomeVideoModal = () => {
  const [trailerModalOpen, setTrailerModalOpen] = useState(false);
  const moduleSettings = useAdminSetting('dashboard-modules');
  const bgImage = moduleSettings?.values.video_banner_image?.location || '';
  const videoId = moduleSettings?.values.video_banner_link || '';

  return (
    <section className={styles.HomeVideoSection}>
      {videoId && (
        <div className={styles.HomeVideo} style={{ backgroundImage: `url(${bgImage})` }}>
          <h5 className={styles.HomeTitle}>{moduleSettings?.values.video_banner_title}</h5>
          <Button
            isInline
            onClick={() => {
              setTrailerModalOpen(true);
            }}
            className={styles.ModalBtn}
          >
            Watch now
          </Button>

          {trailerModalOpen && <VideoModal closeModal={() => setTrailerModalOpen(false)} />}
        </div>
      )}
    </section>
  );
};

export default HomeVideoModal;
