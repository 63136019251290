import { useEffect } from 'react';

// @ts-ignore
import { AvatarGroup } from 'react-activity-feed';

import { SnackbarAlert, SNACKBAR_ALERT_TYPES } from 'components/SnackbarAlert';

import { NotifierProps, Activity } from '../Feed.types';

import styles from '../Notifier/Notifier.module.css';

interface AutoNotifierProps extends NotifierProps {
  showToast?: boolean;
  type?: SNACKBAR_ALERT_TYPES;
  content?: string;
}

// Workaround to auto refresh pinned posts
export const AutoNotifier = ({
  showToast = true,
  type = SNACKBAR_ALERT_TYPES.error,
  content,
  ...props
}: AutoNotifierProps) => {
  useEffect(() => {
    if (props.shouldRefresh) {
      props.triggerFeedRefresh(false);
      autoRefresh(props);
    }
  }, [props, props.shouldRefresh, props.adds.length, props.onClick]);

  const activityWord = props.adds.length === 1 ? 'activity' : 'activities';

  if (!props.adds.length) return null;

  if (!showToast) return null;

  return (
    <SnackbarAlert onClick={props.onClick} isOpened={props.adds.length > 0} type={type}>
      {content && content}
      {!content && (
        <div className={styles.Content}>
          <div className={styles.ActivitiesInfo}>
            You have <b>{`${props.adds.length} new `}</b>
            {activityWord}
          </div>
          <div className={styles.AvatarsContainer}>
            <AvatarGroup
              style={{ display: 'flex', alignItems: 'center' }}
              limit={3}
              users={props.adds.map(getUserFromActivity)}
            />
          </div>
        </div>
      )}
    </SnackbarAlert>
  );
};

export const autoRefresh = (props: NotifierProps) => {
  setTimeout(() => {
    props.onClick();
    props.triggerFeedRefresh(false);
  }, 50);
};

function getUserFromActivity(activity: Activity) {
  return {
    data: {
      profileImage: activity.publisherAvatar,
      name: activity.publisherName,
    },
  };
}
