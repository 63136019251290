const Unleash = (props) => (
  <svg viewBox="0 0 29 27" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
      <path
        stroke="#5A5665"
        mask="url(#b)"
        d="M6.4 27.071h10.854l2.106-7.63h6.48l2.16-8.72h-7.56L22.6 2H11.8l-2.16 8.72H3.1L1 19.441h7.56z"
      />
      <path stroke="#F63A55" mask="url(#b)" d="M18.366 5.988l-1.951 7.733 5.127.026" />
    </g>
  </svg>
);

export default Unleash;
