const SvgComponent = (props) => (
  <svg viewBox="0 0 27 22" width="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#F63A55" d="M9 1l4.63 8.092 2.99-4.971L15.09 1z" />
      <path stroke="#5A5665" d="M20.94 1l-7.374 12.545L6.421 1H1l12.5 21L26 1z" />
    </g>
  </svg>
);

export default SvgComponent;
