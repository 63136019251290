import { get } from 'lodash';

import { PAYMENT_METHOD, paymentMethodsMap } from 'consts/constants';

import { hasPaymentMethod } from './paymentHelper';

const fields = Object.values(PAYMENT_METHOD).reduce((acc, method) => {
  let fieldsValidation = [];
  Object.keys(paymentMethodsMap[method].fields).forEach((key) => {
    if (!paymentMethodsMap[method].fields[key]?.optional) {
      fieldsValidation.push(`info.${key}`);
    }
  });
  acc[method] = fieldsValidation;
  return acc;
}, {});

export const hasUserFilledBanking = ({ _accountingData = {} } = {}) =>
  hasPaymentMethod(_accountingData)
    ? (fields[_accountingData._paymentType.name] || []).every((path) =>
        Boolean(get(_accountingData, path)),
      )
    : false;
