const SvgComponent = (props) => (
  <svg viewBox="0 0 21 23" height="100%" {...props}>
    <defs>
      <path id="a" d="M0 0h19.067v19.067H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M5.8 20.397c4.186 1.523 9.096-1.406 10.966-6.544 1.87-5.138-.008-10.538-4.195-12.062C8.385.267 3.475 3.197 1.605 8.335c-1.87 5.138.008 10.538 4.194 12.062"
          stroke="#F63A55"
        />
        <path
          d="M6.458 17.515c2.855 1.039 6.235-1.05 7.551-4.666 1.316-3.615.07-7.388-2.785-8.427-2.855-1.04-6.235 1.05-7.551 4.665-1.316 3.616-.07 7.389 2.785 8.428"
          stroke="#F63A55"
        />
        <path
          d="M16.774 13.889c1.73-4.752.094-9.57-2.85-11.525.337.071.672.164 1.003.284 4.408 1.604 6.465 7.07 4.595 12.208s-6.96 8.002-11.367 6.398a7.388 7.388 0 0 1-.951-.427c4.151.874 7.775-2.11 9.505-6.863l.065-.075z"
          stroke="#F63A55"
        />
        <path
          d="M16.445 14.907l2.7.983a9.04 9.04 0 0 1-.304.67l-2.702-.985c.11-.217.212-.44.306-.668M17.393 12.13l2.674.973c-.046.24-.103.48-.169.72l-2.675-.975c.065-.238.124-.478.17-.718M15.034 17.514l2.42.881c-.189.198-.385.386-.588.567l-2.42-.88c.203-.182.4-.37.588-.568M17.707 9.123l2.389.87c.049.271.09.546.116.822l-2.39-.87c-.027-.276-.065-.55-.115-.822M16.643 5.614l2.713.987c.177.313.338.632.482.956l-2.713-.987a11.015 11.015 0 0 0-.482-.956M15.766 3.734l2.315.843c.32.346.614.714.88 1.1l-2.314-.842a10.003 10.003 0 0 0-.88-1.101M13.246 19.205l2.766 1.007c-.3.16-.61.308-.929.442l-2.767-1.007c.319-.134.63-.281.93-.442M14.132 21.088c-.35.155-.705.288-1.065.393l-1.942-.707c.361-.105.72-.235 1.071-.39l1.936.704"
          fill="#F63A55"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
