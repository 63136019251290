const ToastMessages = {
  noLongerWorkWarning: {
    autoClose: 5000,
    text: 'If you no longer work on any of the projects, please contact your Account Manager',
  },
  updateTermsError: {
    autoClose: 3000,
    text: 'Error when trying to accept terms. Please, try again later',
  },
};

export { ToastMessages };
