import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './Label.module.scss';

const Label = (props: PropsWithChildren<Props>) => {
  const {
    children,
    isDark,
    isBold,
    isSmall,
    isTiny,
    isSelect,
    isSmallFixed,
    isProfileUnleashLabel,
    isProfileLabel,
    isBankInfo,
    isProfileBankInfo,
    dataTestId,
    isRequired,
  } = props;

  const Classes = classNames(
    styles.Label,
    isSmall && styles.Label_small,
    isSmallFixed && styles.Label_small_fixed,
    isTiny && styles.Label_Tiny,
    isDark && styles.Label_dark,
    isBold && styles.Label_bold,
    isSelect && styles.Label_select,
    isProfileUnleashLabel && styles.Label_profile_unleash,
    isBankInfo && styles.Label_bank_info,
    isProfileBankInfo && styles.Label_profile_bank_info,
    isProfileLabel && styles.Label_profile,
    isRequired && styles.Label_required,
  );

  return (
    <div data-testid={dataTestId} className={Classes}>
      {children}
    </div>
  );
};

export default Label;

type Props = {
  isSmall?: boolean;
  isTiny?: boolean;
  isBold?: boolean;
  isDark?: boolean;
  isSelect?: boolean;
  isProfileUnleashLabel?: boolean;
  isBankInfo?: boolean;
  isProfileBankInfo?: boolean;
  isProfileLabel?: boolean;
  isSmallFixed?: boolean;
  dataTestId?: string;
  isRequired?: boolean;
};
