import { uniqueId } from 'lodash';

import * as constants from 'consts/constants';

// eslint-disable-next-line import/no-cycle
import {
  createSessionApiActionCreator,
  deleteCurrentSessionApiActionCreator,
  updateUserApiActionCreator,
  updateNewsApiActionCreator,
  getUserVaultStashApiActionCreator,
  setAllNotificationsAsReadActionCreator,
} from './reduxRestApi';

export const googleReadyActionCreator = () => ({
  type: constants.GOOGLE_READY,
});

export const userLoggingInActionCreator = () => ({
  type: constants.USER_LOGGING_IN,
});

export const userLoggedInActionCreator = (session) => ({
  type: constants.USER_LOGGED_IN,
  payload: session,
});

export const userLoggedOutActionCreator = () => ({
  type: constants.USER_LOGGED_OUT,
});

export const logOutActionCreator = () => async (dispatch) => {
  try {
    await window.gapi.auth2.getAuthInstance().signOut();
    await dispatch(deleteCurrentSessionApiActionCreator());
  } catch (e) {}
  dispatch(userLoggedOutActionCreator());
};

export const logInWithGoogleActionCreator = (idToken, keepMeSignedIn = false) => async (
  dispatch,
) => {
  dispatch(userLoggingInActionCreator());
  const session = await dispatch(
    createSessionApiActionCreator({
      params: {
        providerName: 'google',
      },
      body: {
        idToken,
        keepMeSignedIn,
      },
    }),
  );

  dispatch(userLoggedInActionCreator(session.payload));
};

export const logInWithPasswordActionCreator = (email, password) => async (dispatch) => {
  dispatch(userLoggingInActionCreator());
  const session = await dispatch(
    createSessionApiActionCreator({
      params: {
        providerName: 'auth',
      },
      body: {
        email,
        password,
      },
    }),
  );

  dispatch(userLoggedInActionCreator(session.payload));
};

export const getVaultStashActionCreator = (userId) => async (dispatch) => {
  const response = await dispatch(getUserVaultStashApiActionCreator({ params: { userId } }));

  return response.payload;
};

export const setAllNotificationsAsReadCreator = () => async (dispatch) => {
  await dispatch(setAllNotificationsAsReadActionCreator());
};

export const setTokenActionCreator = (token) => ({
  type: constants.SET_SESSION_TOKEN,
  payload: token,
});

export const userDataUpdatedActionCreator = (user) => ({
  type: constants.USER_DATA_UPDATED,
  payload: user,
});

export const updateUserDataActionCreator = (user, updateSource = 'profile') => async (dispatch) => {
  const {
    payload: { data: updatedUser },
  } = await dispatch(
    updateUserApiActionCreator({
      params: { userId: user.id },
      body: {
        userData: user,
        updateSource,
      },
    }),
  );
  dispatch(userDataUpdatedActionCreator(updatedUser));
};

export const updateNewsIsActiveActionCreator = (newsItem, isActive) => async (dispatch) => {
  const updatedNews = {
    ...newsItem,
    isActive,
  };
  await dispatch(
    updateNewsApiActionCreator({
      params: {
        newsId: newsItem.id,
      },
      body: updatedNews,
    }),
  );
};

export const onDragStartActionCreator = (payload) => ({
  type: constants.DRAG_EVENTS.start,
  payload,
});

export const onDragEndActionCreator = (payload) => ({
  type: constants.DRAG_EVENTS.end,
  payload,
});

/**
 * @param {object} options
 * @param {number=} options.autoClose
 * @param {string | object} options.text
 * @param {('error'|'info'|'warning'|'success')|string=} options.type
 */
export const addToast = (options = {}) => ({
  payload: {
    id: uniqueId(),
    ...options,
  },
  type: constants.ADD_TOAST,
});

export const removeToast = (id) => ({
  payload: id,
  type: constants.REMOVE_TOAST,
});

export const removeAllToasts = () => ({
  type: constants.REMOVE_ALL_TOASTS,
});

/**
 * @param {object} options
 * @param {number=} options.autoClose
 * @param {string | object} options.text
 * @param {('error'|'info'|'warning'|'success')=} options.type
 * @param {object=} options.forwardRef
 */
export const addModalToast = (options = {}) => ({
  payload: {
    ...options,
    id: uniqueId(),
  },
  type: constants.ADD_MODAL_TOAST,
});

export const removeModalToast = (id) => ({
  payload: id,
  type: constants.REMOVE_MODAL_TOAST,
});

export const removeAllModalToasts = () => ({
  type: constants.REMOVE_ALL_MODAL_TOASTS,
});
