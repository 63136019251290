import { memo, MouseEventHandler, SyntheticEvent } from 'react';

import cx from 'classnames';
import moment from 'moment';

import isKeyboardEvent from 'utils/keyboardEvent';

import { Avatar } from '../../Atoms/Avatar';
import { Paragraph } from '../../Atoms/Paragraph';

import styles from './NotificationItem.module.scss';

type ActionClickHandler = (
  e: SyntheticEvent<HTMLDivElement> | undefined,
) => MouseEventHandler<HTMLDivElement | undefined> | void;

export type NotificationItemProps = {
  imgSrc?: string;
  name: string;
  action: string;
  date: Date;
  unread?: boolean;
  onClick?: ActionClickHandler;
};

export const NotificationItem = memo<NotificationItemProps>(({ date = new Date(), ...props }) => {
  const dateClasses = cx(styles.Date, {
    [styles.Unread]: props.unread,
  });

  return (
    <div
      className={styles.Wrapper}
      onClick={props.onClick}
      onKeyUp={(e) => isKeyboardEvent(e) && props.onClick?.(e)}
      tabIndex={0}
      role="button"
    >
      <div>
        <div className={styles.AvatarWrapper}>
          <Avatar
            interactive={false}
            accessibleName={props.name}
            imgSrc={props.imgSrc}
            name={!props.imgSrc ? props.name : undefined}
          />
        </div>
      </div>

      <div className={styles.ContentWrapper}>
        <div className={styles.ActionDescription}>
          <Paragraph variant="ui-regular">
            <b className={styles.Name}>{props.name}</b>
            {props.action}
          </Paragraph>
        </div>

        <Paragraph className={dateClasses} variant={props.unread ? 'ui-bold' : 'ui-regular'}>
          {moment(date).fromNow()}
        </Paragraph>
      </div>

      <div>
        <div className={styles.UnreadWrapper}>
          <span className={props.unread ? styles.UnreadIndicator : styles.ReadIndicator} />
        </div>
      </div>
    </div>
  );
});
