export const INVOICE_CONFIRMATION_PATH = '/confirmation/:invoiceId';

export const INVOICE = {
  CONFIRMATION_PATH: '/confirmation',
  REFERRALS_PATH: '/invoice-referrals',
  SUBMIT_PATH: '/submit',
  SURVEY_PATH: '/survey',
};

export const ADMIN = {
  ACCOUNTING_PATH: '/admin/invoices',
  ANSWERS_PATH: '/admin/answers',
  API_KEYS_PATH: '/admin/api-keys',
  APPRECIATIONS_PATH: '/admin/appreciations',
  APPROVAL_LIST_PATH: '/admin/approvals',
  BOUNTIES_EDIT_PATH: '/admin/bounties-edit',
  BOUNTIES_COLLECTIONS_PATH: '/admin/bounties-collections',
  BOUNTIES_EDIT_SEASONS_PATH: '/admin/bounties-edit/seasons',
  COMPANIES_LIST_PATH: '/admin/companies',
  EVENTS_PATH: '/admin/events',
  LEGENDS_PATH: '/admin/legends',
  MISSIONS_PATH: '/admin/missions',
  OBJECTIVES_PATH: '/admin/objectives',
  PROJECT_LIST_PATH: '/admin/projects',
  QUESTIONS_PATH: '/admin/questions',
  REPORTS_PATH: '/admin/reports',
  RUN_PAYMENTS_PATH: '/admin/run-payments',
  SETTINGS_CEO_PATH: '/admin/settings/ceo',
  SETTINGS_PATH: '/admin/settings',
  HOUSES_LIST_PATH: '/admin/houses',
  TOOLS_PATH: '/admin/tools',
  UNLEASH_CATEGORIES_PATH: '/admin/unleash-categories',
  USERS_GROUPS_PATH: '/admin/groups',
  USER_LIST_PATH: '/admin/users',
  USER_PATH: '/admin/user',
  XVIP_PATH: '/admin/xvip',
  XVIP_LIST_PATH: '/admin/xvips',
  VACATION_REQUESTS_PATH: '/admin/vacation-requests',
  VAULT_COLLECTIONS_PATH: '/admin/vault-collections',
  VAULT_PATH: '/admin/vault-items',
  REFERRALS_MANAGEMENT_PATH: '/admin/referrals',
  BETS_PATH: '/admin/bets',
};

export const PROFILE = {
  BANKING_PATH: '/profile-banking',
  BOUNTIES_PATH: '/profile-bounties',
  DOCUMENTS_PATH: '/profile-documents',
  PROFILE_INVOICES_PATH: '/profile-invoices',
  PROFILE_PATH: '/profile',
  REFER_PATH: '/refer',
  UNLEASH_REMAINING_PATH: '/profile-unleash-remaining',
  VACATION_REQUESTS_PATH: '/vacation-request',
};

export const APP = {
  BOUNTIES_PATH: '/bounties',
  HELP_PATH: '/help',
  HOME_PATH: '/',
  LEGENDS_PATH: '/legends',
  MISSIONS_PATH: '/missions',
  RAIDS_PATH: '/raids',
  REFER_PATH: '/refer',
  UNLEASH_GUIDE_PATH: '/unleash-guide',
  UNLEASH_PATH: '/unleash',
  VACATION_REQUEST_PATH: '/vacation-request',
  VAULT_PATH: '/vault',
  HOUSES_PATH: '/houses',
};

export const MY_XTEAM_PROFILE_PATH = 'https://jobs.x-team.com/';

export const HANDBOOK_PATH = 'https://x-team.gitbook.io';

export const SUPPORT_DESK_URI = 'https://x-team.slack.com/archives/CCSHSBUUT';

export const USER_DATA_URI = 'https://drive.google.com/drive/search?q=';
