import { ReactNode } from 'react';

import { DesignSystemProvider, Theme } from 'design-system';

import { useCurrentUser } from 'utils/hooks';
import { isNullOrUndefined } from 'utils/object';

interface ThemeProviderProps {
  children: ReactNode;
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const loggedUser = useCurrentUser();

  let defaultTheme;
  if (loggedUser && !isNullOrUndefined(loggedUser?.darkMode)) {
    defaultTheme = loggedUser.darkMode ? Theme.dark : Theme.light;
  }

  return <DesignSystemProvider defaultTheme={defaultTheme}>{children}</DesignSystemProvider>;
}

export default ThemeProvider;
