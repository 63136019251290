import { SVGProps } from 'react';

// prettier-ignore
export const AVAILABLE_ALERTS_ICONS = ['notification-unread', 'notification'];
// prettier-ignore
export const AVAILABLE_ARROWS_ICONS = [ 'caret-down', 'caret-left', 'caret-right', 'caret-up', 'chevron-down', 'chevron-left', 'chevron-right', 'chevron-up', 'thin-long-down', 'thin-long-left', 'thin-long-right', 'thin-long-up' ];
// prettier-ignore
export const AVAILABLE_ATTENTION_ICONS = ['info-circle-outline'];
// prettier-ignore
export const AVAILABLE_BASIC_ICONS = [ 'calendar-colored-first-half', 'calendar-colored-full', 'calendar-colored-second-half', 'calendar-grey-first-half', 'calendar-grey-full', 'calendar-grey-second-half', 'check', 'checkbox-off', 'checkbox-on', 'circle-check-fill', 'circle-check-outline', 'circle-question', 'emoji', 'flag-fill', 'flag-outline', 'heart-fill', 'heart-outline', 'layers', 'like', 'lock', 'pin', 'radio-check', 'radio-uncheck', 'refresh', 'slider', 'trash-outline', 'x-team'];
// prettier-ignore
export const AVAILABLE_BRAND_ICONS = [ 'adobe-xd', 'app-store', 'apple', 'behance', 'coolicons', 'css3', 'discord', 'dribble', 'dropbox', 'facebook', 'figma', 'github', 'google', 'html5', 'instagram', 'invision', 'javascript', 'linkedin', 'linkpath', 'messenger', 'paypal', 'play-store', 'reddit', 'sketch', 'slack', 'snapchat', 'spectrum', 'spotify', 'stackoverflow', 'trello', 'twitter', 'unsplash', 'youtube' ];
// prettier-ignore
export const AVAILABLE_DROPDOWNMENU_ICONS = ['banking-info', 'help-dropdownmenu', 'logout', 'my-documents', 'my-xteam-profile', 'past-invoices', 'profile'];
// prettier-ignore
export const AVAILABLE_EDIT_ICONS = ['comment', 'edit', 'hide', 'list-ul', 'minus', 'plus', 'search', 'show'];
// prettier-ignore
export const AVAILABLE_FILE_ICONS = ['cloud-up', 'folder-plus'];
// prettier-ignore
export const AVAILABLE_MAINMENU_ICONS = ['accounting', 'admin', 'answers', 'approvals', 'bounties', 'community', 'companies', 'crown-legends', 'handbook', 'help-mainmenu', 'missions', 'objectives', 'projects', 'questions', 'refer', 'run-payments', 'settings', 'submit-invoice', 'teams', 'the-vault-outline', 'the-vault', 'unleash', 'users', 'vacations'];
// prettier-ignore
export const AVAILABLE_MEDIA_ICONS = ['file', 'image', 'play','radio'];
// prettier-ignore
export const AVAILABLE_MENU_ICONS = ['close-big', 'close-small', 'hamburger', 'more-vertical'];
// prettier-ignore
export const AVAILABLE_RARITY_ICONS = ['common', 'featured', 'legendary', 'rare'];
// prettier-ignore
export const AVAILABLE_UNLEASH_ICONS = ['adventure-sports', 'adventure', 'babysitter', 'beauty-care', 'bike', 'coffee', 'conferences', 'cooking-gear', 'courses-books', 'coworking', 'ergonomics', 'family', 'freash-produce', 'games', 'gim-membership', 'happy-hour', 'headphones', 'health-wellness', 'hub', 'lamp', 'led-lightbulb', 'maker-tools', 'meetup', 'movie-tickets', 'music-gear', 'package', 'pet-sitting', 'photo', 'picture', 'podcast', 'productivity', 'restaurants', 'running-shoes', 'sanitizer', 'scolarship', 'seeds', 'smart-home', 'vault-boy', 'wifi'];
// prettier-ignore
export const AVAILABLE_USER_ICONS = ['user-check', 'user-plus'];
// prettier-ignore
export const AVAILABLE_ICONS = AVAILABLE_ALERTS_ICONS.concat(AVAILABLE_ARROWS_ICONS).concat(AVAILABLE_ATTENTION_ICONS).concat(AVAILABLE_BASIC_ICONS).concat(AVAILABLE_BRAND_ICONS).concat(AVAILABLE_DROPDOWNMENU_ICONS).concat(AVAILABLE_EDIT_ICONS).concat(AVAILABLE_FILE_ICONS).concat(AVAILABLE_MAINMENU_ICONS).concat(AVAILABLE_MEDIA_ICONS).concat(AVAILABLE_MENU_ICONS).concat(AVAILABLE_RARITY_ICONS).concat(AVAILABLE_UNLEASH_ICONS).concat(AVAILABLE_USER_ICONS);

export type IconProps = SVGProps<SVGSVGElement> & {
  name: string;
};

const getIcon = ({ name, ...props }: IconProps) => {
  const svgsDir = require.context('!@svgr/webpack!../../assets/Icon/');
  const svgPath = svgsDir.keys().find((path) => path.split('/')[2] === `${name}.svg`) ?? '';

  if (svgPath === '') {
    return <></>;
  }

  const Icon = svgsDir(svgPath).default;
  return <Icon {...props} />;
};

export const Icon = (props: IconProps) => {
  return <>{getIcon(props)}</>;
};
