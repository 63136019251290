import './stop-runaway-effects';

import { StrictMode } from 'react';

import * as Sentry from '@sentry/browser';
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import AuthComponent from 'components/Auth';
import { XhqErrorBoundary } from 'components/ErrorBoundary';
import { onDragStartActionCreator, onDragEndActionCreator } from 'store/actionCreators';
import currentStore from 'store/currentStore';

import App from './App';
import { env, sentryVersion } from './config';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

Sentry.init({
  debug: false,
  environment: env,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: sentryVersion,
});

const store = currentStore();

const onDragStart: DragDropContextProps['onDragStart'] = (result) => {
  store.dispatch(onDragStartActionCreator(result));
};

const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
  store.dispatch(onDragEndActionCreator(result));
};

render(
  <StrictMode>
    <XhqErrorBoundary>
      <Provider store={store}>
        <AuthComponent>
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <App />
          </DragDropContext>
        </AuthComponent>
      </Provider>
    </XhqErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);
