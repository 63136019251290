import { lazy } from 'react';

import { Route, Switch, RouteProps } from 'react-router-dom';

import { PrivateRoute } from 'components/Auth/authRedirect';
import { USER_ROLE } from 'consts/constants';
import { INVOICE, PROFILE, APP } from 'consts/routing';
import HomePage from 'pages/HomePage';
import { ToggleablePages } from 'types/types';
import { useAdminSetting, useIsPageEnabled } from 'utils/hooks';

const Submit = lazy(
  () => import(/* webpackChunkName: "Submit", webpackPrefetch: true */ 'pages/Submit'),
);
const Confirmation = lazy(
  () => import(/* webpackChunkName: "Confirmation" */ 'pages/Confirmation/containers/Confirmation'),
);
const Help = lazy(() => import(/* webpackChunkName: "Help" */ 'pages/Help'));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ 'pages/Profile/Profile'));
const ProfileInvoices = lazy(
  () => import(/* webpackChunkName: "ProfileInvoices" */ 'pages/ProfileInvoices'),
);
const ProfileBanking = lazy(
  () => import(/* webpackChunkName: "ProfileBanking" */ 'pages/ProfileBanking/ProfileBanking'),
);
const Unleash = lazy(() => import(/* webpackChunkName: "Unleash" */ 'pages/Unleash/Unleash'));
const UnleashGuide = lazy(
  () => import(/* webpackChunkName: "UnleashGuide" */ 'pages/UnleashGuide'),
);
const Survey = lazy(
  () => import(/* webpackChunkName: "Survey", webpackPrefetch: true */ 'pages/Survey'),
);
const InvoiceReferrals = lazy(
  () =>
    import(
      /* webpackChunkName: "InvoiceReferrals", webpackPrefetch: true */ 'pages/InvoiceReferrals/InvoiceReferrals'
    ),
);
const Refer = lazy(() => import(/* webpackChunkName: "Refer" */ 'pages/Refer'));
const VacationRequest = lazy(
  () => import(/* webpackChunkName: "VacationRequest" */ 'pages/VacationRequest/VacationRequest'),
);
const Vault = lazy(() => import(/* webpackChunkName: "Vault" */ 'pages/Vault/Vault'));
const Bounties = lazy(() => import(/* webpackChunkName: "Bounties" */ 'pages/Bounties/Bounties'));
const Raids = lazy(() => import(/* webpackChunkName: "Raids" */ 'pages/Raids/Raids'));
const UserBounties = lazy(
  () => import(/* webpackChunkName: "UserBounties" */ 'pages/UserBounties/UserBounties'),
);
const UserDocuments = lazy(
  () => import(/* webpackChunkName: "UserDocuments" */ '../user/UserDocuments'),
);
const UnleashRemaining = lazy(
  () =>
    import(/* webpackChunkName: "UnleashRemaining" */ 'pages/UnleashRemaining/UnleashRemaining'),
);
const Missions = lazy(() => import(/* webpackChunkName: "Missions" */ 'pages/Missions/Missions'));
const Legends = lazy(() => import(/* webpackChunkName: "Legends" */ 'pages/Legends/Legends'));
const Houses = lazy(() => import(/* webpackChunkName: "Houses" */ 'pages/Houses'));

const adminCommunityPeople = [
  USER_ROLE.ADMIN,
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.COMMUNITY_TEAM,
  USER_ROLE.PEOPLE_OPS,
];
const allUserRoles = Object.values(USER_ROLE);

const EmptyRoute = () => <div />;

const ToggleableRoute = ({ page, ...routeProps }: ToggleableRouteType) => {
  const isPageEnabled = useIsPageEnabled(page);
  return <Route {...routeProps} component={isPageEnabled ? routeProps.component : EmptyRoute} />;
};

const AppPage = () => {
  return (
    <Switch>
      <Route exact path={APP.HELP_PATH} component={Help} />
      <Route exact path={INVOICE.SUBMIT_PATH} component={Submit} />
      <Route path={`${INVOICE.SUBMIT_PATH}/:userId`} component={Submit} />
      <Route path={`${INVOICE.SURVEY_PATH}/:invoiceId`} component={Survey} />
      <Route path={INVOICE.SURVEY_PATH} component={Survey} />
      <Route path={`${INVOICE.REFERRALS_PATH}/:invoiceId`} component={InvoiceReferrals} />
      <Route path={PROFILE.PROFILE_INVOICES_PATH} component={ProfileInvoices} />
      <Route path={PROFILE.BANKING_PATH} component={ProfileBanking} />
      <Route path={PROFILE.DOCUMENTS_PATH} component={UserDocuments} />
      <Route path={PROFILE.UNLEASH_REMAINING_PATH} component={UnleashRemaining} />
      <Route path={APP.UNLEASH_PATH} component={Unleash} />
      <Route path={APP.UNLEASH_GUIDE_PATH} component={UnleashGuide} />
      <Route path={APP.VACATION_REQUEST_PATH} component={VacationRequest} />
      <Route path={`${INVOICE.CONFIRMATION_PATH}/:invoiceId`} component={Confirmation} />
      <Route path={APP.RAIDS_PATH} component={Raids} />
      <Route exact path={APP.HOME_PATH} component={HomePage} />
      <Route path={PROFILE.PROFILE_PATH} component={Profile} />
      <Route path={PROFILE.BOUNTIES_PATH} component={UserBounties} />
      <Route path={APP.REFER_PATH} component={Refer} />
      <Route path={APP.BOUNTIES_PATH} component={Bounties} />
      <Route path={APP.VAULT_PATH} component={Vault} />
      <ToggleableRoute page="houses" path={APP.HOUSES_PATH} component={Houses} />
      <Route path={APP.MISSIONS_PATH} component={Missions} />
      <PrivateRoute
        allowedRoles={
          useAdminSetting('legends')?.values.legendsPageForUsers
            ? allUserRoles
            : adminCommunityPeople
        }
        path={APP.LEGENDS_PATH}
        component={Legends}
      />
    </Switch>
  );
};

type ToggleableRouteType = RouteProps & { page: ToggleablePages };

export default AppPage;
