const Reports = (props) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 420 360"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="bar-chart-(1)" fillRule="nonzero">
        <path
          d="M352.292,8.364 C347.048,3.033 340.041,0.063 332.563,0.001 L332.328,0 C317.02,0 304.462,12.456 304.336,27.766 C304.275,35.244 307.128,42.299 312.373,47.63 C317.618,52.961 324.624,55.931 332.102,55.993 L332.337,55.994 C347.645,55.994 360.203,43.538 360.329,28.228 C360.39,20.75 357.536,13.695 352.292,8.364 Z M332.336,45.651 L332.187,45.65 C327.472,45.611 323.054,43.738 319.748,40.377 C316.441,37.015 314.641,32.567 314.68,27.852 C314.759,18.198 322.676,10.344 332.328,10.344 L332.477,10.345 C337.192,10.384 341.61,12.257 344.916,15.618 C348.223,18.98 350.023,23.428 349.984,28.143 C349.905,37.797 341.988,45.651 332.336,45.651 Z"
          id="Shape"
          fill="#F63A55"
        />
        <path
          d="M28.228,152.169 L27.993,152.168 C12.684,152.168 0.127,164.624 0.001,179.935 C-0.126,195.371 12.329,208.034 27.766,208.162 L28,208.162 C43.309,208.162 55.867,195.706 55.993,180.396 C56.12,164.959 43.665,152.296 28.228,152.169 Z M28,197.818 L27.851,197.817 C18.117,197.737 10.263,189.753 10.343,180.019 C10.423,170.365 18.34,162.511 27.992,162.511 L28.141,162.511 C37.875,162.591 45.729,170.576 45.649,180.31 C45.57,189.964 37.653,197.818 28,197.818 Z"
          id="Shape"
          fill="#F63A55"
        />
        <path
          d="M133.585,46.812 L133.35,46.811 C118.041,46.811 105.483,59.267 105.358,74.577 C105.231,90.014 117.686,102.677 133.123,102.804 L133.358,102.805 C148.666,102.805 161.224,90.349 161.35,75.039 C161.477,59.601 149.022,46.938 133.585,46.812 Z M133.357,92.462 L133.208,92.461 C123.474,92.381 115.62,84.396 115.7,74.663 C115.78,65.009 123.697,57.155 133.349,57.155 L133.498,57.156 C143.232,57.236 151.086,65.221 151.006,74.954 C150.927,84.608 143.01,92.462 133.357,92.462 Z"
          id="Shape"
          fill="#F63A55"
        />
        <path
          d="M209.669,122.896 L209.434,122.895 C194.125,122.895 181.568,135.351 181.442,150.661 C181.315,166.098 193.771,178.761 209.208,178.888 L209.442,178.889 C224.75,178.889 237.308,166.433 237.434,151.123 C237.561,135.685 225.105,123.023 209.669,122.896 Z M209.441,168.546 L209.292,168.545 C199.558,168.465 191.704,160.48 191.784,150.747 C191.863,141.093 199.78,133.239 209.432,133.239 L209.581,133.24 C219.315,133.32 227.168,141.305 227.088,151.038 C227.01,160.692 219.093,168.546 209.441,168.546 Z"
          id="Shape"
          fill="#F63A55"
        />
        <rect
          id="Rectangle"
          fill="#F63A55"
          transform="translate(270.800472, 89.375502) rotate(-45.072926) translate(-270.800472, -89.375502) "
          x="206.726534"
          y="84.2040473"
          width="128.147875"
          height="10.3429092"
        />
        <rect
          id="Rectangle"
          fill="#F63A55"
          transform="translate(171.410277, 112.873720) rotate(-45.000000) translate(-171.410277, -112.873720) "
          x="166.238827"
          y="81.8770168"
          width="10.3429008"
          height="61.9934055"
        />
        <rect
          id="Rectangle"
          fill="#F63A55"
          transform="translate(80.675764, 127.486344) rotate(-45.000000) translate(-80.675764, -127.486344) "
          x="29.00176"
          y="122.314893"
          width="103.348009"
          height="10.3429008"
        />
        <path
          d="M296.727,106.518 L296.727,360.33 L360.329,360.33 L360.329,106.518 L296.727,106.518 Z M307.071,349.986 L307.07,116.861 L349.986,116.861 L349.986,349.986 L307.071,349.986 Z"
          id="Shape"
          fill="#5A5665"
        />
        <path
          d="M197.818,213.035 L197.818,360.33 L261.42,360.33 L261.42,213.035 L197.818,213.035 Z M251.078,349.986 L208.162,349.986 L208.162,223.379 L251.078,223.379 L251.078,349.986 Z"
          id="Shape"
          fill="#5A5665"
        />
        <path
          d="M98.908,205.427 L98.908,360.33 L162.51,360.33 L162.51,205.427 L98.908,205.427 Z M152.168,349.986 L109.252,349.986 L109.252,215.771 L152.168,215.771 L152.168,349.986 Z"
          id="Shape"
          fill="#5A5665"
        />
        <path
          d="M0,243.469 L0,360.33 L63.602,360.33 L63.603,243.469 L0,243.469 Z M53.259,349.986 L10.343,349.986 L10.343,253.811 L53.259,253.811 C53.259,253.811 53.259,349.986 53.259,349.986 Z"
          id="Shape"
          fill="#5A5665"
        />
      </g>
    </g>
  </svg>
);

export default Reports;
