const SvgComponent = (props) => {
  return (
    <svg viewBox="0 0 29 29" width="100%" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(14.142136, 14.142136) rotate(45.000000) translate(-14.142136, -14.142136) translate(4.142136, 4.142136)">
              <rect fill="#F63A55" x="8" y="8" width="4" height="4" />
              <g transform="translate(4.000000, 8.000000)" fill="#5A5665">
                <rect x="0" y="-2.27373675e-13" width="1" height="8" />
                <rect
                  transform="translate(8.000000, 7.500000) rotate(90.000000) translate(-8.000000, -7.500000) "
                  x="7.5"
                  y="3.5"
                  width="1"
                  height="8"
                />
              </g>
              <g
                transform="translate(10.000000, 8.000000) scale(-1, -1) translate(-10.000000, -8.000000) translate(4.000000, 4.000000)"
                fill="#5A5665"
              >
                <rect x="0" y="-2.27373675e-13" width="1" height="8" />
                <rect
                  transform="translate(8.000000, 7.500000) rotate(90.000000) translate(-8.000000, -7.500000) "
                  x="7.5"
                  y="3.5"
                  width="1"
                  height="8"
                />
              </g>
              <g
                transform="translate(10.000000, 8.000000) scale(-1, -1) translate(-10.000000, -8.000000) "
                fill="#F63A55"
              >
                <rect x="19" y="2.27373675e-13" width="1" height="16" />
                <rect
                  transform="translate(8.000000, 15.500000) rotate(90.000000) translate(-8.000000, -15.500000) "
                  x="7.5"
                  y="7.5"
                  width="1"
                  height="16"
                />
              </g>
              <g transform="translate(0.000000, 4.000000)" fill="#F63A55">
                <rect x="19" y="2.27373675e-13" width="1" height="16" />
                <rect
                  transform="translate(8.000000, 15.500000) rotate(90.000000) translate(-8.000000, -15.500000) "
                  x="7.5"
                  y="7.5"
                  width="1"
                  height="16"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgComponent;
