function SvgAdmin(props) {
  return (
    <svg viewBox="0 0 25 31" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M24.575 3.776L12.67.026a.57.57 0 00-.341 0L.424 3.776A.622.622 0 000 4.375v13.75c0 5.528 8.026 10.444 12.324 11.848a.565.565 0 00.352 0C16.974 28.568 25 23.653 25 18.125V4.375a.622.622 0 00-.425-.599zm-.765 14.349c0 4.389-6.623 9.004-11.31 10.595-4.687-1.591-11.31-6.206-11.31-10.595V4.84L12.5 1.278 23.81 4.84v13.285z"
          fill="#5A5665"
        />
        <path
          d="M12.5 7.5c-3.61 0-6.548 3.084-6.548 6.875S8.89 21.25 12.5 21.25c3.61 0 6.548-3.084 6.548-6.875S16.11 7.5 12.5 7.5zm0 12.5c-2.954 0-5.357-2.524-5.357-5.625S9.546 8.75 12.5 8.75s5.357 2.524 5.357 5.625S15.454 20 12.5 20z"
          fill="#F63A55"
        />
        <path
          d="M16.492 11.434a.576.576 0 00-.842 0l-4.34 4.557-1.961-2.057a.576.576 0 00-.842 0 .648.648 0 000 .883l2.381 2.5a.579.579 0 00.842 0l4.762-5a.648.648 0 000-.883z"
          fill="#F63A55"
        />
      </g>
    </svg>
  );
}

export default SvgAdmin;
