const Projects = (props) => (
  <svg viewBox="0 0 23 24" height="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <g stroke="#5A5665">
        <path d="M6.204 20.332l3.256-3.257-2.892-2.892-3.257 3.256 2.893 2.893zM16.35 10.186l3.256-3.256-2.893-2.893-3.256 3.257 2.892 2.892z" />
        <path
          d="M20.961 5.575l1.449-1.45a.5.5 0 0 0 0-.706l-2.186-2.186a.5.5 0 0 0-.707 0l-1.449 1.449 2.893 2.893zM1.475 22.093l3.751-1.228-2.461-2.46-1.29 3.688z"
          fill="#5A5665"
        />
      </g>
      <g transform="translate(0 1)">
        <path
          d="M1.253 3.759L5.108.036 22.3 17.84l-3.855 3.723a1 1 0 0 1-1.414-.025L1.228 5.173a1 1 0 0 1 .025-1.414zm7.541-.321L6.764 5.5m4.736.688L9.47 8.25m4.736.688L12.176 11m4.736.688l-2.03 2.062m4.736.688l-2.03 2.062"
          stroke="#F63A55"
        />
        <ellipse fill="#F63A55" cx={3.382} cy={4.813} rx={1} ry={1} />
      </g>
    </g>
  </svg>
);

export default Projects;
