/* eslint-disable jsx-a11y/no-autofocus */
import cx from 'classnames';
import { Image, Placeholder, Video } from 'cloudinary-react';
import { marked } from 'marked';
import Avatar from 'react-avatar';
import { Carousel } from 'react-responsive-carousel';
import sanitizeHtml from 'sanitize-html';

import { FeedActivityProps, POST_TYPES, RefreshFeedCallbackFunction } from '../Feed.types';
import PostReactions from '../PostReactions';
import { SlideArrowLeft, SlideArrowRight } from '../SlideArrows';

import styles from './SinglePostBody.module.scss';

export const SinglePostBody = (props: SinglePostBodyProps) => {
  const {
    medias,
    feedProps: { activity, onAddReaction, onRemoveReaction },
  } = props;
  const { message = '', title = '', publisherAvatar, publisherName, type } = activity;
  const isPinnedPost = type === POST_TYPES.PINNED_POST;

  const getMediaByType = (media: Media) => {
    const isVideo = media.type === 'video';
    const isImage = media.type === 'image';

    return (
      <div
        className={cx(styles.MediaCarouselContainer, { [styles.VideoContainer]: isVideo })}
        key={media?.publicId}
      >
        {isImage && (
          <Image
            className={styles.Media}
            loading="lazy"
            publicId={media?.publicId}
            crop="scale"
            alt="User uploaded image"
          >
            <Placeholder type="pixelate" />
          </Image>
        )}
        {isVideo && (
          <Video
            className={cx(styles.Media, styles.Video)}
            publicId={media?.publicId}
            controls={{ playerControls: true }}
          />
        )}
      </div>
    );
  };

  const className = cx({
    [styles.NoMediaContainer]: medias.length === 0,
    [styles.HasMediaContainer]: medias.length > 0,
  });

  return (
    <div className={className}>
      {medias.length ? (
        <div className={styles.MediaContainer}>
          <Carousel
            className={styles.Carousel}
            renderThumbs={() => []}
            renderArrowNext={SlideArrowRight}
            renderArrowPrev={SlideArrowLeft}
            showStatus={false}
            autoFocus
            useKeyboardArrows
            renderIndicator={() => null}
          >
            {medias.map(getMediaByType)}
          </Carousel>
        </div>
      ) : null}

      <div className={styles.PostBody}>
        <div className={styles.ScrollSection}>
          <div className={styles.AuthorContainer}>
            <span>
              <Avatar
                round
                src={publisherAvatar}
                name={publisherName}
                maxInitials={2}
                size="32px"
              />
              {publisherName}
            </span>
          </div>
          {isPinnedPost && title && (
            <div className={styles.PostBodyTitle} dangerouslySetInnerHTML={{ __html: title }} />
          )}
          <div
            className={styles.PostBodyText}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(marked(message, { breaks: true })) }}
          />
          <div>
            <PostReactions
              activity={activity}
              onAddReaction={onAddReaction}
              onRemoveReaction={onRemoveReaction}
              showText={!medias.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface SinglePostBodyProps {
  feedProps: FeedActivityProps;
  medias: { type: string; publicId: string }[];
  refreshFeed?: RefreshFeedCallbackFunction;
}

export interface Media {
  type: string;
  publicId: string;
  bytes?: number;
  format?: string;
  height?: number;
  width?: number;
}
