const SvgComponent = (props) => (
  <svg viewBox="0 0 30 26" width="100%" {...props}>
    <path
      d="M6.688 24.865h16.267V10.15l3.903 1.335L29 6.045 21.048 1h-2.811c-.413 2.27-1.552 3.388-3.42 3.355-1.866-.033-2.966-1.152-3.299-3.355h-2.37L1 5.792l2.156 5.694 4.05-1.64-.01 15.212"
      stroke="#5A5665"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
