const Groups = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 550 474"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="group" fillRule="nonzero">
          <path
            d="M134.095,89.219 C142.222,79.467 147.098,67.276 147.098,54.273 C147.098,25.016 122.717,0.635 93.46,0.635 C64.203,0.635 39.822,25.016 39.822,54.273 C39.822,67.276 44.698,79.467 52.825,89.219 C17.879,103.847 0,129.041 0,162.362 C0,176.991 3.251,186.743 10.565,193.245 C22.755,203.81 48.762,198.934 57.701,197.309 C68.266,195.684 80.457,194.058 93.46,194.058 C106.463,194.058 118.654,195.683 129.219,197.309 C148.724,200.56 164.978,202.998 176.356,193.246 C183.67,187.557 186.921,176.992 186.921,162.363 C186.921,129.041 169.041,103.847 134.095,89.219 Z M93.46,17.701 C113.777,17.701 130.844,34.768 130.844,55.085 C130.844,75.402 113.778,92.47 93.46,92.47 C73.142,92.47 56.076,75.403 56.076,55.086 C56.076,34.769 73.143,17.701 93.46,17.701 Z M165.791,181.054 C160.102,185.93 147.099,183.492 131.658,181.054 C120.28,179.429 107.277,176.991 93.461,176.991 C79.645,176.991 66.642,178.616 55.264,181.054 C39.823,184.305 26.82,185.93 21.131,181.054 C17.88,178.616 16.255,172.114 16.255,163.175 C16.255,133.105 33.322,113.6 67.455,102.223 C74.769,106.287 83.709,108.725 93.461,108.725 C103.213,108.725 112.153,106.287 119.467,102.223 C153.6,113.601 170.667,133.918 170.667,163.175 C170.667,172.114 169.041,178.616 165.791,181.054 Z"
            id="Shape"
            fill="#5A5665"
          ></path>
          <path
            d="M451.048,90.032 C459.175,80.28 464.051,68.089 464.051,55.086 C464.051,25.829 439.67,1.448 410.413,1.448 C381.156,1.448 356.775,25.829 356.775,55.086 C356.775,68.089 361.651,80.28 369.778,90.032 C334.832,104.661 316.953,129.854 316.953,163.175 C316.953,177.804 320.204,187.556 327.518,194.058 C339.708,204.623 365.715,199.747 374.654,198.121 C385.219,196.496 398.222,194.87 410.413,194.87 C423.416,194.87 435.607,196.495 446.172,198.121 C465.677,201.372 481.931,203.81 493.308,194.058 C500.622,188.369 503.873,177.804 503.873,163.175 C503.873,129.854 485.994,104.66 451.048,90.032 Z M410.413,17.701 C430.731,17.701 447.797,34.768 447.797,55.085 C447.797,75.402 430.73,92.469 410.413,92.469 C390.096,92.469 373.029,75.402 373.029,55.085 C373.029,34.768 390.095,17.701 410.413,17.701 Z M482.743,181.054 C477.054,185.93 464.051,183.492 448.61,181.054 C437.232,179.429 424.229,176.991 410.413,176.991 C396.597,176.991 383.594,178.616 372.216,181.054 C356.775,183.492 343.772,185.117 338.083,181.054 C334.832,178.616 333.207,172.114 333.207,163.175 C333.207,133.105 350.274,113.6 384.407,102.223 C391.721,106.287 400.661,108.725 410.413,108.725 C420.165,108.725 429.105,106.287 436.419,102.223 C470.552,113.601 487.619,133.918 487.619,163.175 C487.619,172.114 485.994,178.616 482.743,181.054 Z"
            id="Shape"
            fill="#5A5665"
          ></path>
          <path
            d="M292.571,362.286 C300.698,352.534 305.574,340.343 305.574,327.34 C305.574,298.083 281.193,273.702 251.936,273.702 C222.679,273.702 198.298,298.083 198.298,327.34 C198.298,340.343 203.174,352.534 211.301,362.286 C176.355,376.915 158.476,402.108 158.476,435.429 C158.476,450.058 161.727,459.81 169.041,466.312 C181.231,476.877 207.238,472.001 216.178,470.376 C226.743,468.751 239.746,467.125 251.937,467.125 C264.94,467.125 277.131,468.75 287.696,470.376 C307.201,473.627 323.455,476.065 334.832,466.312 C342.146,460.623 345.397,450.058 345.397,435.429 C345.397,402.108 327.517,376.914 292.571,362.286 Z M251.937,289.955 C272.254,289.955 289.321,307.022 289.321,327.339 C289.321,347.656 272.254,364.723 251.937,364.723 C231.62,364.723 214.553,347.656 214.553,327.339 C214.552,307.022 231.619,289.955 251.937,289.955 Z M324.267,454.121 C318.578,458.997 305.575,456.559 290.134,454.121 C278.756,452.496 265.753,450.057 251.937,450.057 C238.121,450.057 225.118,451.682 213.74,454.121 C198.299,456.559 185.296,458.184 179.607,454.121 C176.356,451.683 174.731,445.181 174.731,436.242 C174.731,406.172 191.798,386.667 225.931,375.29 C233.245,379.353 242.185,381.792 251.937,381.792 C261.689,381.792 270.629,379.354 277.943,375.29 C312.076,386.668 329.143,406.985 329.143,436.242 C329.143,445.181 327.517,450.87 324.267,454.121 Z"
            id="Shape"
            fill="#5A5665"
          ></path>
          <path
            d="M430.73,212.749 C428.292,274.514 392.533,331.403 337.27,361.473 L345.397,376.102 C405.537,343.594 443.733,281.016 446.984,213.562 L430.73,212.749 Z"
            id="Path"
            fill="#F63A55"
          ></path>
          <path
            d="M252.749,9.574 C220.241,9.574 187.733,17.701 159.289,33.142 L167.416,47.771 C192.61,33.142 221.867,25.828 251.937,25.828 C282.007,25.828 311.264,33.142 337.27,47.771 L345.397,33.142 C316.952,17.701 285.257,9.574 252.749,9.574 Z"
            id="Path"
            fill="#F63A55"
          ></path>
          <path
            d="M73.143,212.749 L56.889,213.562 C60.14,285.079 102.4,349.283 166.603,380.978 L173.917,365.537 C114.59,337.092 76.394,278.578 73.143,212.749 Z"
            id="Path"
            fill="#F63A55"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Groups;
