const XVip = (props) => (
  <svg viewBox="0 0 25 24" {...props}>
    <path
      stroke="#F63A55"
      fillRule="nonzero"
      fill="#F63A55"
      fillOpacity="1"
      d="M12.484 10.934a.544.544 0 0 0-.543.543v4.359a.542.542 0 1 0 1.086 0v-4.36c0-.3-.242-.542-.543-.542ZM11.047 10.969a.542.542 0 0 0-.7.316l-1.12 3-1.122-3a.543.543 0 1 0-1.015.387l1.629 4.355a.541.541 0 0 0 1.015 0l1.63-4.355a.542.542 0 0 0-.317-.703ZM14.656 10.934a.544.544 0 0 0-.543.543v4.359a.542.542 0 1 0 1.086 0v-1.637h.547a1.634 1.634 0 0 0 0-3.265Zm1.633 1.632c0 .301-.246.543-.543.543H15.2v-1.086h.547c.297 0 .543.243.543.543Zm0 0"
    />
    <path
      stroke="none"
      fillRule="nonzero"
      fill="#000"
      fillOpacity="1"
      d="M24.953 9.117a.544.544 0 0 0-.441-.363l-7.867-1.047L12.977.336a.536.536 0 0 0-.485-.3.549.549 0 0 0-.488.3L8.336 7.707.473 8.754a.54.54 0 0 0-.312.922l5.769 5.789-1.575 7.883a.55.55 0 0 0 .204.539c.164.129.39.148.574.054l7.36-3.691 7.359 3.691a.53.53 0 0 0 .57-.055.55.55 0 0 0 .203-.538l-1.57-7.883 5.77-5.79a.546.546 0 0 0 .128-.558Zm-6.871 5.781a.545.545 0 0 0-.148.493l1.414 7.078-6.614-3.313a.527.527 0 0 0-.484 0L5.637 22.47 7.05 15.39a.545.545 0 0 0-.149-.493l-5.199-5.21 7.063-.938a.55.55 0 0 0 .414-.297l3.312-6.656 3.309 6.656c.082.16.238.274.414.297l7.062.938Zm0 0"
    />
  </svg>
);
export default XVip;
