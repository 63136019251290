import { memo } from 'react';

import { useGetHealthCheck } from 'utils/apiQueryHooks';

const HealthCheck = () => {
  useGetHealthCheck(true, { suspense: false });

  return null;
};

HealthCheck.whyDidYouRender = false;

export default memo(HealthCheck);
