import { memo, MouseEventHandler, SyntheticEvent } from 'react';

import cx from 'classnames';

import isKeyboardEvent from 'utils/keyboardEvent';

import { Icon } from '../Icon';

import styles from './NotificationIndicator.module.scss';

type NotificationIndicatorHandler = (
  e: SyntheticEvent<HTMLDivElement, Event>,
) => MouseEventHandler<HTMLDivElement> | undefined | void;

export interface NotificationIndicatorProps {
  hasUnread?: boolean;
  state: 'active' | 'inactive';
  onClick: NotificationIndicatorHandler;
}

export const NotificationIndicator = memo<NotificationIndicatorProps>(
  (props: NotificationIndicatorProps) => {
    const iconClasses = cx(styles.Icon, {
      [styles.Active]: props.state === 'active',
      [styles.Inactive]: props.state === 'inactive',
    });

    return (
      <div
        tabIndex={0}
        onKeyUp={(e) => isKeyboardEvent(e) && props.onClick(e)}
        role="button"
        className={styles.Wrapper}
        onClick={props.onClick}
      >
        {props.hasUnread ? (
          <div className={styles.IconWrapper}>
            <span className={styles.UnreadIndicator} />
            <Icon className={iconClasses} name="notification" />
          </div>
        ) : (
          <Icon className={iconClasses} name="notification" />
        )}
      </div>
    );
  },
);
