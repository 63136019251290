import c from 'classnames';
import { NavLink, Link } from 'react-router-dom';

import coinAnimation from 'assets/coin.gif';
import starIcon from 'assets/stars.png';
import { PROFILE, MY_XTEAM_PROFILE_PATH } from 'consts/routing';
import { useVaultStash } from 'contexts/VaultStashContext';
import { getUnleashLeftValueFormatted } from 'helpers/currency';
import { useGetMyUnleashBudget } from 'utils/apiQueryHooks';

import { User, Cv, Banking, Invoices, Bounties, Unleash } from '../../images';
import { ReactComponent as Document } from '../../images/document.svg';
import { ReactComponent as Refer } from '../../images/refer.svg';

import styles from './UserProfileMenu.module.scss';

type UserProfileMenuOptionProps = {
  toggleOpened: () => void;
};

export const UserProfileMenuOptions: React.FC<UserProfileMenuOptionProps> = ({ toggleOpened }) => {
  const vaultStash = useVaultStash();

  const { resolvedData: myUnleashBudget } = useGetMyUnleashBudget();

  return (
    <div className={c(styles.MenuSection, styles.Links)}>
      <NavLink
        data-testid="navLinkProfileAccount"
        to={PROFILE.PROFILE_PATH}
        className={styles.Link}
        onClick={toggleOpened}
      >
        <div className={styles.LinkImage}>
          <User />
        </div>
        <div className={styles.LinkText}>My Account</div>
      </NavLink>
      <NavLink
        data-testid="navLinkProfileInvoices"
        to={PROFILE.PROFILE_INVOICES_PATH}
        className={styles.Link}
        onClick={toggleOpened}
      >
        <div className={styles.LinkImage}>
          <Invoices />
        </div>
        <div className={styles.LinkText}>Past Invoices</div>
      </NavLink>
      <NavLink
        data-testid="navLinkProfileBanking"
        to={PROFILE.BANKING_PATH}
        className={styles.Link}
        onClick={toggleOpened}
      >
        <div className={styles.LinkImage}>
          <Banking />
        </div>
        <div className={styles.LinkText}>Banking Information</div>
      </NavLink>
      <NavLink to={PROFILE.DOCUMENTS_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Document />
        </div>
        <div className={styles.LinkText}>My Documents</div>
      </NavLink>
      <NavLink to={PROFILE.BOUNTIES_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Bounties />
        </div>
        <div className={styles.LinkText}>Collected Bounties</div>
      </NavLink>

      <NavLink to={PROFILE.UNLEASH_REMAINING_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Unleash />
        </div>
        <div className={styles.LinkText}>Unleash+ Expenses</div>
      </NavLink>

      <NavLink to={PROFILE.REFER_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Refer />
        </div>
        <div className={styles.LinkText}>Refer</div>
      </NavLink>

      <Link
        to={{ pathname: MY_XTEAM_PROFILE_PATH }}
        className={styles.Link}
        target="_blank"
        onClick={toggleOpened}
        rel="noopener noreferrer"
      >
        <div className={styles.LinkImage}>
          <Cv />
        </div>
        <div className={styles.LinkText}>My X-Team Profile</div>
      </Link>

      <div className={styles.AddInfo}>
        <div className={styles.AddInfoItem}>
          <div className={styles.UnleashText}>Unleash+ remaining:</div>
          <div>{getUnleashLeftValueFormatted(myUnleashBudget?.data)}</div>
        </div>
        {myUnleashBudget?.data?.bonuses?.map((bonus, i) => (
          <div className={styles.AddInfoItem} key={i}>
            <div className={styles.UnleashText}>{bonus.unleashCategory?.name} U+ bonus:</div>
            <div>{getUnleashLeftValueFormatted(bonus)}</div>
          </div>
        ))}
        <div className={styles.AddInfoItem}>
          <div className={styles.UnleashText}>Coin stash:</div>
          <div>
            {vaultStash.coins}{' '}
            <img src={coinAnimation} className={styles.CoinAnimation} alt="coin animation" />
          </div>
        </div>
        <div className={styles.AddInfoItem}>
          <div className={styles.UnleashText}>Star stash:</div>
          <div>
            {vaultStash.stars}{' '}
            <img src={starIcon} className={styles.CoinAnimation} alt="star icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
