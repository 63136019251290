import { memo, Suspense } from 'react';

import classnames from 'classnames';

import { InlineLoading } from 'components/Loading';
import Feed from 'components/feed/Feed';
import HomeVideoModal from 'components/modals/HomeVideoModal';
import { useAdminSetting, useIsDashboardFeedEnabled, useIsMobile } from 'utils/hooks';

import { Ads } from '../Ads';
import HomeBounties from '../HomeBounties';
import HomeCards from '../HomeCards';
import { HomeEvents } from '../HomeEvents';
import { HomeVaultItems } from '../HomeVaultItems';
import { Referrals } from '../Referrals';

import styles from './CommunityDashboard.module.scss';

const CommunityDashboard = () => {
  const seasonSettings = useAdminSetting('season');
  const isMobile = useIsMobile();
  const isDashboardFeedEnabled = useIsDashboardFeedEnabled();

  const HeaderSeasonClasses = classnames(styles.HeaderCommon, styles.HeaderSeason);
  const HeaderMottoClasses = classnames(styles.HeaderMotto, styles.HeaderCommon);

  return (
    <div className={styles.Layout}>
      <div className={styles.MainContent}>
        <div className={styles.Header}>
          <div className={HeaderSeasonClasses}>SEASON {seasonSettings?.values.number}</div>
          <div className={HeaderMottoClasses}>{seasonSettings?.values.motto}.</div>
        </div>

        {isDashboardFeedEnabled ? (
          <div className={styles.Feed}>
            <Feed>
              {isMobile && (
                <Suspense fallback={<InlineLoading />}>
                  <div className={styles.ColumnItem}>
                    <Referrals />
                  </div>
                </Suspense>
              )}
            </Feed>
          </div>
        ) : (
          <>
            <main className={styles.Main}>
              <div className={styles.News}>
                <HomeCards />
                <HomeVideoModal />
              </div>
            </main>
            {isMobile ? (
              <Suspense fallback={<InlineLoading />}>
                <div className={styles.ColumnItem}>
                  <Ads />
                </div>
              </Suspense>
            ) : null}
            <Suspense fallback={<InlineLoading />}>
              <HomeBounties />
              <HomeVaultItems />
            </Suspense>
          </>
        )}
      </div>
      {!isMobile && (
        <div className={styles.RightColumnItems}>
          <Suspense fallback={<InlineLoading />}>
            <div className={styles.ColumnItem}>
              <Referrals />
            </div>
          </Suspense>
          <Suspense fallback={<InlineLoading />}>
            <div className={styles.ColumnItem}>
              <Ads />
            </div>
          </Suspense>
          <Suspense fallback={<InlineLoading />}>
            <div className={styles.ColumnItem}>
              <HomeEvents ongoing />
            </div>
          </Suspense>
          <Suspense fallback={<InlineLoading />}>
            <div className={styles.ColumnItem}>
              <HomeEvents />
            </div>
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default memo(CommunityDashboard);
