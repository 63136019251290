import { useEffect, memo, useState, useCallback } from 'react';

import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import cookieCheck from 'third-party-cookie-check';

import Checkbox from 'components/inputs/Checkbox';
import { addToast, logInWithGoogleActionCreator } from 'store/actionCreators';

import styles from './GoogleLogin.module.css';

type GoogleParams = Parameters<typeof gapi.signin2.render>;
type GoogleOptions = Required<GoogleParams[1]>;

const GoogleLoginButton = memo(
  ({
    googleReady,
    onLoginSuccess,
    onLoginFailure,
  }: {
    googleReady: boolean;
    onLoginSuccess: GoogleOptions['onsuccess'];
    onLoginFailure: GoogleOptions['onfailure'];
  }) => {
    const [thirdPartyCookies, setThirdPartyCookies] = useState('');

    useEffect(() => {
      const check3rdPartyCookies = async () => {
        const { supported } = await cookieCheck();
        if (supported === false) {
          setThirdPartyCookies('Please enable third party cookies!');
        }
      };
      check3rdPartyCookies();
      if (googleReady) {
        window.gapi.signin2.render('signInButton', {
          scope: 'profile email',
          width: 240,
          height: 50,
          longtitle: true,
          theme: 'dark',
          onsuccess: onLoginSuccess,
          onfailure: onLoginFailure,
        });
      }
    }, [googleReady, onLoginSuccess, onLoginFailure]);

    return <div id="signInButton">{thirdPartyCookies}</div>;
  },
);

export const GoogleLogin = memo(() => {
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const googleReady = useSelector((state) => state.session?.googleReady ?? false);
  const dispatch = useDispatch();

  const handleCheckboxChange = useCallback((val: boolean) => {
    setKeepMeSignedIn(val);
  }, []);

  const onLoginSuccess = useCallback<GoogleOptions['onsuccess']>(
    (googleUser) => {
      const idToken = googleUser.getAuthResponse().id_token;
      dispatch(logInWithGoogleActionCreator(idToken, keepMeSignedIn)).catch((error) => {
        dispatch(
          addToast({
            text: get(error, 'payload.message') || 'Error logging in',
          }),
        );
      });
    },
    [keepMeSignedIn, dispatch],
  );

  const onLoginFailure = useCallback<GoogleOptions['onfailure']>(
    (response) => {
      if (response.error !== 'popup_closed_by_user') {
        dispatch(
          addToast({
            text: 'Google authentication error',
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <div className={styles.LoginWrapper}>
      <GoogleLoginButton
        googleReady={googleReady}
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure}
      />
      <label className={styles.KeepMeSignedIn} htmlFor="KeepMeSignedIn">
        Keep me signed in
        <Checkbox
          id="KeepMeSignedIn"
          className={styles.KeepMeSignedInCheckbox}
          checked={keepMeSignedIn}
          onChange={handleCheckboxChange}
        />
      </label>
    </div>
  );
});
GoogleLogin.displayName = 'GoogleLogin';
