import { memo } from 'react';

import cs from 'classnames';
import { Link } from 'react-router-dom';

import Button from 'components/buttons/Button';
import { isValidUrl } from 'utils/helpers';

import styles from './CardModule.module.scss';

interface CardModuleProps {
  title: string;
  text: string;
  bottomLink: string;
  image: string;
  bottomLinkText?: string;
  imagePosition: 'left' | 'right';
}

const ImageElement = memo<{
  image: string;
  title: string;
  position: string;
}>(({ image, title, position }) => {
  const mainClass = position === 'right' ? styles.ImageWrapper : styles.ImageWrapperLeft;

  return (
    <div className={mainClass}>
      <img src={image} alt={title} />
    </div>
  );
});

const CardModule = ({
  title,
  text,
  bottomLink,
  image,
  bottomLinkText = 'Learn more here',
  imagePosition,
}: CardModuleProps) => {
  const wrapperClasses = cs(styles.CardModule, {
    [styles.CardModule_reverse]: imagePosition !== 'left',
  });
  const contentClasses = cs(styles.CardModule_content);

  // button link
  const routeRefer = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <article className={wrapperClasses}>
      <ImageElement image={image} title={title} position={imagePosition} />
      <div className={contentClasses}>
        <span className={styles.CardModule_title}>{title}</span>
        <span className={styles.CardModule_text} dangerouslySetInnerHTML={{ __html: text }} />
        <div>
          {isValidUrl(bottomLink) ? (
            <Button
              isInline
              isGray
              onClick={() => routeRefer(bottomLink)}
              className={styles.CardModule_btn}
              data-testid={'link_' + bottomLink}
            >
              {bottomLinkText}
            </Button>
          ) : (
            <Link to={bottomLink}>{bottomLinkText}</Link>
          )}
        </div>
      </div>
    </article>
  );
};

export default memo(CardModule);
