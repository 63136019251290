import { lazy, Suspense } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import { USER_ROLE } from 'consts/constants';
import { PROFILE, APP } from 'consts/routing';
import { useAdminSetting } from 'utils/hooks';

import { PrivateRoute } from '../Auth/authRedirect';
import { InlineLoading } from '../Loading';

const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ 'pages/Profile/Profile'));
const Refer = lazy(() => import(/* webpackChunkName: "Refer" */ 'pages/Refer'));
const Vault = lazy(() => import(/* webpackChunkName: "Vault" */ 'pages/Vault/Vault'));
const Bounties = lazy(() => import(/* webpackChunkName: "Bounties" */ 'pages/Bounties/Bounties'));
const UserBounties = lazy(
  () => import(/* webpackChunkName: "UserBounties" */ 'pages/UserBounties/UserBounties'),
);
const Missions = lazy(() => import(/* webpackChunkName: "Missions" */ 'pages/Missions/Missions'));
const Legends = lazy(() => import(/* webpackChunkName: "Legends" */ 'pages/Legends/Legends'));

const adminCommunityPeople = [
  USER_ROLE.ADMIN,
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.COMMUNITY_TEAM,
  USER_ROLE.PEOPLE_OPS,
];
const allUserRoles = Object.values(USER_ROLE);

const XvipPage = () => (
  <Suspense fallback={<InlineLoading />}>
    <Switch>
      <Route
        exact
        path={APP.HOME_PATH}
        render={() => (
          <Redirect
            to={{
              pathname: APP.BOUNTIES_PATH,
            }}
          />
        )}
      />
      <Route path={PROFILE.PROFILE_PATH} component={Profile} />
      <Route path={PROFILE.BOUNTIES_PATH} component={UserBounties} />
      <Route path={APP.REFER_PATH} component={Refer} />
      <Route path={APP.BOUNTIES_PATH} component={Bounties} />
      <Route path={APP.VAULT_PATH} component={Vault} />
      <PrivateRoute
        allowedRoles={
          useAdminSetting('missions')?.values.missionsPageForUsers
            ? allUserRoles
            : adminCommunityPeople
        }
        path={APP.MISSIONS_PATH}
        component={Missions}
      />
      <PrivateRoute
        allowedRoles={
          useAdminSetting('legends')?.values.legendsPageForUsers
            ? allUserRoles
            : adminCommunityPeople
        }
        path={APP.LEGENDS_PATH}
        component={Legends}
      />
    </Switch>
  </Suspense>
);

export default XvipPage;
