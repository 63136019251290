import { memo, ReactNode, Children, isValidElement, cloneElement } from 'react';

import classNames from 'classnames';

import { Paragraph } from '../Paragraph';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  id?: string;
  text: string;
  placement: 'top' | 'bottom' | 'right' | 'left';
  children: ReactNode;
}

const isRight = (placement: string) => placement === 'right';
const isLeft = (placement: string) => placement === 'left';
const isTop = (placement: string) => placement === 'top';
const isBottom = (placement: string) => placement === 'bottom';

export const Tooltip = memo<TooltipProps>((props: TooltipProps) => {
  const backupPlacement = props.placement ?? 'top';

  const TooltipContent = () => {
    const tooltipClasses = classNames(styles.Tooltip, {
      [styles.PlacementBottom]: isBottom(backupPlacement),
      [styles.PlacementTop]: isTop(backupPlacement),
      [styles.PlacementRight]: isRight(backupPlacement),
      [styles.PlacementLeft]: isLeft(backupPlacement),
    });

    return (
      <div id={props.id} className={tooltipClasses}>
        <Paragraph className={styles.TooltipText} variant="ui-regular">
          {props.text}
        </Paragraph>
      </div>
    );
  };

  const childrenWithTooltip = Children.map(props.children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(
        <div>
          <TooltipContent />
          {child}
        </div>,
        {
          className: styles.TooltipParent,
        },
      );
    }

    return child;
  });

  return <>{childrenWithTooltip}</>;
});
