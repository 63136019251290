import { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';

interface useIntersectionApiProps {
  topOffset?: number;
  enabled?: boolean;
}

export const useIntersectionApi = (props: useIntersectionApiProps) => {
  const { topOffset, enabled } = {
    ...{ topOffset: 0, enabled: true },
    ...props,
  };

  const [inView, setInView] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setInView(entry.isIntersecting);
        },
        {
          rootMargin: `${-topOffset}px`,
        },
      );

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, topOffset, setInView]);

  return { ref, inView: enabled ? inView : true };
};

export interface StickyContainerProps extends useIntersectionApiProps {}

export const StickyContainer = memo<PropsWithChildren<StickyContainerProps>>(
  ({ children, enabled = true, topOffset = 0 }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [placeholderWidth, setPlaceholderWidth] = useState<number>();

    const { ref, inView } = useIntersectionApi({ topOffset, enabled });

    useEffect(() => {
      setPlaceholderWidth(contentRef.current?.offsetWidth);
    }, [setPlaceholderWidth]);

    return (
      <div>
        <div ref={ref} style={{ width: placeholderWidth, height: 0 }} />

        <div
          style={{
            position: inView ? 'initial' : 'fixed',
            top: topOffset,
            height: 'fit-content',
          }}
          ref={contentRef}
        >
          {children}
        </div>
      </div>
    );
  },
);
