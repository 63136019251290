const User = (props) => (
  <svg viewBox="0 0 19 32" height="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14 11c0 2.761-2.015 5-4.5 5S5 13.761 5 11s2.015-5 4.5-5S14 8.239 14 11z"
        stroke="#5A5665"
      />
      <path d="M18 27H1c0-4.97 3.768-9 8.417-9h.166C14.232 18 18 22.03 18 27z" stroke="#5A5665" />
    </g>
  </svg>
);

export default User;
