import * as constants from 'consts/constants';

export function dragAndDrop(state, { type, payload }) {
  if (type === constants.DRAG_EVENTS.start) {
    return {
      ...state,
      ...payload,
      isDragging: true,
    };
  }

  if (type === constants.DRAG_EVENTS.end) {
    // dropped outside the list, update the `isDragging` flag only
    if (payload && !payload.destination) {
      return {
        ...state,
        isDragging: false,
      };
    }

    // Otherwise, update the whole drag n drop data
    return {
      ...state,
      ...payload,
      isDragging: false,
    };
  }

  return { ...state };
}
