import { memo, ChangeEventHandler, SyntheticEvent, KeyboardEvent } from 'react';

import cx from 'classnames';

import isKeyboardEvent from 'utils/keyboardEvent';

import { Icon } from '../Icon';
import { Image } from '../Image';
import { Paragraph } from '../Paragraph';

import styles from './Badge.module.scss';

type BadgeHandler = (
  e: SyntheticEvent<HTMLSpanElement, Event> | undefined,
) => ChangeEventHandler<HTMLSpanElement | undefined> | void;

export interface BadgeProps {
  id?: string;
  type:
    | 'regular'
    | 'dark'
    | 'common'
    | 'rare'
    | 'legendary'
    | 'featured'
    | 'sold-out'
    | 'coming-soon'
    | 'unavailable'
    | 'historic';
  text: string;
  showBackground: boolean;
  showIcon: boolean;
  iconSrc?: string;
  interactive: boolean;
  spanClassName?: string;
  paragraphClassName?: string;
  onClick?: BadgeHandler;
}

const isDark = (type: string) => type === 'dark';
const isRegular = (type: string) => type === 'regular';
const isCommon = (type: string) => type === 'common';
const isRare = (type: string) => type === 'rare';
const isLegendary = (type: string) => type === 'legendary';
const isFeatured = (type: string) => type === 'featured';
const isSoldOut = (type: string) => type === 'sold-out';
const isComingSoon = (type: string) => type === 'coming-soon';
const isUnavailable = (type: string) => type === 'unavailable';
const isHistoric = (type: string) => type === 'historic';

const isSpecial = (type: string) =>
  isCommon(type) || isRare(type) || isLegendary(type) || isFeatured(type);

const getIconFromType = (type: string) => {
  if (isCommon(type)) return <Icon name="common" className={styles.BadgeIcon} />;
  else if (isRare(type)) return <Icon name="rare" className={styles.BadgeIcon} />;
  else if (isLegendary(type)) return <Icon name="legendary" className={styles.BadgeIcon} />;
  else if (isFeatured(type)) return <Icon name="featured" className={styles.BadgeIcon} />;
  else if (isRare(type)) return <Icon name="rare" className={styles.BadgeIcon} />;
  else return <Icon name="common" className={styles.BadgeIcon} />;
};

export const Badge = memo<BadgeProps>((props: BadgeProps) => {
  const paragraphClasses = cx(props.paragraphClassName, {
    [styles.BadgeText]: !isDark(props.type) || isHistoric(props.type),
    [styles.BadgeTextDark]:
      isDark(props.type) ||
      isSoldOut(props.type) ||
      isComingSoon(props.type) ||
      isUnavailable(props.type),
  });

  const spanClasses = cx(props.spanClassName, {
    [styles.BadgeClickable]: props.interactive,
    [styles.BadgeNoBackground]: !props.showBackground,
    [styles.BadgeDark]: isDark(props.type),
    [styles.BadgeRegular]: isRegular(props.type),
    [styles.BadgeCommon]: isCommon(props.type),
    [styles.BadgeRare]: isRare(props.type),
    [styles.BadgeLegendary]: isLegendary(props.type),
    [styles.BadgeFeatured]: isFeatured(props.type),
    [styles.BadgeSoldOut]: isSoldOut(props.type),
    [styles.BadgeComingSoon]: isComingSoon(props.type),
    [styles.BadgeUnavailable]: isUnavailable(props.type),
    [styles.BadgeHistoric]: isHistoric(props.type),
  });

  const extraProps = props.interactive && {
    role: 'button',
    tabIndex: 0,
    onClick: props.onClick,
    onKeyUp: (e: KeyboardEvent<HTMLSpanElement>) =>
      isKeyboardEvent(e) && props.onClick ? props?.onClick(e) : undefined,
  };

  return (
    <span id={props.id} className={spanClasses} {...extraProps}>
      {props.showIcon && isSpecial(props.type) && <>{getIconFromType(props.type)}</>}

      {props.showIcon && !isSpecial(props.type) && props.iconSrc && (
        <Image
          variant="squared"
          src={props.iconSrc}
          className={styles.BadgeIcon}
          alt="badge icon"
        />
      )}

      <Paragraph variant="ui-bold" className={paragraphClasses}>
        {props.text}
      </Paragraph>
    </span>
  );
});
