import { ChangeEvent, HTMLProps } from 'react';

import classNames from 'classnames';

import styles from './Checkbox.module.scss';

const Checkbox = ({ onChange, checked, isModal, id, disabled, className, ...rest }: Props) => {
  const toggleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange(!checked);
  };

  const Classes = classNames(
    styles.Checkbox,
    isModal && styles.Checkbox_modal,
    disabled && styles.Checkbox__disabled,
    className,
  );

  return (
    <label className={Classes}>
      <input
        {...rest}
        type="checkbox"
        checked={checked}
        onChange={toggleCheck}
        id={id}
        disabled={disabled}
      />
      <span />
    </label>
  );
};

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
  onChange: (value: boolean) => void;
  isModal?: boolean;
};

export default Checkbox;
