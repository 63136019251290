import { memo } from 'react';

import cs from 'classnames';

import styles from './paragraph.module.scss';

type VariantLevel = 'bold' | 'regular' | 'ui-bold' | 'ui-regular' | 'label';
export type ParagraphProps = {
  variant?: VariantLevel;
} & JSX.IntrinsicElements['p'];

export const Paragraph = memo<ParagraphProps>(
  ({ variant = 'regular', children, className, ...props }) => {
    const classnames = cs(className, styles.p, {
      [styles['p--variant--bold']]: variant === 'bold',
      [styles['p--variant--regular']]: variant === 'regular',
      [styles['p--variant--ui-bold']]: variant === 'ui-bold',
      [styles['p--variant--ui-regular']]: variant === 'ui-regular',
      [styles['p--variant--label']]: variant === 'label',
    });

    return (
      <p {...props} className={classnames}>
        {children}
      </p>
    );
  },
);
