const Users = (props) => (
  <svg viewBox="0 0 28 25" width="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 4.5C10 6.433 8.657 8 7 8S4 6.433 4 4.5 5.343 1 7 1s3 1.567 3 3.5z"
        stroke="#5A5665"
      />
      <path d="M12 16H1c0-3.314 2.438-6 5.446-6h.108C9.562 10 12 12.686 12 16" fill="#FFF" />
      <path
        d="M13 15H1c0-3.314 2.66-6 5.941-6h.118C10.34 9 13 11.686 13 15zM24 4.5C24 6.433 22.657 8 21 8s-3-1.567-3-3.5S19.343 1 21 1s3 1.567 3 3.5z"
        stroke="#5A5665"
      />
      <path d="M26 16H15c0-3.314 2.438-6 5.446-6h.108C23.562 10 26 12.686 26 16" fill="#FFF" />
      <path d="M27 15H15c0-3.314 2.66-6 5.941-6h.118C24.34 9 27 11.686 27 15z" stroke="#5A5665" />
      <path
        d="M17 13.5c0 1.933-1.343 3.5-3 3.5s-3-1.567-3-3.5 1.343-3.5 3-3.5 3 1.567 3 3.5z"
        stroke="#F63A55"
        fill="#FFF"
      />
      <path d="M19 25H8c0-3.314 2.438-6 5.446-6h.108C16.562 19 19 21.686 19 25" fill="#FFF" />
      <path d="M20 24H8c0-3.314 2.66-6 5.941-6h.118C17.34 18 20 20.686 20 24z" stroke="#F63A55" />
    </g>
  </svg>
);

export default Users;
