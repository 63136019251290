import { memo, useMemo } from 'react';

import { sample } from 'lodash';

import urlLion from 'assets/season4/lion.png';
import urlNightclaw from 'assets/season4/nightclaw.png';
import Wrap from 'components/Wrap';
import PageTitle from 'components/page/PageTitle';
import PageToasts from 'components/toasts/PageToasts';
import { useAdminSetting } from 'utils/hooks';

import { Login } from '../Login';

import styles from './LoginPage.module.scss';

const LoginPage = memo(() => {
  const seasonSettings = useAdminSetting('season');

  const randomSpotlightImage = useMemo<string | undefined>(() => {
    const loginImageList = seasonSettings?.values.loginScreenImages;

    const images = loginImageList?.length
      ? loginImageList.map((image) => image.location)
      : [urlLion, urlNightclaw];

    return sample(images);
  }, [seasonSettings]);

  return (
    <Wrap className={styles.FullWidthHeight} innerWrapClassName={styles.FullWidthHeight}>
      <PageToasts />
      <main className={styles.WelcomePage}>
        <div className={styles.HalfLogin}>
          <div className={styles.LoginWrapper}>
            <PageTitle className={styles.Title}>
              <i>
                Keep <br />
                Moving <br />
                Forward.
              </i>
            </PageTitle>
            <p className={styles.IntroText}>
              You’re one click away from the XHQ. <br />
              Login using your x-team.com account.
            </p>
            <Login />
          </div>
        </div>
        <div
          data-testid="backgroundImage"
          className={styles.HalfSpotlight}
          style={{ backgroundImage: `url(${randomSpotlightImage})` }}
        />
      </main>
    </Wrap>
  );
});

export default LoginPage;
