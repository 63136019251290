import { ReactNode, useRef, useState } from 'react';

import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './SnackbarAlert.module.scss';

export enum SNACKBAR_ALERT_TYPES {
  success = 'success',
  error = 'error',
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
} as any;

export const SnackbarAlert = ({
  children,
  type = SNACKBAR_ALERT_TYPES.success,
  isOpened = false,
  onClick = () => {},
}: Props) => {
  const snackbarBodyClassNames = classNames({
    [styles.SnackbarAlertBody]: true,
    [styles.SnackbarAlertSuccess]: type === SNACKBAR_ALERT_TYPES.success,
    [styles.SnackbarAlertError]: type === SNACKBAR_ALERT_TYPES.error,
    [styles.SnackbarAlertContainerHidden]: !isOpened,
  });

  return (
    <div
      onClick={onClick}
      onKeyUp={(e) => isKeyboardEvent(e) && onClick()}
      role="button"
      tabIndex={0}
      className={styles.SnackbarAlertContainer}
    >
      <Transition in={isOpened} timeout={500}>
        {(state) => (
          <div className={snackbarBodyClassNames} style={transitionStyles[state]}>
            <div className={styles.SnackbarAlertContent}>{children}</div>
          </div>
        )}
      </Transition>
    </div>
  );
};

interface Props {
  children?: ReactNode;
  type?: SNACKBAR_ALERT_TYPES;
  isOpened?: boolean;
  onClick?: () => void;
}

export function useSnackbarAlert(
  closeAfter = 3000,
): [boolean, (type?: SNACKBAR_ALERT_TYPES) => void, SNACKBAR_ALERT_TYPES] {
  const [isOpened, setIsOpened] = useState(false);
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_ALERT_TYPES.success);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const openSnackBar = (type: SNACKBAR_ALERT_TYPES = SNACKBAR_ALERT_TYPES.success) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setSnackbarType(type);
    setIsOpened(true);
    timerRef.current = setTimeout(() => {
      setIsOpened(false);
    }, closeAfter);
  };

  return [isOpened, openSnackBar, snackbarType];
}
