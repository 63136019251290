import { ComponentType } from 'react';

import { ACTIVITY_STATUS, USER_ROLE } from 'consts/constants';
import { ADMIN, APP, HANDBOOK_PATH } from 'consts/routing';
import type { UserRole, DisabledMenuItems } from 'types/types';
import { isAirwallexEnabled } from 'utils/helpers';

import type { MenuProps } from './Menu';
import images from './images';

type PickProps =
  | 'user'
  | 'approvedInvoiceStatus'
  | 'submittedInvoiceStatus'
  | 'queuedInvoiceStatus'
  | 'projectsOwners'
  | 'admin'
  | 'superAdmin'
  | 'communityTeam'
  | 'manager'
  | 'coreManager'
  | 'peopleOps'
  | 'recruiter'
  | 'isDashboardXvipEnabled';
type MenuLinkOptions = Pick<MenuProps, PickProps>;

const RUN_PAYMENT_INSERT_INDEX = 3 as const;

const getTogglableMenuItem = (
  item: RenderLinkOptions,
  disable?: boolean,
): RenderLinkOptions | null => (disable ? null : item);

export type MainMenuItemsOptions = {
  seasonNumber?: number;
  disabledItems?: DisabledMenuItems;
};
export const getMainMenuItemsData = ({
  seasonNumber,
  disabledItems,
}: MainMenuItemsOptions): RenderLinkOptions[] => {
  const excludedFrom: UserRole[] = [USER_ROLE.XVIP_USER];

  return [
    {
      link: '/submit',
      text: 'Submit invoice',
      ImageEl: images.Add,
      excludedFrom,
    },
    {
      link: '/unleash',
      text: 'Unleash+',
      ImageEl: images.Unleash,
      excludedFrom,
    },
    { link: '/refer', text: 'Refer', ImageEl: images.Refer },
    {
      link: '/vacation-request',
      text: 'Vacation Request',
      ImageEl: images.Vacation,
      excludedFrom,
    },
    { link: '/vault', text: 'The Vault', ImageEl: images.Vault },
    {
      link: `/bounties?show=all&season=${seasonNumber}`,
      text: 'Bounties',
      ImageEl: images.Bounties,
    },
    {
      link: `/missions?show=all&season=${seasonNumber}`,
      text: 'Missions',
      ImageEl: images.Missions,
      excludedFrom: disabledItems?.missions ? excludedFrom.concat(USER_ROLE.USER) : excludedFrom,
    },
    {
      link: APP.LEGENDS_PATH,
      text: 'Legends',
      ImageEl: images.Legends,
      excludedFrom: disabledItems?.legends
        ? excludedFrom.concat([USER_ROLE.USER, USER_ROLE.MANAGER, USER_ROLE.CORE_MANAGER])
        : excludedFrom,
    },
    getTogglableMenuItem(
      {
        link: APP.HOUSES_PATH,
        text: 'Houses',
        ImageEl: images.Teams,
        excludedFrom,
      },
      disabledItems?.houses,
    ),
    {
      link: HANDBOOK_PATH,
      text: 'Handbook',
      ImageEl: images.Raids,
      external: true,
      excludedFrom,
    },
  ].filter(Boolean) as RenderLinkOptions[];
};

export const getAdminMenuItemsData = (type: MenuSection, options: MenuLinkOptions) => {
  const {
    user,
    approvedInvoiceStatus,
    submittedInvoiceStatus,
    queuedInvoiceStatus,
    projectsOwners,
    admin,
    superAdmin,
    communityTeam,
    manager,
    coreManager,
    peopleOps,
    recruiter,
    isDashboardXvipEnabled,
  } = options;
  const userId = user?.id ?? '';
  const managesProjects = projectsOwners?.some((x) => x.id === userId);
  let menuLinks: RenderLinkOptions[] = [];

  switch (type) {
    case 'admin':
      menuLinks = [
        {
          link: {
            pathname: ADMIN.REPORTS_PATH,
          },
          text: 'Reports',
          ImageEl: images.Reports,
        },
        {
          link: {
            pathname: ADMIN.APPROVAL_LIST_PATH,
            search: `?_order=_invoice.submittedAt_desc&status=${
              submittedInvoiceStatus ? submittedInvoiceStatus.id : ''
            }${managesProjects ? `&manager=${userId}` : ''}`,
          },
          text: 'Approvals',
          ImageEl: images.Approvals,
        },
        {
          link: {
            pathname: ADMIN.ACCOUNTING_PATH,
            search: `?status=${approvedInvoiceStatus ? approvedInvoiceStatus.id : ''}`,
          },
          text: 'Accounting',
          ImageEl: images.Accounting,
        },
        {
          link: {
            pathname: ADMIN.USER_LIST_PATH,
            search: `?status=${ACTIVITY_STATUS.ACTIVE}&_order=displayName_asc`,
          },
          text: 'Users',
          ImageEl: images.Users,
        },
        {
          link: {
            pathname: ADMIN.PROJECT_LIST_PATH,
            search: managesProjects ? `?owners=${userId}` : '',
          },
          text: 'Projects',
          ImageEl: images.Projects,
        },
        { link: ADMIN.COMPANIES_LIST_PATH, text: 'Companies', ImageEl: images.Companies },
        {
          link: {
            pathname: ADMIN.VACATION_REQUESTS_PATH,
            search: managesProjects ? `?manager=${userId}` : '',
          },
          text: 'Vacation Requests',
          ImageEl: images.Vacation,
        },
        {
          link: ADMIN.USERS_GROUPS_PATH,
          text: 'Groups',
          ImageEl: images.Groups,
        },
        {
          link: ADMIN.REFERRALS_MANAGEMENT_PATH,
          text: 'Referrals',
          ImageEl: images.Refer,
        },
      ];

      if (isDashboardXvipEnabled) {
        menuLinks.splice(4, 0, {
          link: {
            pathname: ADMIN.XVIP_LIST_PATH,
            search: `?status=${ACTIVITY_STATUS.ACTIVE}&_order=displayName_asc`,
          },
          text: 'XVIP',
          ImageEl: images.XVip,
        });
      }

      if (superAdmin && isAirwallexEnabled()) {
        menuLinks = [
          ...menuLinks.slice(0, RUN_PAYMENT_INSERT_INDEX),
          {
            link: {
              pathname: ADMIN.RUN_PAYMENTS_PATH,
              search: `?status=${queuedInvoiceStatus ? queuedInvoiceStatus.id : ''}`,
            },
            text: 'Run Payments',
            ImageEl: images.RunPayments,
          },
          ...menuLinks.slice(RUN_PAYMENT_INSERT_INDEX),
        ];
      }
      break;
    case 'community':
      menuLinks = [
        { link: ADMIN.QUESTIONS_PATH, text: 'Questions', ImageEl: images.Questions },
        { link: ADMIN.ANSWERS_PATH, text: 'Answers', ImageEl: images.Answers },
        {
          link: ADMIN.UNLEASH_CATEGORIES_PATH,
          text: 'Unleash+ Categories',
          ImageEl: images.Unleash,
        },
        { link: ADMIN.VAULT_PATH, text: 'Vault Items', ImageEl: images.Vault },
        { link: ADMIN.VAULT_COLLECTIONS_PATH, text: 'Vault Collections', ImageEl: images.Vault },
        {
          link: {
            pathname: ADMIN.BOUNTIES_EDIT_PATH,
            search: 'status=true',
          },
          text: 'Edit Bounties',
          ImageEl: images.Achievements2,
        },
        {
          link: {
            pathname: ADMIN.BOUNTIES_COLLECTIONS_PATH,
            search: 'status=true',
          },
          text: 'Bounties Collections',
          ImageEl: images.Achievements2,
        },
        { link: ADMIN.HOUSES_LIST_PATH, text: 'Houses', ImageEl: images.Teams },
        { link: ADMIN.LEGENDS_PATH, text: 'Legends', ImageEl: images.Legends },
        { link: ADMIN.MISSIONS_PATH, text: 'Missions', ImageEl: images.Missions },
        { link: ADMIN.OBJECTIVES_PATH, text: 'Objectives', ImageEl: images.Objectives },
        { link: ADMIN.EVENTS_PATH, text: 'Events', ImageEl: images.Vacation },
        { link: ADMIN.BETS_PATH, text: 'Bets', ImageEl: images.Bets },
      ];
      break;

    case 'settings':
      menuLinks = [{ link: ADMIN.SETTINGS_PATH, text: 'Settings', ImageEl: images.Settings }];
  }

  let allowedLinks: string[] = [];
  if (admin || superAdmin) {
    allowedLinks = [];
  } else if (communityTeam) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.XVIP_LIST_PATH,
      ADMIN.XVIP_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.VAULT_COLLECTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.BOUNTIES_COLLECTIONS_PATH,
      ADMIN.MISSIONS_PATH,
      ADMIN.OBJECTIVES_PATH,
      ADMIN.LEGENDS_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.BETS_PATH,
    ];
  } else if (manager) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.APPROVAL_LIST_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.XVIP_LIST_PATH,
      ADMIN.XVIP_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.MISSIONS_PATH,
      ADMIN.OBJECTIVES_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.SETTINGS_PATH,
    ];
  } else if (coreManager) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.APPROVAL_LIST_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.XVIP_LIST_PATH,
      ADMIN.XVIP_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.MISSIONS_PATH,
      ADMIN.OBJECTIVES_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.SETTINGS_PATH,
    ];
  } else if (peopleOps) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.ACCOUNTING_PATH,
      ADMIN.APPROVAL_LIST_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.XVIP_LIST_PATH,
      ADMIN.XVIP_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_COLLECTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.BOUNTIES_COLLECTIONS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.MISSIONS_PATH,
      ADMIN.OBJECTIVES_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.LEGENDS_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.BETS_PATH,
    ];
  } else if (recruiter) {
    allowedLinks = [ADMIN.REFERRALS_MANAGEMENT_PATH];
  }

  if (allowedLinks.length !== 0) {
    return menuLinks.filter((item) =>
      allowedLinks.includes(typeof item.link === 'string' ? item.link : item.link.pathname),
    );
  } else {
    return menuLinks;
  }
};

type MenuSection = 'admin' | 'community' | 'settings';

export interface RenderLinkOptions {
  link: { pathname: string; search?: string } | string;
  text: string;
  ImageEl: ComponentType;
  sublinks?: {
    link: { pathname: string; search?: string } | string;
    text: string;
    ImageEl: ComponentType;
  }[];
  external?: boolean;
  sublink?: boolean;
  onClickHandler?: (() => void) | null;
  key?: string;
  excludedFrom?: UserRole[];
}
