function SvgSettings(props) {
  return (
    <svg viewBox="0 0 25 31" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M24.575 3.776L12.67.026a.57.57 0 00-.341 0L.424 3.776A.622.622 0 000 4.375v13.75c0 5.528 8.026 10.444 12.324 11.848a.56.56 0 00.352 0C16.974 28.568 25 23.653 25 18.125V4.375a.622.622 0 00-.425-.599zm-.765 14.349c0 4.389-6.623 9.004-11.31 10.595-4.687-1.591-11.31-6.206-11.31-10.595V4.84L12.5 1.278 23.81 4.84v13.285z"
          fill="#5A5665"
        />
        <path
          d="M18.68 12.179a.62.62 0 00-.32-.369.57.57 0 00-.477-.005.57.57 0 01-.743-.244.627.627 0 01.173-.795.633.633 0 00.233-.436.64.64 0 00-.144-.476c-.971-1.16-2.222-1.928-3.618-2.218a.564.564 0 00-.47.11.632.632 0 00-.238.44c-.03.322-.278.564-.574.564-.296 0-.544-.242-.575-.565a.628.628 0 00-.238-.439.57.57 0 00-.47-.11c-1.395.29-2.647 1.058-3.618 2.218a.641.641 0 00-.144.476.628.628 0 00.233.436.63.63 0 01.172.8.564.564 0 01-.74.242.566.566 0 00-.477.005.614.614 0 00-.32.368c-.249.77-.37 1.488-.37 2.195 0 .708.121 1.425.37 2.195.053.165.17.299.321.37.15.07.323.073.475.005a.565.565 0 01.742.243.63.63 0 01-.173.796.633.633 0 00-.233.436.636.636 0 00.145.476c.97 1.16 2.222 1.927 3.617 2.218a.57.57 0 00.47-.11.63.63 0 00.238-.439c.031-.323.279-.566.575-.566.296 0 .543.242.574.565a.625.625 0 00.238.439c.104.08.228.122.354.122a.37.37 0 00.117-.015c1.395-.29 2.646-1.057 3.616-2.217a.637.637 0 00.146-.476.626.626 0 00-.234-.437.628.628 0 01-.172-.796.566.566 0 01.742-.242.57.57 0 00.477-.005.612.612 0 00.32-.37c.249-.773.37-1.49.37-2.194 0-.708-.121-1.425-.37-2.195zm-.98 3.466c-.592-.008-1.258.316-1.59.919-.333.601-.3 1.332.035 1.902a5.245 5.245 0 01-2.08 1.286c-.297-.6-.888-1.002-1.563-1.002-.675 0-1.266.401-1.565 1.002a5.23 5.23 0 01-2.08-1.286c.337-.57.368-1.301.037-1.904a1.749 1.749 0 00-1.59-.918 5.387 5.387 0 01-.159-1.269c0-.409.053-.828.159-1.269a1.763 1.763 0 001.589-.917 1.936 1.936 0 00-.035-1.905 5.245 5.245 0 012.08-1.287c.298.602.89 1.003 1.564 1.003.675 0 1.266-.401 1.564-1a5.23 5.23 0 012.08 1.286 1.934 1.934 0 00-.036 1.903c.332.603.952.948 1.591.918.106.442.159.86.159 1.27 0 .407-.053.825-.16 1.268z"
          fill="#F63A55"
        />
        <path
          d="M12.502 11.25c-1.641 0-2.976 1.402-2.976 3.125 0 1.722 1.335 3.125 2.976 3.125 1.642 0 2.977-1.403 2.977-3.125 0-1.723-1.335-3.125-2.977-3.125zm0 5c-.984 0-1.785-.841-1.785-1.875s.8-1.875 1.785-1.875c.985 0 1.786.841 1.786 1.875s-.801 1.875-1.786 1.875z"
          fill="#F63A55"
        />
      </g>
    </svg>
  );
}

export default SvgSettings;
