import { useState, useEffect } from 'react';

import c from 'classnames';
import { Image, Video, Placeholder } from 'cloudinary-react';

import { Activity } from '../Feed.types';
import { ReactComponent as MultiIcon } from '../Layers.svg';
import MultiPostMedia from '../MultiPostMedia';
import { ReactComponent as PlayIcon } from '../PlayArrow.svg';

import styles from './PostMedia.module.scss';

export interface Media {
  type: string;
  publicId: string;
  bytes?: number;
  format?: string;
  height?: number;
  width?: number;
}

const PostMedia = ({ activity, pinned }: PostMediaProps) => {
  const {
    mediaImages = [],
    mediaVideos = [],
    mediaImagesMeta = [],
    mediaVideosMeta = [],
  } = activity;

  const [primaryMedia, setPrimaryMedia] = useState<Media>();
  const [isPrimaryMulti, SetIsPrimaryMulti] = useState<boolean>(false);
  const [numberOfMedia, setNumberOfMedia] = useState<number>(0);
  const [allMedia, setAllMedia] = useState<Array<Media>>([]);

  useEffect(() => {
    if (!mediaImages?.length && !mediaVideos?.length) return;

    const medias = [
      ...mediaVideos.map((v) => ({ type: 'video', publicId: v })),
      ...mediaImages.map((i) => ({ type: 'image', publicId: i })),
    ];

    const mediasMeta = [...mediaVideosMeta, ...mediaImagesMeta];

    const [primary] = medias;
    const [primaryMeta] = mediasMeta;

    let mediasLength = medias.length <= 4 ? medias.length : 4;

    setAllMedia(medias);
    setNumberOfMedia(mediasLength);
    SetIsPrimaryMulti(medias.length > 1);
    setPrimaryMedia({ ...primary, ...(primaryMeta || {}) });
  }, [mediaImages, mediaVideos, mediaImagesMeta, mediaVideosMeta]);

  const getMediaByType = (media?: Media, options?: MediaOptions) => {
    if (!media) return null;

    switch (media.type) {
      case 'image':
        return (
          <Image
            className={styles.Media}
            loading="lazy"
            publicId={media?.publicId}
            alt="Upload widget"
          >
            <Placeholder type="pixelate" />
          </Image>
        );
      case 'video':
        return (
          <Video
            className={styles.Media}
            publicId={media?.publicId}
            controls={!!options?.playerControls}
          />
        );
    }
  };

  const isPrimaryVideo = () => {
    return primaryMedia?.type === 'video';
  };

  if (!mediaImages?.length && !mediaVideos?.length) {
    return null;
  }

  return (
    <div className={!pinned ? styles.ContainerWithMulti : c(styles.Container, styles.Pinned)}>
      <div className={c(styles.OverIconContainer, pinned && styles.Pinned)}>
        {isPrimaryMulti && pinned && (
          <div>
            <MultiIcon width="18px" />
          </div>
        )}
        {isPrimaryVideo() && pinned && (
          <div>
            <PlayIcon width="11px" />
          </div>
        )}
      </div>
      {pinned ? (
        getMediaByType(primaryMedia, { playerControls: false })
      ) : numberOfMedia > 0 ? (
        <MultiPostMedia numberOfMedia={numberOfMedia} media={allMedia} />
      ) : (
        <></>
      )}
    </div>
  );
};

export interface MediaOptions {
  playerControls: boolean;
}

export interface PostMediaProps {
  activity: Activity;
  pinned?: boolean;
}

export default PostMedia;
