import { memo, ReactNode } from 'react';

import classNames from 'classnames';
import { Heading, Icon, Tooltip } from 'design-system';
import { Carousel } from 'react-responsive-carousel';

import { SlideArrowLeft, SlideArrowRight } from 'components/feed/SlideArrows';
import { ImageOptions, VaultItemModel } from 'types/types';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './ViewOnlyVaultItem.module.scss';

export type ViewOnlyVaultItemProps = {
  isHome?: boolean;
  className?: string;
  item: VaultItemModel;
  onClick?: () => void;
  bottomContent?: ReactNode;
  imageOptions?: ImageOptions;
};

const ViewOnlyVaultItem = memo((props: ViewOnlyVaultItemProps) => {
  const {
    isHome,
    className,
    bottomContent = null,
    item: { images = [], itemName, stock, _rarity: { name: rarityName = '' } = {} },
    onClick,
    imageOptions: { loading, width, height } = {},
  } = props;
  const viewOnlyVaultItemClass = classNames(styles.ViewOnlyVaultItem, className, {
    [styles.ViewOnlyVaultItemHome]: isHome,
    [styles.ViewOnlyVaultItemRare]: rarityName === 'Rare',
    [styles.ViewOnlyVaultItemLegendary]: rarityName === 'Legendary',
    [styles.ViewOnlyVaultItemCommon]: rarityName === 'Common',
  });

  return (
    <div className={viewOnlyVaultItemClass}>
      {images.length > 0 && (
        <Carousel
          renderThumbs={() => []}
          renderArrowNext={SlideArrowRight}
          renderArrowPrev={SlideArrowLeft}
          showStatus={false}
          renderIndicator={() => null}
        >
          {images.map((file, index) => (
            <div
              key={index}
              className={styles.ImageCarousel}
              role="button"
              tabIndex={0}
              onClick={onClick}
              onKeyUp={(e) => onClick !== undefined && isKeyboardEvent(e) && onClick()}
            >
              <div className={styles.ImageCarouselContent}>
                <img
                  src={file?.location}
                  alt={`${itemName} ${index}`}
                  className={classNames({
                    [styles.VaultItemNoStockImage]: stock === 0,
                  })}
                  loading={loading}
                  width={width}
                  height={height}
                />
              </div>
            </div>
          ))}
        </Carousel>
      )}

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={props.onClick}
        onKeyUp={(e) => props.onClick !== undefined && isKeyboardEvent(e) && props.onClick()}
      >
        <div className={styles.TitleBox}>
          <div className={styles.TitleContent}>
            {rarityName ? (
              <Tooltip text={rarityName} placement="top">
                <Icon
                  name={rarityName.toLowerCase()}
                  className={styles.RarityIcon}
                  data-testid="rarity-icon"
                />
              </Tooltip>
            ) : null}
            <Heading className={styles.Title} level="h5">
              {props.item.itemName}
            </Heading>
          </div>
          {bottomContent}
        </div>
      </div>
    </div>
  );
});

export default ViewOnlyVaultItem;
