const Approvals = (props) => (
  <svg
    viewBox="0 0 24 25"
    style={{ width: '2.8rem', height: '3rem', marginLeft: '-0.2rem' }}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.2871 16.1234C22.2841 13.8049 20.4854 11.8852 18.1721 11.7315V4.65159C18.172 4.55922 18.1353 4.47066 18.07 4.40536L15.2667 1.60211C15.2014 1.53678 15.1129 1.50005 15.0205 1.5H2.36397C2.27156 1.49995 2.18293 1.53663 2.11759 1.60197C2.05226 1.66731 2.01557 1.75594 2.01563 1.84834V22.1517C2.01557 22.2441 2.05226 22.3327 2.11759 22.398C2.18293 22.4634 2.27156 22.5001 2.36397 22.5H17.8243C17.9167 22.5001 18.0054 22.4634 18.0707 22.398C18.1361 22.3327 18.1727 22.2441 18.1727 22.1517V20.5153C20.4857 20.3611 22.2841 18.4415 22.2871 16.1234ZM15.3688 2.68934L16.9828 4.30325H15.3688V2.68934ZM2.71231 21.8033V2.19668H14.6722V4.65159C14.6721 4.74399 14.7088 4.83263 14.7741 4.89797C14.8395 4.9633 14.9281 4.99999 15.0205 4.99993H17.4754V11.7412C15.2122 11.9517 13.4815 13.8508 13.4815 16.1238C13.4815 18.3967 15.2122 20.2958 17.4754 20.5063V21.8037L2.71231 21.8033ZM14.1793 16.1228C14.1796 14.0764 15.8387 12.4177 17.8851 12.4178C19.9315 12.418 21.5904 14.0769 21.5904 16.1234C21.588 18.1692 19.9299 19.8269 17.8841 19.8289C15.8376 19.8285 14.179 18.1693 14.1793 16.1228Z"
        fill="#5A5665"
      />
      <path
        d="M12.4832 13.2787H5.20186C5.00947 13.2787 4.85352 13.4346 4.85352 13.627C4.85352 13.8194 5.00947 13.9754 5.20186 13.9754H12.4832C12.6756 13.9754 12.8315 13.8194 12.8315 13.627C12.8315 13.4346 12.6756 13.2787 12.4832 13.2787Z"
        fill="#5A5665"
      />
      <path
        d="M5.20186 16.0641H9.56428C9.75667 16.0641 9.91262 15.9081 9.91262 15.7157C9.91262 15.5233 9.75667 15.3674 9.56428 15.3674H5.20186C5.00947 15.3674 4.85352 15.5233 4.85352 15.7157C4.85352 15.9081 5.00947 16.0641 5.20186 16.0641Z"
        fill="#5A5665"
      />
      <path
        d="M12.4832 17.456H5.20186C5.00947 17.456 4.85352 17.612 4.85352 17.8043C4.85352 17.9967 5.00947 18.1527 5.20186 18.1527H12.4832C12.6756 18.1527 12.8315 17.9967 12.8315 17.8043C12.8315 17.612 12.6756 17.456 12.4832 17.456Z"
        fill="#5A5665"
      />
      <path
        d="M9.56428 19.5441H5.20186C5.00947 19.5441 4.85352 19.7001 4.85352 19.8924C4.85352 20.0848 5.00947 20.2408 5.20186 20.2408H9.56428C9.75667 20.2408 9.91262 20.0848 9.91262 19.8924C9.91262 19.7001 9.75667 19.5441 9.56428 19.5441Z"
        fill="#5A5665"
      />
      <path
        d="M5.20186 11.6516H15.0211C15.1135 11.6517 15.2021 11.615 15.2675 11.5497C15.3328 11.4843 15.3695 11.3957 15.3694 11.3033V6.33655C15.3695 6.24414 15.3328 6.15551 15.2675 6.09017C15.2021 6.02484 15.1135 5.98815 15.0211 5.98821H5.20186C5.10945 5.98815 5.02082 6.02484 4.95548 6.09017C4.89015 6.15551 4.85346 6.24414 4.85352 6.33655V11.3033C4.85346 11.3957 4.89015 11.4843 4.95548 11.5497C5.02082 11.615 5.10945 11.6517 5.20186 11.6516ZM5.5502 6.68529H14.6728V10.955H5.5502V6.68529Z"
        fill="#5A5665"
      />
      <path
        d="M19.7981 14.3014C19.6263 14.1879 19.3946 14.2344 19.2803 14.4052L17.3459 17.3023L16.2423 16.1415C16.1525 16.038 16.0128 15.992 15.8787 16.0216C15.7446 16.0513 15.6376 16.1518 15.6001 16.2834C15.5626 16.415 15.6007 16.5565 15.6993 16.6518L17.1256 18.1536C17.1961 18.228 17.2943 18.2702 17.397 18.2701C17.4077 18.2701 17.4183 18.2701 17.429 18.2686C17.5424 18.2591 17.6452 18.1985 17.7081 18.1041L19.9025 14.8175C19.9575 14.7353 19.9773 14.6347 19.9577 14.5379C19.9381 14.4411 19.8807 14.3561 19.7981 14.3014Z"
        fill="#F63A55"
      />
      <path
        d="M17.8991 12.4976C15.8791 12.4976 14.2415 14.1352 14.2415 16.1552C14.2415 18.1752 15.8791 19.8128 17.8991 19.8128C19.9191 19.8128 21.5567 18.1752 21.5567 16.1552C21.5567 14.1352 19.9191 12.4976 17.8991 12.4976ZM13.4453 16.1552C13.4453 13.6955 15.4393 11.7014 17.8991 11.7014C20.3589 11.7014 22.3529 13.6955 22.3529 16.1552C22.3529 18.615 20.3589 20.609 17.8991 20.609C15.4393 20.609 13.4453 18.615 13.4453 16.1552Z"
        fill="#F63A55"
      />
    </g>
  </svg>
);

export default Approvals;
