const Refer = (props) => (
  <svg viewBox="0 0 19 29" height="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14 14c0 2.761-2.015 5-4.5 5S5 16.761 5 14s2.015-5 4.5-5 4.5 2.239 4.5 5"
        fill="#FFF"
      />
      <path
        d="M14 11c0 2.761-2.015 5-4.5 5S5 13.761 5 11s2.015-5 4.5-5S14 8.239 14 11z"
        stroke="#5A5665"
      />
      <path d="M18 29H1c0-4.97 3.768-9 8.417-9h.166C14.232 20 18 24.03 18 29" fill="#FFF" />
      <path d="M18 27H1c0-4.97 3.768-9 8.417-9h.166C14.232 18 18 22.03 18 27z" stroke="#5A5665" />
      <path
        fill="#FFF"
        d="M13.616 4.616L14.5 3l.884 1.616L17 5.5l-1.616.884L14.5 8l-.884-1.616L12 5.5z"
      />
      <path stroke="#F63A55" d="M13.94 1.94L15 0l1.06 1.94L18 3l-1.94 1.06L15 6l-1.06-1.94L12 3z" />
    </g>
  </svg>
);

export default Refer;
