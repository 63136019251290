import Avatar from 'react-avatar';

import Text from 'components/Text';
import { DATE_FORMAT_MONTH_DAY_LABEL } from 'consts/constants';
import { formatDate } from 'utils/helpers';

import { Comment } from '../Feed.types';
import TeamIcon from '../TeamIcon';

import styles from './FeedComment.module.scss';

const FeedComment: React.FC<Comment> = ({ comment: { user, data, created_at } }) => {
  return (
    <div className={styles.CommentContainer}>
      <div className={styles.AvatarContainer}>
        <Avatar
          round
          src={data.publisherAvatar}
          name={user.data.name}
          maxInitials={2}
          size="32px"
        />
      </div>
      <div>
        <Text type="uiBold" className={styles.CommentName}>
          {user.data.name}
          <TeamIcon teamName={data.teamName} teamIconLocation={data.teamIconLocation} />
        </Text>
        <div className={styles.Date}>{formatDate(created_at, DATE_FORMAT_MONTH_DAY_LABEL)}</div>
      </div>

      <div className={styles.CommentTextContainer}>
        <Text type="uiRegular" className={styles.CommentText}>
          {data.text}
        </Text>
      </div>
    </div>
  );
};

export default FeedComment;
