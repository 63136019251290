const SvgComponent = (props) => (
  <svg viewBox="0 0 23 24" height="100%" {...props}>
    <g strokeWidth={1.002} fill="none" fillRule="evenodd">
      <path
        d="M1.02452 20.5084H12.663V0.501316L1.02452 0.513613V20.5084Z"
        stroke="#5A5665"
        strokeWidth="1.00157"
      />
      <path d="M5.78125 2.65107V4.65107H3.78125V2.65107H5.78125Z" fill="#5A5665" />
      <path d="M5.78125 7.22008V9.22008H3.78125V7.22008H5.78125Z" fill="#5A5665" />
      <path
        d="M5.78125 11.7891V13.7891H3.78125V11.7891H5.78125ZM5.27734 12.7871L5.27934 12.789H4.78125H4.77925V12.7871H5.27734ZM4.78125 12.789V13.2852H4.77925L4.28316 12.7891V12.7872L4.78125 12.789ZM4.78125 13.2871H4.77925L4.78125 13.2852V13.2871Z"
        fill="#5A5665"
      />
      <path d="M5.78125 16.3581V18.3581H3.78125V16.3581H5.78125Z" fill="#5A5665" />
      <path
        d="M8.33702 23.4992H19.9755V3.49213L8.33702 3.50443V23.4992Z"
        stroke="#F63A55"
        strokeWidth="1.00157"
      />
      <path
        d="M17.2187 12.2109V10.2109H15.2187V12.2109H17.2187ZM16.7148 11.2128L16.7168 11.2109H16.2187H16.2167V11.2128H16.7148ZM16.2187 11.2109V10.7148H16.2167L15.7207 11.2109V11.2128L16.2187 11.2109ZM16.2187 10.7129H16.2167L16.2187 10.7148V10.7129Z"
        fill="#F63A55"
      />
      <path
        d="M13.0938 14.7799V16.7799H11.0938V14.7799H13.0938ZM12.5898 15.778L12.5918 15.7799H12.0938H12.0917V15.778H12.5898ZM12.0938 15.7799V16.276H12.0917L11.5957 15.7799V15.778L12.0938 15.7799ZM12.0938 16.2779H12.0917L12.0938 16.276V16.2779Z"
        fill="#F63A55"
      />
      <path d="M17.2187 21.3489V19.3489H15.2187V21.3489H17.2187Z" fill="#5A5665" />
      <path d="M17.2187 16.7799V14.7799H15.2187V16.7799H17.2187Z" fill="#5A5665" />
      <path d="M17.2187 7.64188V5.64188H15.2187V7.64188H17.2187Z" fill="#5A5665" />
      <path d="M13.0938 5.64188V7.64188H11.0938V5.64188H13.0938Z" fill="#5A5665" />
      <path d="M13.0938 10.2109V12.2109H11.0938V10.2109H13.0938Z" fill="#5A5665" />
      <path d="M13.0938 19.3489V21.3489H11.0938V19.3489H13.0938Z" fill="#5A5665" />
    </g>
  </svg>
);

export default SvgComponent;
