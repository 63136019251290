import { useEffect } from 'react';

import { connect, HandleThunkActionCreator } from 'react-redux';

import { googleReadyActionCreator } from 'store/actionCreators';

interface GoogleAPIProps {
  googleReadyActionCreator: HandleThunkActionCreator<typeof googleReadyActionCreator>;
}

const GoogleAPI = ({ googleReadyActionCreator }: GoogleAPIProps) => {
  useEffect(() => {
    const gapiScript = document.createElement('script');
    gapiScript.id = 'google_api';
    gapiScript.type = 'text/javascript';
    gapiScript.src = 'https://apis.google.com/js/api.js';
    document.head.appendChild(gapiScript);

    gapiScript.onload = () => {
      gapi.load('client:auth2:signin2', () => {
        gapi.client
          .init({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'profile',
          })
          .then(() => googleReadyActionCreator());
      });
    };

    return () => {
      if (gapiScript.parentNode) {
        gapiScript.onload = null;
        gapiScript.parentNode.removeChild(gapiScript);
      }
    };
  }, [googleReadyActionCreator]);

  return null;
};

const mapDispatchToProps = {
  googleReadyActionCreator,
};

export default connect(null, mapDispatchToProps)(GoogleAPI);
