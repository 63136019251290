import * as Yup from 'yup';

import { CONTRACTING_OPTIONS, NATIONALITY_REGEX, PHONE_REGEX } from 'consts/constants';
import { ValidationMessages } from 'pages/Profile/ProfileMessages';
import { maxBirthday, minBirthday } from 'utils/helpers';

export const shippingAddressValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(35, 'Must be less than 35 characters')
    .required(ValidationMessages.requiredField),
  address1: Yup.string()
    .trim()
    .max(32, 'Must be less than 32 characters')
    .required(ValidationMessages.requiredField),
  address2: Yup.string().trim().max(32, 'Must be less than 32 characters').optional().nullable(),
  city: Yup.string()
    .trim()
    .max(32, 'Must be less than 32 characters')
    .required(ValidationMessages.requiredField),
  countryCode: Yup.string().required(ValidationMessages.requiredField),
  stateCode: Yup.string()
    .nullable()
    .when('countryCode', {
      is: (countryCode: string) => ['US', 'AU', 'JP', 'CA'].includes(countryCode),
      then: Yup.string().required(ValidationMessages.requiredField),
      otherwise: Yup.string().optional().nullable(),
    }),
  cpf: Yup.string()
    .nullable()
    .when('countryCode', {
      is: (countryCode: string) => countryCode === 'BR',
      then: Yup.string().nullable().required(ValidationMessages.requiredField),
      otherwise: Yup.string().nullable().optional().nullable(),
    }),
  zipCode: Yup.string()
    .trim()
    .max(32, 'Must be less than 32 characters')
    .required(ValidationMessages.requiredField),
  phone: Yup.string()
    .trim()
    .matches(PHONE_REGEX, 'Must be a valid phone number')
    .required('Phone number is required'),
  countryCallingCode: Yup.string().required('Country calling code is required.'),
});

export const profileValidationSchema = Yup.object({
  countryCallingCode: Yup.string().trim().required(ValidationMessages.requiredField),
  telephone: Yup.string()
    .trim()
    .matches(PHONE_REGEX, ValidationMessages.invalidField('phone number'))
    .required(ValidationMessages.requiredField),
  dateOfBirth: Yup.date()
    .min(minBirthday.toDate(), ValidationMessages.greaterThanField(minBirthday.format()))
    .max(maxBirthday.toDate(), ValidationMessages.lessThanField(maxBirthday.format()))
    .required(ValidationMessages.requiredField),
  nationality: Yup.string()
    .trim()
    .matches(NATIONALITY_REGEX, ValidationMessages.invalidField('nationality'))
    .required(ValidationMessages.requiredField),
  livingCountryCode: Yup.string()
    .trim()
    .matches(NATIONALITY_REGEX, ValidationMessages.invalidField('country'))
    .required(ValidationMessages.requiredField),
  livingCity: Yup.string().optional().nullable(),
  livingStreetAddress: Yup.string().optional().nullable(),
  _shirtSize: Yup.object({
    id: Yup.number(),
    name: Yup.string(),
  })
    .optional()
    .nullable(),
  _emergencyContact: Yup.object({
    name: Yup.string()
      .trim()
      .min(2, ValidationMessages.greaterThanField('2 characters'))
      .required(ValidationMessages.requiredField),
    countryCallingCode: Yup.string().trim().required(ValidationMessages.requiredField),
    telephone: Yup.string()
      .trim()
      .matches(PHONE_REGEX, ValidationMessages.invalidField('phone number'))
      .required(ValidationMessages.requiredField),
    email: Yup.string()
      .trim()
      .email(ValidationMessages.invalidField('email'))
      .required(ValidationMessages.requiredField),
  }),
  _shippingAddress: shippingAddressValidationSchema,
  _team: Yup.object({
    id: Yup.number(),
    name: Yup.string(),
  })
    .optional()
    .nullable(),
  slackReminders: Yup.boolean().optional().nullable(),
  australianBusinessNumber: Yup.string()
    .nullable()
    .when('hasAbn', {
      is: (hasAbn: boolean) => hasAbn,
      then: Yup.string()
        .nullable()
        .trim()
        .length(11, ValidationMessages.mustBeField('11 characters'))
        .required(ValidationMessages.requiredField),
      otherwise: Yup.string().nullable(),
    }),
  registeredForGST: Yup.boolean()
    .nullable()
    .when('hasAbn', {
      is: (hasAbn: boolean) => hasAbn,
      then: Yup.boolean().nullable().required(ValidationMessages.requiredField),
      otherwise: Yup.boolean().nullable(),
    }),
  contractingOption: Yup.string().required(ValidationMessages.requiredField),
  contractingOptionCompany: Yup.string().when('contractingOption', {
    is: (contractingOption: string) => contractingOption === CONTRACTING_OPTIONS.COMPANY,
    then: Yup.string().required(ValidationMessages.requiredField),
    otherwise: Yup.string(),
  }),
});

export const xvipProfileValidationSchema = Yup.object({
  ...profileValidationSchema.fields,
  _emergencyContact: Yup.object({
    name: Yup.string()
      .trim()
      .min(2, ValidationMessages.greaterThanField('2 characters'))
      .required(ValidationMessages.requiredField),
    countryCallingCode: Yup.string().trim().required(ValidationMessages.requiredField),
    telephone: Yup.string()
      .trim()
      .matches(PHONE_REGEX, ValidationMessages.invalidField('phone number'))
      .required(ValidationMessages.requiredField),
    email: Yup.string()
      .trim()
      .email(ValidationMessages.invalidField('email'))
      .required(ValidationMessages.requiredField),
  })
    .optional()
    .nullable(),
});

export const uploadedFileSchema = Yup.object({
  location: Yup.string().url().required(ValidationMessages.requiredField),
  key: Yup.string(),
  bucket: Yup.string(),
  name: Yup.string(),
  originalName: Yup.string(),
});
