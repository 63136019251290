function SvgMissions(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/25A5665/svg"
      {...props}
    >
      <path
        d="M466.724 107.928a6.993 6.993 0 00-5.2-4.88l-42.9-9.692-9.692-42.9a6.998 6.998 0 00-8.572-5.236 6.996 6.996 0 00-3.204 1.832L347.816 96.4a199.631 199.631 0 1067.784 67.784l49.36-49.36a7.004 7.004 0 001.764-6.896zM430.3 267.348a185.626 185.626 0 01-256.719 171.444 185.627 185.627 0 01-60.088-302.799A185.622 185.622 0 01337.6 106.632l-22.66 22.66a6.9 6.9 0 00-.54.7 154.252 154.252 0 00-194.765 46.775 154.256 154.256 0 10262.341 20.893c.257-.182.502-.381.732-.596l22.66-22.66a184.996 184.996 0 0124.932 92.944zm-64.936-70.856a139.855 139.855 0 01-35.213 181.392 139.853 139.853 0 11-14.655-231.308l7.372 32.656-7.072 7.072a108.108 108.108 0 00-179.434 82.988 108.113 108.113 0 10189.334-73.088l7.072-7.072 32.596 7.36zm-90.612 70.856a30.21 30.21 0 01-42.001 27.928 30.213 30.213 0 1127.621-53.548L239.7 262.4a6.994 6.994 0 00-.085 9.985 6.999 6.999 0 009.985-.089l20.672-20.672a29.869 29.869 0 014.48 15.724zm-4.304-35.696a44.28 44.28 0 109.896 9.9l35.432-35.432a94.042 94.042 0 01-7.313 130.294 94.047 94.047 0 01-130.476-2.457A94.044 94.044 0 01305.88 196.22l-35.432 35.432zm131.376-73.504l-26.272 26.268-3.94-.888-.152-.036-35.06-7.892-8.836-39.132 70.528-70.54 7.868 34.824a7.006 7.006 0 005.288 5.288l34.824 7.868-44.248 44.24z"
        fill="#5A5665"
      />
      <path
        d="M274.885 245.523a37.499 37.499 0 01-56.938 48.457 37.5 37.5 0 0148.614-56.804"
        stroke="#F63A55"
        strokeWidth={15}
      />
      <path
        d="M371.5 192c16.5 33 23.865 56.282 19.447 93.123-4.419 36.841-22.513 70.657-50.676 94.706-28.163 24.049-64.324 36.563-101.274 35.048-36.95-1.515-71.973-16.949-98.086-43.224-26.113-26.274-41.397-61.46-42.804-98.539-1.408-37.08 11.165-73.33 35.21-101.521 24.046-28.192 57.798-46.254 94.526-50.585C259.5 117.276 290 124 319 139.5"
        stroke="#F63A55"
        strokeWidth={16}
      />
      <path
        d="M273 229l14.5 5.5-21.829 21.78-11.948-8L273 229zM311.5 133l6.364-6.629L327.5 136.5l6.364 28.371L320 165l-8.5-32zM370.5 184l16.85 8.43-7.5 7.5-21-5L370.5 184z"
        fill="#5A5665"
      />
    </svg>
  );
}

export default SvgMissions;
