import { version as pkgVersion } from '../package.json';

const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_ENV,
  REACT_APP_BUILD_VERSION,
  REACT_APP_CLOUDINARY_URL,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
} = process.env;

export const apiUrl = REACT_APP_API_URL || 'http://xhq-api.localhost';

export const env = REACT_APP_ENV || NODE_ENV || 'development';

export const version = env + '-' + pkgVersion + '_BUILD_' + REACT_APP_BUILD_VERSION;

export const sentryVersion = version.replace(/__.*?$/, '');

export const cloudinaryImagePath = `${REACT_APP_CLOUDINARY_URL}/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_scale/`;

export const cloudinaryVideoPath = `${REACT_APP_CLOUDINARY_URL}/${REACT_APP_CLOUDINARY_CLOUD_NAME}/video/upload/`;
