import classNames from 'classnames';

import styles from './PageContents.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const PageContents: React.FC<Props> = (props) => {
  const { children, className } = props;
  const classes = classNames(styles.PageContents, className);

  return <div className={classes}>{children}</div>;
};

export default PageContents;
