import { Fragment, useEffect, useRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import { toLower } from 'lodash';

import styles from './Input.module.scss';

function cancelWheel() {
  if (document && document.activeElement) {
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (err) {
      console.warn(err);
    }
  }
}

function useFocusRef(targetKeys: string | undefined) {
  const activeElement = document.activeElement;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function keyHandler({ key }: KeyboardEvent) {
      if (!targetKeys) {
        return;
      }

      const focusableElements = ['input', 'textarea', 'select'];
      const escapeKeyPressed = key === 'Escape';
      const targetKeyPressed = targetKeys.includes(key);
      const activeElementFocused = focusableElements.includes(toLower(activeElement?.nodeName));

      if (targetKeyPressed && !activeElementFocused) {
        inputRef.current?.focus();
      }

      if (escapeKeyPressed) {
        inputRef.current?.blur();
      }
    }

    window.addEventListener('keyup', keyHandler);

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  }, [activeElement?.nodeName, targetKeys]);

  return inputRef;
}

type Props = Partial<{
  error: string;
  className: string;
  errorClassName: string;
  focusOn: string;
  isRate: boolean;
  small: boolean;
  isPayment: boolean;
  isReimb: boolean;
  maxLength: number;
  fullwidth: boolean;
  isModal: boolean;
  isNarrow: boolean;
  reimburseNumber: boolean;
  step: string;
  defaultValue: string;
  isSearch: boolean;
  title: string;
}> &
  InputHTMLAttributes<HTMLInputElement>;

function Input(props: Props) {
  const {
    className,
    errorClassName,
    isRate,
    isPayment,
    isReimb,
    fullwidth,
    small,
    isNarrow,
    isModal,
    error,
    reimburseNumber,
    isSearch,
    focusOn,
    ...restProps
  } = props;

  const Classes = classNames(
    styles.Input,
    isRate && styles.Input_rate,
    isPayment && styles.Input_payment,
    isReimb && styles.Input_reimb,
    fullwidth && styles.Input_fullwidth,
    small && styles.Input_small,
    isModal && styles.Input_modal,
    isNarrow && styles.Input_narrow,
    reimburseNumber && styles.Input_reimburseNumber,
    isSearch && styles.Input_search,
    className,
  );

  const ErrorClasses = classNames(errorClassName, styles.Input_error);

  const focusRef = useFocusRef(focusOn);
  const ref = focusOn ? focusRef : undefined;
  const isNumberType = restProps.type && restProps.type === 'number';

  let calculatedProps = isNumberType ? { onWheel: cancelWheel } : {};

  return (
    <Fragment>
      {error && (
        <div key="error" className={ErrorClasses}>
          {error}
        </div>
      )}
      <input
        key="input"
        className={Classes}
        {...restProps}
        {...calculatedProps}
        ref={ref}
        tabIndex={0}
      />
    </Fragment>
  );
}

export default Input;
