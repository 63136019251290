import { HEALTH_CHECK_INTERVAL } from 'consts/constants';
import { definitions } from 'types/apiTypes';
import { Assignment, Get } from 'types/types';
import { handleRequestError } from 'utils/helpers';

import { getApiQueryHook } from './xhqFetcher';

export const useGetHealthCheck = getApiQueryHook<void>(
  '/health-check',
  {},
  {
    refetchInterval: HEALTH_CHECK_INTERVAL,
    onError: handleRequestError,
  },
);

export const useGetCurrentUserBySession = getApiQueryHook<
  Get<definitions, ['getSessionsMeResponse']>
>('/sessions/me');

export const useGetMyUnlockableBounties = getApiQueryHook<
  Get<definitions, ['getMeUnlockableBountiesResponse']>
>('/me/unlockable-bounties');

export const useGetUserBounties = getApiQueryHook<Get<definitions, ['getBountiesResponse']>>(
  '/bounties',
);

export const useGetUserFeedToken = getApiQueryHook<Get<definitions, ['getFeedTokenResponse']>>(
  '/feed/token',
);

export const useGetMyHomeVaultItems = getApiQueryHook<
  Get<definitions, ['getMeHomeVaultItemsResponse']>
>('/me/home-vault-items');

export const useGetVaultItems = getApiQueryHook<Get<definitions, ['getVaultItemsResponse']>>(
  '/vault-items',
  { baseQuery: { isActive: true, inStock: true, stockZero: true } },
);

export const useGetAllVaultItemsCollection = getApiQueryHook<
  Get<definitions, ['getVaultCollectionsResponse']>
>('/vault/collections');

export const useGetUserUnlockItems = getApiQueryHook<
  Get<definitions, ['getUsersUserIdUnlockedVaultItemsResponse']>,
  { userId: number }
>('/users/:userId/unlocked-vault-items');

export const useGetEvents = getApiQueryHook<Get<definitions, ['getEventsResponse']>>('/events');

export const useGetUserUnleashInvoiceItems = getApiQueryHook<
  Get<definitions, ['getUsersUserIdInvoicesUnleashResponse']>,
  { userId: number }
>('/users/:userId/invoices/unleash');

export const useGetUserUnleashBudget = getApiQueryHook<
  Get<definitions, ['getUsersUserIdUnleashBudgetResponse']>,
  { userId: number }
>('/users/:userId/unleash-budget');

export const useGetMyUnleashBudget = getApiQueryHook<
  Get<definitions, ['getMeUnleashBudgetResponse']>
>('/me/unleash-budget');

export const useGetUsers = getApiQueryHook<Get<definitions, ['getUsersResponse']>>('/users');

export const useGetReferrals = getApiQueryHook<Get<definitions, ['getReferralsResponse']>>(
  '/referrals',
);

export const useGetUsersForAppreciation = getApiQueryHook<Get<definitions, ['getUsersResponse']>>(
  '/users',
  { baseQuery: { isForAppreciation: true } },
);

export const useGetUsersForCommunity = getApiQueryHook<Get<definitions, ['getUsersResponse']>>(
  '/users',
  { baseQuery: { isCommunityPage: true } },
);

export const useGetUsersProjectmanagers = getApiQueryHook<
  Get<definitions, ['getUsersProjectmanagersResponse']>
>('/users/projectmanagers');

export const useGetAnswersInvoicesResponse = getApiQueryHook<
  Get<definitions, ['getAnswersInvoicesResponse']>
>('/answers-invoices');

const CSVFileSymbol = Symbol('CSVFile');
type CSVFile = { [CSVFileSymbol]: typeof CSVFileSymbol };

export const useGetGenerateDocumentAnswers = getApiQueryHook<CSVFile>('/generate-document/answers');

export const useGetSettings = getApiQueryHook<Get<definitions, ['getSettingsResponse']>>(
  '/settings',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetCurrencies = getApiQueryHook<Get<definitions, ['getCurrenciesResponse']>>(
  '/currencies',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetProjectTypes = getApiQueryHook<Get<definitions, ['getProjectTypes']>>(
  '/projects/types',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetUnleashCategories = getApiQueryHook<
  Get<definitions, ['getUnleashCategoriesResponse']>
>('/unleash-categories');

export const useGetTeamDocument = getApiQueryHook<CSVFile, { teamId: number }>(
  '/teams/:teamId/document',
);

export const useGetTeamsDocument = getApiQueryHook<CSVFile>('/generate-document/teams');

export const useGetMissions = getApiQueryHook<Get<definitions, ['getMissionsResponse']>>(
  '/missions',
  { baseQuery: { isActive: true } },
);

export const useGetMissionsSimplified = getApiQueryHook<
  Get<definitions, ['getMissionsSimplifiedResponse']>
>('/missions/simplified', { baseQuery: { activeOnly: false } });

export const useGetObjectives = getApiQueryHook<Get<definitions, ['getObjectivesResponse']>>(
  '/objectives',
);

export const useGetObjectiveDocument = getApiQueryHook<CSVFile, { objectiveId: number }>(
  '/objectives/:objectiveId/document',
);

export const useGetObjectivesRewardTypes = getApiQueryHook<
  Get<definitions, ['getObjectivesRewardTypes']>
>('/objectives/rewards/types');

export const useGetTeamsSimplified = getApiQueryHook<
  Get<definitions, ['getTeamsSimplifiedResponse']>
>('/teams/simplified');

export const useGetTeamsAndGroups = getApiQueryHook<
  Get<definitions, ['getTeamsAndGroupsResponse']>
>('/teamsandgroups');

export const useGetFavoriteObjectives = getApiQueryHook<
  Get<definitions, ['getUsersUserIdObjectivesFavoritesResponse']>,
  { userId: number }
>('/users/:userId/objectives/favorites');

export const useGetEmploymentTypes = getApiQueryHook<
  Get<definitions, ['getEmploymentTypesResponse']>
>('/employment-types', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetPaymentTypes = getApiQueryHook<Get<definitions, ['getPaymentTypesResponse']>>(
  '/payment-types',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetPrintfulProducts = getApiQueryHook<
  Get<definitions, ['getPrintfulProductsResponse']>
>('/printful/products', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetPrintfulCountries = getApiQueryHook<
  Get<definitions, ['getPrintfulCountriesResponse']>
>('/printful/countries', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetChartsActiveUsers = getApiQueryHook<
  Get<definitions, ['getChartsActiveUsersResponse']>
>('/charts/active-users');

export const useGetChartsReferralsSubmitted = getApiQueryHook<
  Get<definitions, ['getChartsReferralsSubmittedResponse']>
>('/charts/referrals-submitted');

export const useGetChartsTopReferrers = getApiQueryHook<
  Get<definitions, ['getChartsTopReferrersResponse']>
>('/charts/top-referrers');

export const useGetChartsMostPopularVaultItems = getApiQueryHook<
  Get<definitions, ['getChartsMostPopularVaultItemsResponse']>
>('/charts/most-popular-vault-items');

export const useGetChartsVaultItemsPurchased = getApiQueryHook<
  Get<definitions, ['getChartsVaultItemsPurchasedResponse']>
>('/charts/vault-items-purchased');

export const useGetChartsBountiesCompleted = getApiQueryHook<
  Get<definitions, ['getChartsBountiesCompletedResponse']>
>('/charts/bounties-completed');

export const useGetChartsPopularBounties = getApiQueryHook<
  Get<definitions, ['getChartsPopularBountiesResponse']>
>('/charts/popular-bounties');

export const useGetChartsTopUnleashCategories = getApiQueryHook<
  Get<definitions, ['getChartsTopUnleashCategoriesResponse']>
>('/charts/top-unleash-categories');

export const useGetChartsUnleashSpent = getApiQueryHook<
  Get<definitions, ['getChartsUnleashSpentResponse']>
>('/charts/unleash-spent');

export const useGetAllUserGroups = getApiQueryHook<Get<definitions, ['getUserGroupsResponse']>>(
  '/user-groups',
);

export const useGetAllUserGroupsDropdown = getApiQueryHook<
  Get<definitions, ['getUserGroupsResponse']>
>('/user-groups', { baseQuery: { isDropdown: true } });

export const useGetUserGroupDetails = getApiQueryHook<
  Get<definitions, ['getUserGroupsUserGroupIdResponse']>,
  { groupId: number }
>('/user-groups/:groupId');

export const useGetUserGroupMemberList = getApiQueryHook<
  Get<definitions, ['getUserGroupsUserGroupIdMembersResponse']>,
  { groupId: number }
>('/user-groups/:groupId/members');

export const useGetUserGroupChatMemberList = getApiQueryHook<
  Get<definitions, ['getUserGroupsUserGroupIdChatMembersResponse']>,
  { groupId: number }
>('/user-groups/:groupId/chat/members');

export const useGetInvoices = getApiQueryHook<Get<definitions, ['getInvoicesResponse']>>(
  '/invoices',
  {},
  { refetchInterval: HEALTH_CHECK_INTERVAL },
);

export const useGetHoursInvoiceCategory = getApiQueryHook<
  Get<definitions, ['getInvoiceCategoriesHoursResponse']>
>('/invoice-categories/hours', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetJobOpenings = getApiQueryHook<Get<definitions, ['getJobOpeningsResponse']>>(
  '/job-openings',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
  },
);

export const useGetUserRoles = getApiQueryHook<Get<definitions, ['getUserRolesResponse']>>(
  '/user-roles',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetReferralInvoiceCategory = getApiQueryHook<
  Get<definitions, ['getInvoiceCategoriesReferralResponse']>
>('/invoice-categories/referral', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetUserAssignments = getApiQueryHook<{ data: Assignment[] }, { userId: number }>(
  '/users/:userId/assignments',
);

export const useGetProjectsSimplified = getApiQueryHook<
  Get<definitions, ['getProjectsSimplifiedResponse']>
>('/projects-simplified');

export const useGetInvoiceCategoriesHours = getApiQueryHook<
  Get<definitions, ['getInvoiceCategoriesHoursResponse']>
>('/invoice-categories/hours', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetInvoiceCategoriesUnleashPlus = getApiQueryHook<
  Get<definitions, ['getInvoiceCategoriesUnleashPlusResponse']>
>('/invoice-categories/unleash-plus', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetUserBountyList = getApiQueryHook<
  Get<definitions, ['getUsersUserIdBountyListResponse']>,
  { userId: number | undefined }
>('/users/:userId/bounty-list');

export const useGetUserGroups = getApiQueryHook<
  Get<definitions, ['getUserUserGroupsUserIdResponse']>,
  { userId: number }
>('/user/user-groups/:userId');

export const useGetLegends = getApiQueryHook<Get<definitions, ['getLegendsResponse']>>('/legends');

export const useGetAirwallexTokenStatus = getApiQueryHook<void>('/invoices/airwallex-token', {});
