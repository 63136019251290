import { AnchorHTMLAttributes, ButtonHTMLAttributes, memo } from 'react';

import cs from 'classnames';

import { Icon } from '../Icon';

import styles from './button.module.scss';

interface CommonProps {
  variant: 'primary' | 'secondary' | 'tertiary';
  layout: 'autolayout' | 'fluid';
  iconName?: string;
  iconPosition?: 'start' | 'end';
}
interface AnchorOnlyProps extends CommonProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  as: 'a';
}
interface ButtonOnlyProps extends CommonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
}

export type ButtonProps = AnchorOnlyProps | ButtonOnlyProps;

export const Button = memo<ButtonProps>((props) => {
  const {
    as,
    children,
    variant,
    layout,
    className,
    iconName,
    iconPosition = 'start',
    ...p
  } = props;

  const classnames = cs(styles.button, className, {
    [styles['layout--autolayout']]: layout === 'autolayout',
    [styles['layout--fluid']]: layout === 'fluid',
    [styles['variant--primary']]: variant === 'primary',
    [styles['variant--secondary']]: variant === 'secondary',
    [styles['variant--tertiary']]: variant === 'tertiary',
    [styles['icon-position--start']]: children && iconPosition === 'start',
    [styles['icon-position--end']]: children && iconPosition === 'end',
  });

  const inner = (
    <>
      {iconName && <Icon className={styles.icon} name={iconName} />}
      <p>{children}</p>
    </>
  );

  if (props.as === 'a') {
    return (
      <a {...(p as AnchorOnlyProps)} className={classnames}>
        <>{inner}</>
      </a>
    );
  }
  if (props.as === 'button') {
    return (
      <button {...(p as ButtonOnlyProps)} className={classnames}>
        <>{inner}</>
      </button>
    );
  }
  return null;
});
