import { ComponentPropsWithoutRef, memo, ReactNode, forwardRef, ButtonHTMLAttributes } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Loader from 'components/Loader';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<any> {
  url?: string;
  onClick?: any;
  onKeyUp?: any;
  children?: ReactNode;
  isBig?: boolean;
  isSmall?: boolean;
  isReverse?: boolean;
  isGray?: boolean;
  isFullWidth?: boolean;
  isBlock?: boolean;
  isInline?: boolean;
  isFormBtn?: boolean;
  isRef?: boolean;
  isModal?: boolean;
  isClose?: boolean;
  isWhite?: boolean;
  isBare?: boolean;
  isSpaced?: boolean;
  isMobile?: boolean;
  className?: string;
  isBankingConfirm?: boolean;
  isGreen?: boolean;
  disabled?: boolean;
  submitNext?: boolean;
  addReimburseStep?: boolean;
  isLoading?: boolean;
  isIconOnly?: boolean;
  noOutline?: boolean;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  id?: string;
  tabIndex?: number;
}

type RenderUrlProps = {
  url: string;
  ButtonClasses?: string;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: any;
};

interface CloseBtnProps {
  ButtonClasses?: string;
  disabled?: boolean;
  onClick?: any;
  isWhite?: boolean;
  children?: ReactNode;
  propsRest?: ComponentPropsWithoutRef<'button'>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      url,
      onClick,
      children,
      isBig,
      isSmall,
      isReverse,
      isFormBtn,
      isGray,
      isBlock,
      isInline,
      isFullWidth,
      isRef,
      isModal,
      isClose,
      isWhite,
      isSpaced,
      isBankingConfirm,
      isMobile,
      disabled,
      className,
      submitNext,
      addReimburseStep,
      isLoading,
      isIconOnly,
      isBare,
      noOutline,
      isGreen,
      ...propsRest
    },
    ref,
  ) => {
    const ButtonClasses = classNames(
      styles.Button,
      isBig && styles.Button_big,
      isSmall && styles.Button_small,
      isReverse && styles.Button_reverse,
      isGray && styles.Button_gray,
      isFormBtn && styles.Button_formbtn,
      isBlock && styles.Button_block,
      isInline && styles.Button_inline,
      isFullWidth && styles.Button_FullWidth,
      isRef && styles.Button_ref,
      isModal && styles.Button_modal,
      isClose && styles.Button_close,
      isSpaced && styles.Button_spaced,
      isBankingConfirm && styles.Button_bankingConfirm,
      submitNext && styles.Button_submitNext,
      addReimburseStep && styles.Button_addReimburseStep,
      isLoading && styles.Button_loading,
      isMobile && styles.Button_mobile,
      isIconOnly && styles.Button_icon,
      isBare && styles.Button_bare,
      noOutline && styles.Button_noOutline,
      isGreen && styles.Button_green,
      className,
    );

    const renderCloseButton = ({
      ButtonClasses,
      disabled,
      onClick,
      isWhite,
      propsRest,
    }: CloseBtnProps) => {
      return (
        <button
          onClick={onClick}
          className={ButtonClasses}
          disabled={disabled}
          {...propsRest}
          ref={ref}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: `
            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Close</title>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                <g id="X-Team---Invoice-App:-Developer-Referrals-Popup" transform="translate(-1031.000000, -289.000000)" stroke=${
                  isWhite ? '#ffffff' : '#B8B7BC'
                } stroke-width="2">
                  <g id="Popup" transform="translate(515.000000, 253.000000)">
                    <g id="Close-button" transform="translate(524.849242, 44.849242) rotate(45.000000) translate(-524.849242, -44.849242) translate(514.349242, 34.349242)">
                        <path d="M10.5,-7.95807864e-13 L10.5,21" id="Line-2"></path>
                        <path d="M21,10.5 L0,10.5" id="Line-2"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          `,
            }}
          />
        </button>
      );
    };

    const renderUrl = ({ url, ButtonClasses, disabled, children }: RenderUrlProps) => (
      <Link to={disabled ? '#' : url} className={ButtonClasses}>
        {children}
      </Link>
    );

    return isClose ? (
      renderCloseButton({
        ButtonClasses,
        disabled,
        onClick,
        isWhite,
        children,
        ...propsRest,
      })
    ) : url ? (
      renderUrl({ url, ButtonClasses, disabled, onClick, children })
    ) : (
      <button
        onClick={onClick}
        className={ButtonClasses}
        disabled={disabled || isLoading}
        ref={ref}
        {...propsRest}
      >
        {isLoading && <Loader />}
        {!isLoading && children}
      </button>
    );
  },
);

export default memo(Button);
