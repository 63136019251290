import { memo } from 'react';

import Lottie from 'react-lottie';

import loaderBlack from 'assets/loader-black.json';
import loaderWhite from 'assets/loader-white.json';
import { useCurrentUser } from 'utils/hooks';
import { isNullOrUndefined } from 'utils/object';

import styles from './Loading.module.scss';

const isDarkModeSelected = (loggedUser: any) => {
  return loggedUser && !isNullOrUndefined(loggedUser?.darkMode) && loggedUser?.darkMode;
};

const getLoaderByTheme = (loggedUser: any) => {
  const optionsForLoaderAnimationForTheme = {
    autoplay: true,
    animationData: loaderBlack,
    rendererSettings: {
      progressiveLoad: true,
    },
  };

  if (isDarkModeSelected(loggedUser)) {
    return {
      ...optionsForLoaderAnimationForTheme,
      animationData: loaderWhite,
    };
  }

  return optionsForLoaderAnimationForTheme;
};

type LoadingContainerProps = {
  children: React.ReactElement;
  testId: string;
  isInlineLoader: boolean;
};

const LoadingContainer = ({ children, testId, isInlineLoader }: LoadingContainerProps) => {
  return (
    <div
      className={isInlineLoader ? styles.InlineLoadingContainer : styles.LoadingContainer}
      data-testid={testId}
    >
      <div
        className={
          isInlineLoader ? styles.LottieInlineLoadingAnimation : styles.LottieLoadingAnimation
        }
      >
        {children}
      </div>
    </div>
  );
};

export const Loading = memo(() => {
  const loggedUser = useCurrentUser();
  const loaderOptions = {
    ...getLoaderByTheme(loggedUser),
  };
  return (
    <LoadingContainer testId="loading" isInlineLoader={false}>
      <Lottie options={loaderOptions} height={100} width={100} />
    </LoadingContainer>
  );
});

export const InlineLoading = memo(() => {
  const loggedUser = useCurrentUser();
  const loaderOptions = {
    ...getLoaderByTheme(loggedUser),
  };
  return (
    <LoadingContainer testId="inline-loading" isInlineLoader={true}>
      <Lottie options={loaderOptions} height={100} width={100} />
    </LoadingContainer>
  );
});
