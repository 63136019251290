import ScrollMenu from 'react-horizontal-scrolling-menu';

import dashboardModulesDefault from 'assets/grand-epic/grand-epic-default.png';
import CardModule from 'components/CardModule';
import { useAdminSetting } from 'utils/hooks';

import styles from './HomeCards.module.scss';

const HomeCards = () => {
  const moduleSettings = useAdminSetting('dashboard-modules');
  const moduleOneImage = moduleSettings?.values.image?.location || dashboardModulesDefault;
  const moduleOneLink = moduleSettings?.values.cta_url || '';
  const moduleTwoLink = moduleSettings?.values.cta_url_module || '';
  const moduleTwoImage = moduleSettings?.values.image_module?.location || dashboardModulesDefault;

  const cards = [
    <CardModule
      title={moduleSettings?.values.title ?? ''}
      text={moduleSettings?.values.text ?? ''}
      bottomLink={moduleOneLink}
      image={moduleOneImage}
      imagePosition={'right'}
      key={'CardModule-1'}
    />,
    <CardModule
      title={moduleSettings?.values.title_module ?? ''}
      text={moduleSettings?.values.text_module ?? ''}
      bottomLink={moduleTwoLink}
      image={moduleTwoImage}
      imagePosition={'right'}
      key={'CardModule-2'}
    />,
  ];

  return (
    <ScrollMenu
      data={cards}
      alignCenter={false}
      itemClass={styles.Card}
      innerWrapperClass={styles.InnerWrapper}
      scrollToSelected={true}
      useButtonRole={false}
      wheel={false}
      wrapperClass={styles.HomeWrapper}
    />
  );
};

export default HomeCards;
