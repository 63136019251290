import { memo, MouseEventHandler, SyntheticEvent } from 'react';

import cx from 'classnames';

import { Image } from '../Image';
import { Paragraph } from '../Paragraph';

import styles from './Avatar.module.scss';

type AvatarHandler = (
  e: SyntheticEvent<HTMLDivElement, Event>,
) => MouseEventHandler<HTMLDivElement> | undefined | void;

export interface AvatarProps {
  id?: string;
  accessibleName?: string;
  imgSrc?: string;
  name?: string;
  interactive?: boolean;
  className?: string;
  onClick?: AvatarHandler;
}

const getNameInitials = (name: string) =>
  name
    .match(/(^\S\S?|\b\S)?/g)!
    .join('')
    .match(/(^\S|\S$)?/g)!
    .join('')
    .toUpperCase();

export const Avatar = memo<AvatarProps>(
  ({ id, accessibleName, imgSrc, name, interactive, className, onClick }) => {
    const extraProps = interactive && {
      role: 'button',
      onClick: onClick,
    };

    const avatarClasses = cx(styles.Avatar, { [styles.AvatarClickable]: interactive }, className);

    return (
      <div className={avatarClasses} {...extraProps} id={id}>
        {name && !imgSrc && (
          <Paragraph
            aria-label={accessibleName ?? 'Avatar'}
            className={styles.AvatarInitials}
            variant="bold"
          >
            {getNameInitials(name)}
          </Paragraph>
        )}

        {imgSrc && !name && (
          <Image
            overlayer
            variant="circle"
            className={styles.AvatarImageSource}
            src={imgSrc}
            alt={accessibleName ?? 'Avatar'}
          />
        )}
      </div>
    );
  },
);
