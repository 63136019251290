import { memo } from 'react';

import classNames from 'classnames';
import Linkify from 'react-linkify';

import bountyFallback from 'assets/bounties/bounty1.svg';
import { ReactComponent as CircleChecked } from 'assets/circle_checked.svg';
import coinAnimation from 'assets/coin.gif';
import { ReactComponent as EyeSlash } from 'assets/eye-slash.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as AddToListIcon } from 'assets/folder_plus.svg';
import { ReactComponent as Heart } from 'assets/heart.svg';
import { ReactComponent as OutlineHeart } from 'assets/heart_outline.svg';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import FluidButton from 'components/buttons/FluidButton';
import { BOUNTY_TYPE } from 'consts/constants';
import { BountyTypes } from 'pages/Bounties/Bounties.types';

import { IMG_LOADING } from '../../../types/types';
import UnlockedBy from '../UnlockedBy';

import styles from './BountyCard.module.scss';

export type BountyCardProps = {
  bounty: BountyTypes;
  testId?: string;
  showActions?: boolean;
  isFavorite?: boolean;
  className?: string;
  showFooter?: boolean;
  toggleHidden?: (bounty: BountyTypes) => void;
  toggleFavorite?: (bounty: BountyTypes, isFavorite: boolean) => void;
  handlePrivateBounty?: (bounty: BountyTypes) => void;
  toggleAddBountyToList?: (bounty: BountyTypes) => void;
  shouldLazyLoad?: boolean;
};

const BountyCard = (props: BountyCardProps) => {
  const {
    bounty,
    className,
    testId,
    showActions = true,
    showFooter = true,
    toggleHidden,
    toggleFavorite,
    toggleAddBountyToList = () => {},
    handlePrivateBounty = () => {},
    shouldLazyLoad = false,
  } = props;
  const {
    title,
    description,
    coverImage,
    coinValue,
    isFavorite = false,
    isDone,
    isHidden,
    _unlockedBounties: unlockedBy,
  } = bounty;
  const isPrivateBounty = bounty._bountyType?.id === BOUNTY_TYPE.PRIVATE;
  const userCreated = bounty._bountyType?.id === BOUNTY_TYPE.USER_CREATED;
  const addedBy = bounty._addedBy?.displayName;
  const image = (coverImage && coverImage.location) || bountyFallback;
  const slackChannel = bounty._slackChannels ? bounty._slackChannels[0]?.name : undefined;

  const eyeClasses = classNames(styles.Eye, {
    [styles.Eye_hidden]: isHidden,
    [styles.noHoverHiddenIcon]: !toggleHidden,
  });

  const heartClasses = classNames({
    [styles.noHoverFavoriteIcon]: !toggleFavorite,
  });

  const bountyClasses = classNames(styles.BountyCardContainer, className, {
    [styles.BountyCardContainer_NoFooter]: !showFooter,
  });

  const bountyContentClasses = classNames(styles.BountyCardContent, {
    [styles.BountyCardContent_Favorite]: isFavorite,
    [styles.BountyCardContent_NoFooter]: !showFooter,
  });

  const imageClasses = classNames(styles.Image, {
    [styles.Image_Done]: isDone,
  });

  const bountyDescription =
    description.length <= 210 ? description : `${description.slice(0, 207)}...`;

  return (
    <div className={bountyClasses} data-testid={testId}>
      <div className={styles.ImageContainer}>
        <img
          className={imageClasses}
          src={image}
          alt={title}
          data-testid="BountyImage"
          width={168}
          height={126}
          loading={shouldLazyLoad ? IMG_LOADING.LAZY : undefined}
        />
      </div>
      <div className={bountyContentClasses}>
        <Text type="uiBold" className={styles.Coins}>
          <span className={styles.CoinAnimation}>
            <img src={coinAnimation} alt="coin animation" />
          </span>
          X{coinValue}
        </Text>
        {isPrivateBounty && (
          <div className={styles.BountyCardType}>
            <Text type="uiBold" className={styles.BountyCardTypeText}>
              Private
            </Text>
          </div>
        )}

        {showActions && (
          <Button
            data-testid="hiddenButton"
            isIconOnly
            noOutline
            className={eyeClasses}
            title={isHidden ? 'Click to Show' : 'Click to Hide'}
            onClick={() => toggleHidden && toggleHidden(bounty)}
          >
            {isHidden ? <EyeSlash /> : <Eye />}
          </Button>
        )}
        <div className={styles.BountyTitleDescriptionContainer}>
          <Text type="bold" className={styles.Title}>
            {title}
          </Text>

          {(userCreated || isPrivateBounty) && (
            <Text type="uiRegular" className={styles.Tag}>
              Made by <span className={styles.MadeByUser}>{addedBy}</span>
            </Text>
          )}
          <Text type="uiRegular" className={styles.Text}>
            <Linkify
              componentDecorator={(href: string, text: string, key: number) => (
                <a href={href} key={key} target="_blank" rel="noreferrer">
                  {text}
                </a>
              )}
            >
              {bountyDescription}
            </Linkify>
          </Text>
          {!isPrivateBounty && slackChannel && (
            <Text type="uiRegular" className={styles.Tag}>
              Post it in: <span className={styles.RedTag}>{`#${slackChannel}`}</span>
            </Text>
          )}
          {!isPrivateBounty && !slackChannel && (
            <Text type="uiRegular" className={styles.Tag}>
              Post it in: <span className={styles.RedTag}>All channels</span>
            </Text>
          )}
        </div>

        {showFooter && (
          <div className={styles.BountyFoot}>
            {showActions && (
              <div className={styles.BountyFootButtonsContainer}>
                <FluidButton
                  level="tertiary"
                  onClick={() => toggleAddBountyToList(bounty)}
                  className={styles.AddToListButton}
                >
                  <div className={styles.IconButton}>
                    <div>Add to list</div>
                    <AddToListIcon />
                  </div>
                </FluidButton>
                <FluidButton
                  data-testid="favoriteButton"
                  level="tertiary"
                  active={isFavorite}
                  onClick={() => toggleFavorite && toggleFavorite(bounty, isFavorite)}
                >
                  <div className={styles.IconButton}>
                    <div>Favorite</div>
                    {isFavorite ? <Heart /> : <OutlineHeart />}
                  </div>
                </FluidButton>
              </div>
            )}
            <div>
              {isPrivateBounty && showActions && (
                <FluidButton
                  data-testid={isDone ? 'undoCompleteButton' : 'completeButton'}
                  level="secondary"
                  className={heartClasses}
                  onClick={() => handlePrivateBounty(bounty)}
                >
                  <div className={styles.IconButton}>
                    <div>
                      <span>{isDone ? 'Undo this bounty' : 'Mark as completed'}</span>
                    </div>
                    <div>
                      <CircleChecked />
                    </div>
                  </div>
                </FluidButton>
              )}
              {!isPrivateBounty && unlockedBy && (
                <UnlockedBy unlockedByList={unlockedBy} shouldLazyLoad={shouldLazyLoad} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BountyCard);
