import { useEffect, useRef } from 'react';

import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { DropResult } from 'react-beautiful-dnd';

import {
  DATE_FORMAT_MONTH_LABEL,
  EMAIL_REGEX,
  DISPLAY_DATE_FORMAT,
  CURRENCIES_SYMBOLS,
  PHONE_REGEX,
} from 'consts/constants';
import { addToast } from 'store/actionCreators';
import currentStore from 'store/currentStore';
import { DragAndDrop, InvoiceStatus, Settings, VaultItemModel } from 'types/types';

export const isWeekday = (date: moment.Moment) =>
  date.isoWeekday() !== 6 && date.isoWeekday() !== 7;

export const formatDate = (
  date?: moment.Moment | Date | string | number,
  format = DISPLAY_DATE_FORMAT,
) => moment.utc(date).format(format);

export const formatCurrency = (
  number?: number,
  currency: string = 'usd',
  returnString: boolean = false,
  showSymbol: boolean = false,
) => {
  if (!number || isNaN(number)) {
    // @ts-ignore
    number = '0';
  }
  const configuredParts = [
    {
      addSpace: false,
      value: showSymbol ? CURRENCIES_SYMBOLS[currency.toUpperCase()] : '',
    },
    {
      addSpace: true,
      value: Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number as number),
    },
    {
      addSpace: false,
      value: currency.toUpperCase(),
    },
  ];

  const spacedString = configuredParts.reduce((str, { addSpace, value }) => {
    str += value;
    if (addSpace) str += ' ';
    return str;
  }, '');

  if (returnString) return spacedString;
  return <span dangerouslySetInnerHTML={{ __html: spacedString }} />;
};

export const formatCurrencyWithPrefix = (
  number?: string | number,
  currency: string = 'usd',
  returnString: boolean = false,
) => {
  const string =
    currency.toUpperCase() +
    ' ' +
    currencyFormatter.format(number as number, {
      code: currency.toUpperCase(),
      symbol: '',
    });

  if (returnString) return string;
  return <span dangerouslySetInnerHTML={{ __html: string }} />;
};

export const mkInt = (value: string) => Math.abs(parseInt(value.replace(/\D/g, ''), 10));

export const getSettingByName = (settings?: Settings[] | null, name?: string): any => {
  if (!settings) {
    return null;
  }
  const setting = settings.find((item) => item.id === name);

  if (setting && setting.values) {
    return setting.values;
  }

  return null;
};

// Set whole function as any types since anything can come from a component's state
export const setStateAsync = (self: any, stateReducer: any, callback: Function) => {
  return new Promise((resolve, reject) => {
    let originalState: any;
    self.setState(
      (state: any) => {
        originalState = state;
        return stateReducer(state);
      },
      () => {
        callback()
          .then(resolve)
          .catch((err: any) => {
            self.setState(originalState);
            return reject(err);
          });
      },
    );
  });
};

export const countDays = (
  startDate?: moment.Moment | Date | string,
  endDate?: moment.Moment | Date | string,
) => {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  return endMoment.diff(startMoment, 'days') + 1;
};

export const countBusinessDays = (
  startDate?: moment.Moment | Date | string,
  endDate?: moment.Moment | Date | string,
) => {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  let businessDays = 0;

  while (startMoment.isSameOrBefore(endMoment)) {
    const day = startMoment.day();
    if (day >= 1 && day <= 5) {
      businessDays++;
    }
    startMoment.add(1, 'day');
  }

  return businessDays;
};

interface ItemWithOrder {
  order?: number;
}

export function reorderItems<T extends ItemWithOrder>(
  { sourceIndex, targetIndex }: { sourceIndex: number; targetIndex: number },
  allItems: T[],
): T[] {
  const rearrangedItems = allItems.map((a) => Object.assign({}, a));
  const [removed] = rearrangedItems.splice(sourceIndex, 1);
  rearrangedItems.splice(targetIndex, 0, removed);
  return rearrangedItems;
}

export function updateItemsOnReorder<T extends ItemWithOrder>(
  { sourceIndex, targetIndex }: { sourceIndex: number; targetIndex: number },
  allItems: T[],
  updateItem: Function,
): T[] {
  const rearrangedItems = reorderItems({ sourceIndex, targetIndex }, allItems);

  for (let i = 0; i < rearrangedItems.length; ++i) {
    if (rearrangedItems[i].order !== allItems[i].order) {
      rearrangedItems[i].order = allItems[i].order;
      updateItem(rearrangedItems[i], false);
    }
  }

  return rearrangedItems;
}

export function useInterval(callback?: Function, delay?: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallback.current?.(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getErrorMessage = (err: any) =>
  String(err.payload?.message || err.payload || err.message || err || '');

export const isLooselyValidEmail = (email: string) => {
  // Only way to properly validate is by testing the email address which should be done server side
  // https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html#input.email.attrs.value.single
  return EMAIL_REGEX.test(email);
};

export const minBirthday = moment.utc(moment().subtract(100, 'years').format('YYYY-MM-DD'));
export const maxBirthday = moment.utc(moment().subtract(16, 'years').format('YYYY-MM-DD'));

export const isBirthdayValid = (dateString?: moment.Moment | Date | string) => {
  const date = moment.utc(dateString);
  return date.isValid() && date.isBetween(minBirthday, maxBirthday);
};

export const roundMoney = (money: number) => (money ? Math.round(money * 100) / 100 : money);

export const handleRequestError = (error: any) => {
  const message = getErrorMessage(error);

  console.error({ type: error.type, message });
};

export const isValidUrl = (stringUrl: string) => {
  try {
    new URL(stringUrl);
    return true;
  } catch (_) {
    return false;
  }
};

export const isExternal = (path: string) =>
  path.startsWith('http://') || path.startsWith('https://');

export const normalizeUrl = (string: string) => {
  const hasProtocol = isExternal(string);

  return hasProtocol ? string : `https://${string}`;
};

export const getStatus = (statusName: string, invoiceStatuses: InvoiceStatus[]): InvoiceStatus =>
  invoiceStatuses.find((statusObj) => statusObj.name === statusName) as InvoiceStatus;

export const getPreviousMonths = (date?: moment.Moment | Date | string | number, months = 12) => {
  const momentDate = moment.utc(date);
  const dates: { label: string; value: number }[] = [];

  if (!momentDate.isValid()) {
    return dates;
  }

  const initialMonth = momentDate.clone().startOf('month');
  const initialLabel = initialMonth.format(DATE_FORMAT_MONTH_LABEL);

  dates.push({ label: initialLabel, value: initialMonth.valueOf() });

  for (let i = 1; i < months; i++) {
    const value = momentDate.subtract(1, 'month').startOf('month').clone();
    const label = value.format(DATE_FORMAT_MONTH_LABEL);

    dates.push({ label, value: value.valueOf() });
  }

  return dates;
};

export const generateImageFormDataFromStaticURI = async (uri: string) => {
  const response = await fetch(uri);
  const imgBlob = await response.blob();
  const fileName = uri.split('/').pop() ?? 'image.svg';
  const file = new File([imgBlob], fileName, { type: 'image/svg+xml' });
  const formData = new FormData();
  formData.append('files', file);

  return formData;
};

export const parseNumbers = (text: string) => text.replace(/\D/g, '');

export const validatePhoneNumber = (phoneNumber: string) =>
  PHONE_REGEX.test(parseNumbers(phoneNumber));

// Feed does not accept @, ' and . in user id.
// filter more based on the RFC 5321 https://tools.ietf.org/html/rfc5321
export const getFeedUserId = (email = '') => email.replace(/[@."'`+!?^=/#$%& ]/gi, '');

export const isAirwallexEnabled = () =>
  String(process.env.REACT_APP_ENABLE_AIRWALLEX).toLowerCase() === 'true';

export const getCurrentCursorPosition = (inputId: string) => {
  const input = document.getElementById(inputId);

  // @ts-ignore
  return input?.selectionStart;
};

export const concatEmojiWithText = ({
  emoji,
  text,
  position,
}: {
  emoji: string;
  text: string;
  position: number;
}) => {
  if (!position) {
    return text + emoji;
  }

  const arrayOfLetters = text.split('');

  arrayOfLetters.splice(position, 0, emoji);

  return arrayOfLetters.join('');
};

export const dragAndDropHandler = (
  dropResult: DragAndDrop | DropResult,
  items: any[],
  setState: any,
  actionCreator: Function,
) => {
  const sourceIndex = dropResult?.source?.index;
  const targetIndex = dropResult?.destination?.index as number;

  const { dispatch } = currentStore();

  const itemsToReorder: { id: any; order: any }[] = [];

  const rearrangedItems = updateItemsOnReorder(
    { sourceIndex, targetIndex },
    items,
    (item: { id: any; order: any }) =>
      itemsToReorder.push({
        id: item.id,
        order: item.order,
      }),
  );

  setState(rearrangedItems);

  return actionCreator({ body: itemsToReorder })
    .then(() => {
      dispatch(addToast({ text: 'Successfully updated', type: 'success' }));
    })
    .catch((err: { payload: { message: string } }) => {
      const text = err?.payload?.message || 'Failed to update';
      dispatch(addToast({ text, type: 'error' }));
    });
};

/**
 * Pad any value on start
 *
 * @param {any} value
 * @param {string} padString
 * @return {string} value with padding on start
 */
export const padStartValue = (value: number, padLength = 5, padString = '0') => {
  return String(value).padStart(padLength, padString);
};

/**
 * Get Item id or return default value (-1)
 *
 * @param {object} item
 * @return {string} item.id or -1
 */
export function getItemId(item: { id?: number }): string {
  return (item.id || -1).toString();
}

/**
 * Returns true whether stock label for Vault Item should be displayed
 *
 * @param {VaultItemModel} item
 * @return {boolean}
 */
export function shouldDisplayStockLabel(item: VaultItemModel): boolean {
  return item.stock === 0 && item._whenStockIsZero.id > 1;
}
