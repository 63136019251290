import { memo, createRef } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Toast from '../Toast';

type ToastsProps = {
  toasts?: any;
  removeToast: (id: string) => void;
  classes: {
    toastsList: string;
    toastsListItem: string;
  };
};

type ToastProps = JSX.IntrinsicAttributes & {
  id: string;
  text: string;
  type?: 'error' | 'info' | 'warning' | 'success';
  autoClose?: number;
  toastWrapperClass?: string;
};

const Toasts = (props: ToastsProps) => {
  const { toasts, removeToast, classes } = props;

  return (
    <div className={classes.toastsList}>
      <TransitionGroup component={null}>
        {toasts.map((toast: ToastProps) => {
          const { id } = toast;
          // we need to create refs on the fly, rather than useRef hook with array/object
          // as CSSTransition references nodeRef.current directly
          // so, e.g. we can't just pass nodeRef={topLevelRef.current[id]} — the animations won't work
          const itemRef = createRef<HTMLDivElement>();
          return (
            <CSSTransition nodeRef={itemRef} key={id} timeout={500} classNames="toast-fade">
              <Toast
                key={id}
                ref={itemRef}
                {...toast}
                toastWrapperClass={classes.toastsListItem}
                onDismissClick={() => removeToast(id)}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default memo(Toasts);
