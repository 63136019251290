import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { removeToast } from 'store/actionCreators';

import Toasts from '../Toasts';

import styles from './PageToasts.module.scss';

export const PageToasts = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts.pageToasts, shallowEqual);

  const handleRemoveToast = (id: string) => {
    dispatch(removeToast(id));
  };

  const classes = {
    toastsList: styles.Toasts,
    toastsListItem: styles.Toast,
  };

  const toastsProps = {
    toasts,
    removeToast: handleRemoveToast,
  };

  return <Toasts {...toastsProps} classes={classes} removeToast={handleRemoveToast} />;
};

export default PageToasts;
