import { memo, lazy, Suspense, useEffect } from 'react';

import { useClearCache } from 'react-clear-cache';
import { Switch } from 'react-router-dom';

import AppPage from 'components/AppPage';
import { isXVipUser } from 'components/Auth/authHelpers';
import { PrivateRoute } from 'components/Auth/authRedirect';
import GoogleAPI from 'components/GoogleAPI';
import { GoogleAnalyticsRouteTracker } from 'components/GoogleAnalytics';
import { Loading } from 'components/Loading';
import NotActivePage from 'components/NotActivePage';
import XvipPage from 'components/XvipPage';
import { AcceptInvitationLogin } from 'components/login/AcceptInvitationLogin';
import LoginPage from 'components/login/LoginPage';
import ValidateEmailTokenLink from 'components/login/ValidateEmailTokenLink';
import Menu from 'components/menu/Menu';
import PageToasts from 'components/toasts/PageToasts';
import { USER_ROLE } from 'consts/constants';
import DocumentSigning from 'pages/DocumentSigning/DocumentSigning';
import TimezoneUpdater from 'utils/TimezoneUpdater';
import { useCurrentSession } from 'utils/hooks';

const AdminPage = lazy(() => import(/* webpackChunkName: "AdminPage" */ './components/AdminPage'));
const {
  ADMIN,
  COMMUNITY_TEAM,
  MANAGER,
  CORE_MANAGER,
  PEOPLE_OPS,
  SUPER_ADMIN,
  RECRUITER,
} = USER_ROLE;
const allowedToSeeAdmin = [
  ADMIN,
  COMMUNITY_TEAM,
  MANAGER,
  CORE_MANAGER,
  PEOPLE_OPS,
  SUPER_ADMIN,
  RECRUITER,
];

const Main = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const session = useCurrentSession();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [emptyCacheStorage, isLatestVersion]);

  const determinePage = () =>
    isXVipUser(session?.data) ? (
      <PrivateRoute path="/" component={XvipPage} userIsAuthenticated userIsActive />
    ) : (
      <PrivateRoute path="/" component={AppPage} userIsAuthenticated userIsActive />
    );

  return (
    <Menu>
      <GoogleAnalyticsRouteTracker />
      <GoogleAPI />
      <TimezoneUpdater />
      <PageToasts />
      <Suspense fallback={<Loading />}>
        <Switch>
          <PrivateRoute
            path="/inactive"
            component={NotActivePage}
            userIsAuthenticated
            userIsDeactivated
          />
          <PrivateRoute
            path="/admin"
            component={AdminPage}
            userIsAuthenticated
            userIsActive
            allowedRoles={allowedToSeeAdmin}
          />
          <PrivateRoute
            path="/login/email-token/:token"
            component={ValidateEmailTokenLink}
            userIsNotAuthenticated
          />
          <PrivateRoute
            path="/login/accept/:token"
            component={AcceptInvitationLogin}
            userIsNotAuthenticated
          />
          <PrivateRoute path="/login" component={LoginPage} userIsNotAuthenticated />
          <PrivateRoute
            path="/document-signing"
            component={DocumentSigning}
            userHasDocumentsToSign
          />
          {determinePage()}
        </Switch>
      </Suspense>
    </Menu>
  );
};

export default memo(Main);
