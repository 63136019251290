import { useState, useRef, ChangeEvent, useEffect } from 'react';

import c from 'classnames';
// @ts-ignore
import { EmojiPicker } from 'react-activity-feed';
import Avatar from 'react-avatar';
import TextareaAutosize from 'react-textarea-autosize';

import { isAdmin, isCommunityTeam, isPeopleOps, isSuperAdmin } from 'components/Auth/authHelpers';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import ModalBackButton from 'components/modals/ModalBackButton';
import { cloudinaryImagePath, cloudinaryVideoPath } from 'config';
import { useVaultStash } from 'contexts/VaultStashContext';
import {
  useGenerateCloudinaryAuthSignature,
  useFeedPost,
  useFeedPinnedPostUpdateMutation,
} from 'utils/apiMutationHooks';
import { concatEmojiWithText, getCurrentCursorPosition, getErrorMessage } from 'utils/helpers';
import { useCurrentUser, useToast, useIsMobile, useAllowedRoles } from 'utils/hooks';

import {
  POST_TYPES,
  POST_OPERATION_TYPES,
  UploaderOptions,
  MediaProgress,
  SelectedActivity,
} from '../Feed.types';
import type { Activity, Emoji } from '../Feed.types';
import MediaDropPanel from '../MediaDropPanel';
import MediaSelector from '../MediaSelector';
import * as mediaUtils from '../MediaSelector/utils';
import { ReactComponent as PinIcon } from '../Pin.svg';
import { uploadPostHelper } from '../PostUploadHelper';

import styles from './NewPost.module.scss';

import 'react-activity-feed/dist/index.css';

const NewPost = (props: NewPostProps) => {
  const {
    selectedActivity,
    onResetForm,
    onConfirmUpdate,
    triggerFeedRefresh,
    isInlineForm,
  } = props;
  const [post, setPost] = useState<PostModel>({ title: '', text: '' });
  const [canPost, setCanPost] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [progress, setProgress] = useState<MediaProgress[]>([]);
  const [isPosting, setIsPosting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const user = useCurrentUser();
  const toast = useToast();
  const isMobile = useIsMobile();
  const { getVaultStash } = useVaultStash();

  const panelRef = useRef<HTMLDivElement>(null);

  const canPinPosts = useAllowedRoles([isAdmin, isSuperAdmin, isCommunityTeam, isPeopleOps]);

  const [generateCloudinaryAuthSignature] = useGenerateCloudinaryAuthSignature();
  const [submitFeedPost] = useFeedPost({
    useErrorBoundary: false,
    onSuccess: () => {
      toast({ text: 'Successfully added a post!', type: 'success' });
    },
    onError: () => {
      toast({ text: 'Failed to add post!', type: 'error' });
    },
  });
  const [updatePinnedFeedPost] = useFeedPinnedPostUpdateMutation({
    useErrorBoundary: false,
    onSuccess: () => {
      toast({ text: 'The post was edited successfully!', type: 'success' });
    },
    onError: () => {
      toast({ text: 'The post failed to update!', type: 'error' });
    },
  });

  const resetForm = () => {
    if (isPosting) return;
    setProgress([]);
    setMediaFiles([]);
    setShowSendOptions(false);
    setIsPinned(false);
    setPost({ title: '', text: '' });
    onResetForm();
  };

  const onMediaProgress = (progress: MediaProgress[] = []) => {
    setProgress(progress);
  };

  const confirmSubmit = async () => {
    onConfirmUpdate(getPostData());
  };

  const onSubmit = async (data?: UploaderOptions) => {
    setErrorMessage('');
    setIsPosting(true);

    const feedData = data || getPostData();
    // Because useErrorBoundary is used we need to check
    // if response exists rather than use try/catchUploaderOptions
    const res = await uploadPostHelper(feedData);

    if (!res) {
      return;
    }

    await getVaultStash();
    setIsPosting(false);
    resetForm();
    setShowSendOptions(false);
    triggerFeedRefresh(true);
  };

  const getPostData = () => {
    return {
      post,
      isPinned,
      mediaFiles,
      onMediaProgress,
      generateCloudinaryAuthSignature,
      submitFeedPost,
      updatePinnedFeedPost,
      mutationOptions: {
        onError(error: unknown) {
          const message = getErrorMessage(error) || 'There was an issue with creating a post.';
          setErrorMessage(message);
          setIsPosting(false);
        },
      },
      activity: selectedActivity?.activity,
    };
  };

  const handleFileInputChange = (files?: File[], shouldConcatenateFiles: boolean = true) => {
    if (!files) {
      return;
    }

    if (files.some((file) => !file.type.match('(image|video).*'))) {
      return toast({ text: 'File type not supported' });
    }

    const overLimit = !mediaUtils.validateFilesSize(files);
    if (overLimit) {
      return toast({ text: 'Files cannot exceed 100MB.' });
    }

    if (shouldConcatenateFiles) {
      setMediaFiles((currentFiles) => [...currentFiles, ...files]);
    } else {
      setMediaFiles(files);
    }
  };

  const handleInputChange = <T extends keyof PostModel>(field: T) => (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPost({ ...post, [field]: e.target.value });
  };

  const handleEmojiSelection = async ({ native }: Emoji) => {
    const currentCursorPosition = getCurrentCursorPosition('text-area-input');

    !cursorPosition && (await setCursorPosition(currentCursorPosition));

    setPost((currentPost) => {
      return {
        ...currentPost,
        text: concatEmojiWithText({
          emoji: native,
          text: currentPost.text,
          position: cursorPosition || currentCursorPosition,
        }),
      };
    });

    await setCursorPosition((cursorPosition || currentCursorPosition) + 2);
  };

  const retrieveMedia = (activity: Activity) => {
    const mediaCount = (activity.mediaImages?.length || 0) + (activity.mediaVideos?.length || 0);

    setMediaFiles(Array(mediaCount).fill(null));

    const videosOffset = activity.mediaImages?.length || 0;

    async function retrieveFile(name: string, url: string, index: number) {
      return await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], name, { type: blob.type });
          setMediaFiles((currentFiles) => {
            currentFiles[index] = file;
            return currentFiles;
          });
        });
    }

    activity.mediaImages?.forEach((mediaImage, i) => {
      const imageUrl = `${cloudinaryImagePath}${mediaImage}`;
      retrieveFile(mediaImage, imageUrl, i);
    });

    activity.mediaVideos?.forEach((mediaVideo, i) => {
      const videoUrl = `${cloudinaryVideoPath}${mediaVideo}`;
      retrieveFile(mediaVideo, videoUrl, videosOffset + i);
    });
  };

  useEffect(() => {
    if (!selectedActivity || selectedActivity.operation === POST_OPERATION_TYPES.DELETE) {
      return;
    }

    const activity = selectedActivity?.activity;
    if (!activity) {
      return;
    }

    // When editing inline
    if (activity.type === POST_TYPES.USER_POST && !isInlineForm) {
      return; // Hide the main form (the form below the pinned post)
    }

    switch (selectedActivity.operation) {
      case POST_OPERATION_TYPES.EDIT:
        if (activity.mediaImages || activity?.mediaVideos) {
          retrieveMedia(activity);
        }
        break;
      case POST_OPERATION_TYPES.EDIT_CONFIRM:
        handleFileInputChange(selectedActivity?.editedData?.mediaFiles, false);
        break;
      case POST_OPERATION_TYPES.UPDATE:
        handleFileInputChange(selectedActivity?.editedData?.mediaFiles, false);
        onSubmit(selectedActivity?.editedData);
        break;
      default:
    }

    let title = activity.title;
    let text = activity.message;
    if (selectedActivity.editedData) {
      title = selectedActivity.editedData?.post.title;
      text = selectedActivity.editedData?.post.text;
    }

    setPost({
      title: title || '',
      text: text || '',
    });
    setIsPinned(activity.type === POST_TYPES.PINNED_POST);
    setShowSendOptions(true);
    // eslint-disable-next-line
  }, [selectedActivity, isInlineForm]);

  useEffect(() => {
    setCanPost(post.text.replace(/&nbsp;/g, ' ').trim().length > 0 || mediaFiles?.length > 0);

    if (isPinned) setCanPost(isPinned && post.title.trim().length > 0);
  }, [post, isPinned, mediaFiles]);

  if (!user) {
    return null;
  }

  const placeholder = !isPinned ? 'Share what’s on your mind!' : 'Share your pinned message!';

  const postPublisher = {
    avatar: selectedActivity?.activity?.publisherAvatar ?? user?.avatarUrl,
    name: selectedActivity?.activity?.publisherName ?? user?.displayName,
  };

  return (
    <MediaDropPanel
      mediaFiles={mediaFiles}
      onFileInputChange={handleFileInputChange}
      isDisabled={isPosting}
    >
      <div
        ref={panelRef}
        className={c(styles.NewPostContainer, { [styles.ShowMobile]: showSendOptions && isMobile })}
      >
        <ModalBackButton isVisible={showSendOptions && isMobile} isSticky closeModal={resetForm} />
        <div className={styles.MainFeedControls}>
          <Avatar
            round
            className={styles.PostAvatar}
            src={postPublisher.avatar}
            name={postPublisher.name}
            maxInitials={2}
            size="32px"
          />
          {isPinned && (
            <Input
              className={styles.PinnedPostTitleInput}
              placeholder="Enter pinned post title"
              name="title"
              value={post.title}
              onChange={handleInputChange('title')}
              disabled={isPosting}
            />
          )}

          <TextareaAutosize
            id="text-area-input"
            key={placeholder}
            value={post.text}
            placeholder={placeholder}
            className={styles.Textarea}
            onChange={handleInputChange('text')}
            onFocus={() => {
              setShowSendOptions(true);
              setCursorPosition(0);
            }}
            disabled={isPosting}
            name="text"
          />
        </div>

        {showSendOptions && (
          <>
            <MediaSelector
              multiple
              mediaFiles={mediaFiles}
              progress={progress}
              setMediaFiles={setMediaFiles}
              isDisabled={isPosting}
              onFileInputChange={handleFileInputChange}
            />

            <div className={styles.MainFeedActions}>
              <div
                className={c({
                  [styles.ActionsBlock]: !isMobile,
                  [styles.ActionsBlockMobile]: isMobile,
                })}
              >
                {canPinPosts && !selectedActivity && (
                  <Button
                    className={c(styles.PostActionButton, { [styles.Selected]: isPinned })}
                    onClick={() => setIsPinned(!isPinned)}
                    disabled={isPosting}
                    isLoading={isPosting}
                  >
                    <PinIcon />
                  </Button>
                )}
                <div
                  className={c(styles.PostActionButton)}
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyUp={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EmojiPicker onSelect={handleEmojiSelection} />
                </div>
              </div>
              <div className={styles.ButtonsBlock}>
                <Button className={styles.ButtonSecondary} onClick={resetForm}>
                  Cancel
                </Button>
                <Button
                  onClick={() => (selectedActivity ? confirmSubmit() : onSubmit(undefined))}
                  disabled={!canPost || isPosting}
                  isLoading={isPosting}
                >
                  {selectedActivity ? 'Update' : 'Send'}
                </Button>
              </div>
            </div>
          </>
        )}

        {errorMessage && <div className={styles.ErrorContainer}>{errorMessage}</div>}
      </div>
    </MediaDropPanel>
  );
};

export default NewPost;

type PostModel = {
  title: string;
  text: string;
};

interface NewPostProps {
  selectedActivity?: SelectedActivity;
  onResetForm: () => void;
  onConfirmUpdate: (data: UploaderOptions) => void;
  triggerFeedRefresh: (shouldRefresh: boolean) => void;
  isInlineForm?: boolean;
}
