import { memo } from 'react';

import { Link } from 'react-router-dom';

import BountyCard from 'components/bounties/BountyCard';
import { xvipFilter } from 'helpers/xvipHelpers';
import { useGetMyUnlockableBounties, useGetSettings } from 'utils/apiQueryHooks';
import { getSettingByName } from 'utils/helpers';
import { useCurrentUser } from 'utils/hooks';

import styles from './HomeBounties.module.scss';

const HomeBounties = memo(() => {
  const { resolvedData: unlockableBountiesForMe } = useGetMyUnlockableBounties();
  const { resolvedData: settings } = useGetSettings();
  const currentUser = useCurrentUser();

  if (!unlockableBountiesForMe?.data.length) {
    return null;
  }
  const seasonSettings = getSettingByName(settings?.data, 'season');
  const activeSeason = seasonSettings?.number || 1;

  return (
    <section className={styles.Bounties}>
      <h3 className={styles.sectionTitle}>Choose your own adventure</h3>
      <Link to={`/bounties?season=${activeSeason}`} className={styles.titleLink}>
        All Bounties
      </Link>
      <div className={styles.bountiesGrid}>
        {unlockableBountiesForMe.data
          .filter((b) => xvipFilter(currentUser?._role?.name, b.isXvip))
          .map((bounty: any) => (
            <BountyCard
              className={styles.BountyItem}
              bounty={bounty}
              showFooter={false}
              showActions={false}
            />
          ))}
      </div>
    </section>
  );
});

export default HomeBounties;
