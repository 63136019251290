const Vacation = (props) => (
  <svg viewBox="0 0 32 26" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(0 -2)" fill="none" fillRule="evenodd">
      <g>
        <path stroke="#5A5665" d="M.5 4.5h29v23H.5z" />
        <path
          stroke="#5A5665"
          strokeWidth={2}
          fill="#F8F8F9"
          d="M5 3h2v1H5zm9 0h2v1h-2zm9 0h2v1h-2z"
        />
        <path stroke="#5A5665" d="M.5 9.5h28v1H.5z" />
        <path stroke="#5A5665" d="M4.5 7.5h2v1h-2zm8 0h4v1h-4zm10 0h2v1h-2z" />
        <path stroke="#5A5665" d="M6.5 13.5h4v4h-4zm0 6h4v4h-4z" />
        <path stroke="#F63A55" d="M20.5 13.5h4v4h-4z" />
        <path stroke="#5A5665" d="M20.5 19.5h4v4h-4zm-7-6h4v4h-4zm0 6h4v4h-4z" />
      </g>
    </g>
  </svg>
);

export default Vacation;
