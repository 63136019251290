import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './PageTitle.module.scss';

type Props = {
  isSmall?: boolean;
  isRed?: boolean;
  isMobile?: boolean;
  className?: string;
  'data-testid'?: string;
};

const PageTitle = (props: PropsWithChildren<Props>) => {
  const { children, isSmall, isRed, isMobile, className } = props;

  const titleClasses = classNames(
    styles.Title,
    isSmall && styles.Title_small,
    isRed && styles.Title_red,
    isMobile && styles.Title_mobile,
    className,
  );

  return (
    <h1 data-testid={props['data-testid']} className={titleClasses}>
      {children}
    </h1>
  );
};

export default PageTitle;
