import { useMemo } from 'react';

import moment from 'moment';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';

import { NotificationProps } from '../Notifications/Notifications.types';

import styles from './Notification.module.scss';

const verbsTranslations = {
  comment: 'commented your post',
  like: 'reacted on your post',
  post_user_commented: 'commented a post that you commented',
};

export function Notification(props: NotificationProps) {
  const history = useHistory();

  const { onMarkAsRead, activityGroup } = props;
  const { is_read: isRead, activities } = activityGroup;
  const { id, reaction } = activities[0];
  const { actor, actorAvatar, time, verb, object } = reaction.data;

  const redirectURL = useMemo(() => {
    return `/?post=${object}`;
  }, [object]);

  function handleMarkAsRead() {
    onMarkAsRead(activityGroup);
  }

  function handleClick() {
    handleMarkAsRead();
    history.push(redirectURL);
  }

  return (
    <div className={styles.listItem} key={id}>
      <button onClick={handleClick} className={styles.itemButton}>
        <div>
          <Avatar round src={actorAvatar} name={actor} maxInitials={2} size="32px" />
        </div>

        <div className={styles.itemInformation}>
          <span>
            <strong>{actor}</strong> {verbsTranslations[verb]}
          </span>
          <span data-testid="time" className={!isRead ? styles.activeDate : ''}>
            {moment.utc(time).fromNow()}
          </span>
        </div>
      </button>

      {!isRead && (
        <button
          data-testid="red-dot-button"
          className={styles.redDotContainer}
          onClick={handleMarkAsRead}
        >
          <div className={styles.redDot} />
        </button>
      )}
    </div>
  );
}
