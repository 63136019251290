import 'normalize.css';
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { memo } from 'react';

import { ReactQueryConfigProvider, ReactQueryConfig } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import HealthCheck from 'components/HealthCheck';
import ScrollToTop from 'components/ScrollToTop';
import { VaultStashProvider } from 'contexts/VaultStashContext';
import ThemeProvider from 'providers/ThemeProvider';

import Main from './Main';

const queryConfig: ReactQueryConfig = {
  shared: {
    suspense: true,
  },
  queries: {
    refetchOnWindowFocus: false,
  },
};

const App = () => (
  <ThemeProvider>
    <Router>
      <ReactQueryConfigProvider config={queryConfig}>
        <VaultStashProvider>
          <ScrollToTop />
          <Main />
          <HealthCheck />
          <ReactQueryDevtools />
        </VaultStashProvider>
      </ReactQueryConfigProvider>
    </Router>
  </ThemeProvider>
);

export default memo(App);
