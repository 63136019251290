import { ReactNode } from 'react';

import { MutateFunction, MutationConfig } from 'react-query';

import { UserApiModel, BountyApiModel, ObjectivesApiModel } from 'types/types';

export interface StreamUser {
  created_at: string;
  updated_at: string;
  id: string;
  data: {
    name: string;
    slackId: string;
    avatarUrl: string;
  };
}

export interface ActivityActor extends StreamUser {}

export interface Reaction {
  activity_id: string;
  children_counts: {};
  created_at: string;
  data: {};
  id: string;
  kind: string;
  parent: string;
  updated_at: string;
  user: StreamUser;
  user_id: string;
}

export interface ReactionCounts {
  like?: number;
  comment?: number;
}

export interface MediaMeta {
  bytes: number;
  format: string;
  height: number;
  width: number;
}

export interface Activity {
  actor: ActivityActor;
  foreign_id: string;
  id: string;
  publisherAvatar: string;
  publisherName: string;
  bountyId?: number;
  bountyTitle?: string;
  objectiveId?: number;
  objectiveTitle?: number;
  bountyCoinValue?: number | null;
  teamName?: string | null;
  teamIconLocation?: string | null;
  mediaImages?: string[];
  mediaPreset: string;
  mediaVideos?: string[];
  mediaImagesMeta?: MediaMeta[];
  mediaVideosMeta?: MediaMeta[];
  object: string;
  origin?: string | null;
  own_reactions: {
    like?: Reaction[];
    comment?: Reaction[];
  };
  reaction_counts?: ReactionCounts;
  target: string;
  time: string;
  verb: string;
  type: POST_TYPES;
  message?: string;
  title?: string;
  participants?: string[];
}

export type EnrichOptions = {
  enrich?: boolean;
  recentReactionsLimit?: number;
  withOwnChildren?: boolean;
  withOwnReactions?: boolean;
  withReactionCounts?: boolean;
  withRecentReactions?: boolean;
};

export type FeedPaginationOptions = {
  id_gt?: string;
  id_gte?: string;
  id_lt?: string;
  id_lte?: string;
  limit?: number;
};

export type RankedFeedOptions = {
  offset?: number;
  ranking?: string;
  session?: string;
};

type NotificationBroadcastingOptions = {
  targetFeeds?: string[];
};

export type GetFeedOptions = FeedPaginationOptions &
  EnrichOptions &
  RankedFeedOptions &
  NotificationBroadcastingOptions;

export type AddReactionCallbackFunction = (
  kind: string,
  activity: Activity,
  data?: {},
  options?: GetFeedOptions,
) => void | Promise<unknown>;

export type SelectedActivity = {
  editedData?: UploaderOptions;
  activity?: Activity;
  operation: POST_OPERATION_TYPES;
};

export interface FeedWrapperProps {
  user: Partial<UserApiModel>;
  selectedActivity?: SelectedActivity;
  shouldRefresh: boolean;
  toggleFeedRefresh: (shouldRefresh: boolean) => void;
  resetActivity: () => void;
  handlePostUpdate: (data: UploaderOptions) => void;
  editPost: (activity: Activity) => void;
  handlePostRemove: (activity: Activity) => void;
  onClickPost: (activity: Activity, refreshFeed?: RefreshFeedCallbackFunction) => void;
  limit?: number;
  showMyFeed?: boolean;
}

export interface FeedProps extends FeedWrapperProps {}

export interface FeedActivityProps {
  activity: Activity;
  feedGroup: string;
  onAddChildReaction: Function;
  onAddReaction: AddReactionCallbackFunction;
  onRemoveActivity: Function;
  onRemoveChildReaction: Function;
  onRemoveReaction: Function;
  onToggleChildReaction: Function;
  onToggleReaction: Function;
  getActivityPath: Function;
}

export type FeedCtx = {
  refresh?: RefreshFeedCallbackFunction;
};

export type RefreshFeedCallbackFunction = (options?: GetFeedOptions) => Promise<unknown>;

export interface LoadMorePaginatorProps {
  /** The button the user should click to click to load more */
  LoadMoreButton: ReactNode;
  /** callback to load the next page */
  loadNextPage: () => any;
  /** indicates if there is a next page to load */
  hasNextPage: boolean;
  /** indicates if there there's currently any refreshing taking place */
  refreshing: boolean;
  /** display the items in opposite order */
  reverse: boolean;
  /** The paginated content to display */
  children: ReactNode;
}

export interface LoadMoreButtonProps {
  onClick: () => any;
  refreshing: boolean;
  children: ReactNode;
}

export enum POST_TYPES {
  USER_POST = 'user_post',
  PINNED_POST = 'pinned_post',
}

export enum POST_OPERATION_TYPES {
  DELETE = 'delete',
  EDIT = 'edit',
  EDIT_CONFIRM = 'edit_confirm',
  UPDATE = 'update',
}

// taken from https://github.com/GetStream/react-activity-feed/blob/87d62d38f0080ed5a71714e3788f726e2627d1f2/src/types.js#L166-L178
export type Emoji = {
  // The actual unicode emoji (e.g. 👍)
  native: string;
  // Colon representation (e.g. ":+1:")
  colons: string;
  // Colon representation (e.g. "+1")
  id: string;
  // Colon representation (e.g. Thumbs Up Sign)
  name: string;
  emoticons: string[];
  skin?: number;
  unified: string;
};

export type BountySelectedOption = {
  value: number;
  bounty: boolean;
  label: string;
  option: BountyApiModel & ObjectivesApiModel;
};

export interface NotifierProps {
  shouldRefresh: boolean;
  adds: Activity[];
  onClick: () => void;
  triggerFeedRefresh: (shouldRefresh: boolean) => void;
}

export interface UploaderOptions {
  post: { title: string; text: string };
  bountyOrObjective?: Partial<BountyApiModel & ObjectivesApiModel & { bounty: boolean }> | null;
  isPinned: boolean;
  mediaFiles?: File[];
  onMediaProgress?: (progress: MediaProgress[]) => void;
  generateCloudinaryAuthSignature?: any;
  submitFeedPost: MutateFunction<any, any, any>;
  updatePinnedFeedPost: MutateFunction<any, any, any>;
  mutationOptions: MutationConfig<any>;
  activity?: Activity;
}

export interface MediaProgress {
  file: File;
  total: number;
  loaded: number;
  progress: number;
}

export interface Comment {
  comment: {
    created_at: string;
    data: {
      publisherAvatar: string;
      text: string;
      teamName?: string | null;
      teamIconLocation?: string | null;
    };
    user: {
      data: {
        name: string;
      };
    };
  };
}
