function SvgObjectives(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#objectives_svg__clip0)">
        <path
          d="M255 65.302a14 14 0 0111.047-13.686l118-25.458C392.768 24.276 401 30.922 401 39.843v68.131c0 8.651-7.768 15.231-16.302 13.809l-11.238-1.873a13.988 13.988 0 00-4.91.055l-96.941 18.385c-8.624 1.636-16.609-4.977-16.609-13.755V65.302z"
          fill="#F63A55"
        />
        <path
          d="M509.622 464.823L402.004 289.912c-2.943-4.782-8.052-7.637-13.667-7.637-5.614 0-10.723 2.856-13.664 7.637l-13.972 22.709a7.512 7.512 0 0012.796 7.873l13.972-22.71c.074-.12.299-.486.87-.486s.797.366.871.486l107.618 174.911c.086.139.314.51.022 1.033-.292.523-.728.523-.892.523h-59.6L269.665 203.333a16 16 0 00-5.958-5.678l.008.004v-50.98a69.434 69.434 0 0018.297 2.419c17.132 0 33.175-5.955 48.87-11.781 21.748-8.073 42.29-15.698 64.508-6.788a9.276 9.276 0 008.66-.927 9.286 9.286 0 004.089-7.701V37.044a9.286 9.286 0 00-5.65-8.561c-28.203-12.027-52.927-2.851-76.836 6.023-22.398 8.314-43.553 16.167-66.588 5.872a7.514 7.514 0 00-10.577 6.858v.279a7.7 7.7 0 00-.203 1.736v148.408a16.016 16.016 0 00-5.951 5.674l-79.139 128.623-25.868-42.043c-2.942-4.782-8.051-7.637-13.666-7.637-5.614 0-10.723 2.855-13.665 7.637L2.377 464.823a16.06 16.06 0 00-.341 16.235 16.057 16.057 0 0014.006 8.218h479.915a16.057 16.057 0 0014.006-8.218 16.057 16.057 0 00-.341-16.235zM263.512 57.901c24.317 6.67 46.158-1.437 67.37-9.311 20.985-7.79 40.867-15.168 62.234-7.642v72.985c-24.39-6.688-46.253 1.428-67.462 9.3-20.95 7.776-40.813 15.149-62.142 7.646V57.901zM93.282 474.252l25.771-41.885a7.512 7.512 0 00-12.795-7.873l-30.615 49.758h-59.6c-.164 0-.6 0-.892-.523-.292-.523-.065-.893.022-1.033l107.619-174.91c.074-.12.299-.486.87-.486s.796.366.87.486l29.844 48.504-29.799 48.431a7.512 7.512 0 0012.795 7.873L255.13 211.205c.074-.12.299-.486.87-.486.57 0 .795.366.87.486l161.848 263.047H93.282z"
          fill="#5A5665"
        />
      </g>
      <defs>
        <clipPath id="objectives_svg__clip0">
          <path fill="#fff" d="M0 0h512v512H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgObjectives;
