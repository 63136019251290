import * as constants from 'consts/constants';

const initialState = {
  data: null,
  isLoading: false,
};

export function session(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_LOGGING_IN:
      return { ...initialState, isLoading: true };
    case constants.USER_LOGGED_IN:
      return { data: payload, isLoading: false };
    case constants.USER_LOGGED_OUT:
      return initialState;
    case constants.SET_SESSION_TOKEN:
      return { ...state, data: { ...state.data, id: payload }, isLoading: false };
    case constants.USER_DATA_UPDATED:
      return { ...state, data: { ...state.data, _user: { ...state.data._user, ...payload } } };
    case constants.GOOGLE_READY:
      return { ...state, googleReady: true };
    default:
      return state;
  }
}
