import { memo, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Table.module.css';

type TableProps = {
  children: ReactNode;
  isRaw?: boolean;
  isUnleash?: boolean;
  className?: string;
};

const Table = ({ children, isRaw, isUnleash, className }: TableProps) => {
  const Classes = classNames(className, styles.Table, isUnleash && styles.Table_unleash);

  return (
    <div data-testid="profileTable" className={!isRaw ? Classes : undefined}>
      {children}
    </div>
  );
};

export default memo(Table);
