function SvgLegends(props) {
  return (
    <svg viewBox="0 0 486 356" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M328.629 192.627a6.919 6.919 0 01-6.061-3.556L251.995 62.384a6.919 6.919 0 1112.08-6.74l66.726 119.753 92.845-70.13a6.93 6.93 0 018.337 11.07l-99.182 74.891a6.919 6.919 0 01-4.172 1.398zM168.082 197.055a6.919 6.919 0 01-4.096-1.343L54.68 115.508a6.92 6.92 0 118.192-11.153l102.58 75.264L224.07 57.403a6.919 6.919 0 1112.454 5.985l-62.222 129.73a6.919 6.919 0 01-6.227 3.923l.007.014z"
          fill="#5A5665"
        />
        <path
          d="M397.202 306.692H89.857a6.919 6.919 0 01-6.684-5.113L35.031 123.437a6.922 6.922 0 1113.367-3.604l46.759 173.021h296.745l46.557-172.89a6.922 6.922 0 1113.368 3.605l-47.941 178.003a6.919 6.919 0 01-6.684 5.12z"
          fill="#5A5665"
        />
        <path
          d="M397.202 355.325H89.31a16.183 16.183 0 01-15.685-20.217l9.5-36.989a6.919 6.919 0 016.704-5.196h306.847a6.919 6.919 0 016.705 5.196l9.506 36.996a16.197 16.197 0 01-15.685 20.217v-.007zm-301.997-48.57l-8.164 31.826a2.352 2.352 0 002.276 2.94h307.892a2.352 2.352 0 002.276-2.933l-8.171-31.827-296.109-.007z"
          fill="#5A5665"
        />
        <path
          d="M243 70.342a34.83 34.83 0 1133.806-26.569c-3.747 15.622-17.74 26.62-33.806 26.569zm0-55.83c-11.124.004-20.322 8.668-20.99 19.771-.668 11.104 7.425 20.808 18.468 22.144 11.043 1.337 21.218-6.156 23.218-17.098 2.001-10.942-4.864-21.55-15.666-24.207a21.269 21.269 0 00-5.1-.61h.07zM450.325 129.208a34.83 34.83 0 1133.84-26.555c-3.768 15.62-17.77 26.609-33.84 26.555zm0-55.836c-11.123.003-20.321 8.667-20.99 19.77-.667 11.104 7.426 20.808 18.469 22.145 11.043 1.336 21.217-6.156 23.218-17.099 2-10.942-4.865-21.55-15.667-24.207a21.269 21.269 0 00-5.064-.609h.034zM35.675 129.208c-18.438 0-33.687-14.359-34.795-32.763-1.108-18.405 12.308-34.489 30.613-36.7 18.305-2.212 35.166 10.213 38.474 28.352 3.307 18.139-8.083 35.716-25.99 40.108a34.892 34.892 0 01-8.302 1.003zm0-55.836a21.269 21.269 0 00-5.03.609c-10.722 2.647-17.53 13.182-15.54 24.045 1.99 10.862 12.093 18.298 23.056 16.971 10.964-1.327 19-10.959 18.341-21.982-.658-11.024-9.784-19.63-20.827-19.643z"
          fill="#F63A55"
        />
      </g>
    </svg>
  );
}

export default SvgLegends;
