import { useEffect } from 'react';

import { ReactComponent as EmptyImage } from '../emptyImage.svg';

import styles from './Empty.module.scss';

interface EmptyProps {
  setThereAreNotifications: (value: boolean) => void;
}

export function Empty({ setThereAreNotifications }: EmptyProps) {
  useEffect(() => {
    setThereAreNotifications(false);

    return () => setThereAreNotifications(true);
  }, [setThereAreNotifications]);

  return (
    <div className={styles.container}>
      <EmptyImage />
      <strong>No notifications</strong>
      <span>We will notify you when something arrives</span>
    </div>
  );
}
