import { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import isKeyboardEvent from 'utils/keyboardEvent';

import { Paragraph } from '../Paragraph';

import styles from './Message.module.scss';

export type MessageProps = {
  type: 'success' | 'error';
  glow?: boolean;
  closeButton?: boolean;
  duration?: number;
  onClose?: () => void;
};

export const defaultProps: Required<MessageProps> = {
  type: 'success',
  glow: false,
  closeButton: false,
  duration: 0,
  onClose: () => {},
};

export const Message: React.FC<MessageProps> = (props) => {
  const { type, glow, closeButton: closable, duration, onClose, children } = {
    ...defaultProps,
    ...props,
  };

  const timer = useRef<{ timeoutId?: number }>({});
  const [show, setShow] = useState<boolean>(true);

  const className = cx(styles.Message, {
    [styles.Show]: show,
    [styles.Success]: type === 'success',
    [styles.Error]: type === 'error',
    [styles.Glow]: glow,
  });

  const handleClose = () => {
    setShow(false);
    clearTimeout(timer.current.timeoutId);
    onClose();
  };

  useEffect(() => {
    let timeoutId: number | undefined;

    if (show && duration > 0) {
      clearTimeout(timer.current.timeoutId);
      timeoutId = timer.current.timeoutId = +setTimeout(() => {
        handleClose();
      }, duration);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, show]);

  if (!show) {
    return null;
  }

  return (
    <Paragraph className={className} variant="ui-regular">
      {closable && (
        <span
          role="button"
          aria-roledescription="Close"
          aria-label="Close"
          data-dismiss="toast"
          className={styles.MessageCloseButton}
          onClick={handleClose}
          tabIndex={0}
          onKeyUp={(e) => isKeyboardEvent(e) && handleClose()}
        />
      )}
      {children}
    </Paragraph>
  );
};
