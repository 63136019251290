import { PropsWithChildren, SyntheticEvent, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { FocusOn } from 'react-focus-on';
import { useDispatch } from 'react-redux';

import { ReactComponent as ThinLeftArrow } from 'assets/thin-left-arrow.svg';
import Button from 'components/buttons/Button';
import FluidButton from 'components/buttons/FluidButton';
import { removeAllModalToasts } from 'store/actionCreators';
import { ToastMessage } from 'types/types';
import { useLatest } from 'utils/hooks';

import styles from './ModalFullScreen.module.scss';

const ModalFullScreen = ({
  modalClassName,
  contentClassName,
  headerClassName,
  footerClassName,
  buttonDisabled,
  submitButtonName = 'Submit',
  cancelButtonName = 'Cancel',
  overlayClassName,
  onClose,
  onSubmit,
  show,
  cancelButtonClassName,
  saveButtonClassName,
  toasts,
  children,
  ...props
}: PropsWithChildren<ModalFullScreenProps>) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const didMountRef = useRef(false);
  const prevValues = useLatest({ toasts }).current;

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(removeAllModalToasts());
    onClose();
  };

  // TODO: This should be removed once the new toast messages are implemented
  const scrollToModalTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (
        toasts &&
        prevValues?.toasts &&
        toasts.length > 0 &&
        toasts.length >= prevValues.toasts.length
      ) {
        scrollToModalTop();
      }
    } else {
      didMountRef.current = true;
    }
  }, [didMountRef, prevValues, toasts]);

  const stopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const wrappedContentClassNames = classNames(styles.Content, contentClassName);
  const wrappedHeaderClassNames = classNames(styles.Header, headerClassName);
  const wrappedModalClassNames = classNames(styles.Modal, modalClassName);
  const wrappedFooterClassNames = classNames(styles.Footer, footerClassName);
  const overlayClassNames = classNames(styles.Overlay, overlayClassName);
  const cancelButton = classNames(styles.CancelButton, cancelButtonClassName);
  const saveButton = classNames(styles.SaveButton, saveButtonClassName);

  if (!show) {
    return null;
  }

  return (
    <FocusOn onEscapeKey={onClose}>
      <div {...props} role="dialog" aria-modal="true">
        {/* The <div> element has a child <button> element that allows keyboard interaction */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={overlayClassNames} onClick={handleClose} data-testid="close-modal-box">
          <div className={wrappedHeaderClassNames}>
            <Button
              onClick={handleClose}
              isClose
              className={styles.CloseButton}
              title="CloseModal"
            />
          </div>
          <FluidButton level="secondary" onClick={handleClose} className={styles.GoBackButton}>
            <ThinLeftArrow />
            <span>Go back</span>
          </FluidButton>
          {/* There's no interaction with this element, just preventing click propagation */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            ref={modalRef}
            className={wrappedModalClassNames}
            onClick={stopPropagation}
            onWheel={stopPropagation}
          >
            <div className={wrappedContentClassNames}>{children}</div>
            {onSubmit && (
              <div className={wrappedFooterClassNames}>
                <FluidButton level="tertiary" className={cancelButton} onClick={handleClose}>
                  {cancelButtonName}
                </FluidButton>
                <FluidButton
                  className={saveButton}
                  type="submit"
                  onClick={onSubmit}
                  disabled={buttonDisabled}
                >
                  {submitButtonName}
                </FluidButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </FocusOn>
  );
};

export default ModalFullScreen;

export interface ModalFullScreenProps {
  onClose: () => void;
  show?: boolean;
  modalClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  buttonDisabled?: boolean;
  saveButtonClassName?: string;
  cancelButtonClassName?: string;
  submitButtonName?: string;
  cancelButtonName?: string;
  overlayClassName?: string;
  onSubmit?: () => void;
  toasts?: ToastMessage[];
}
