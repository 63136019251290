function SvgCommunity(props) {
  return (
    <svg viewBox="0 0 25 31" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M24.575 3.776L12.67.026a.57.57 0 00-.341 0L.424 3.776A.622.622 0 000 4.375v13.75c0 5.528 8.026 10.444 12.324 11.848a.56.56 0 00.352 0C16.974 28.568 25 23.653 25 18.125V4.375a.622.622 0 00-.425-.599zm-.765 14.349c0 4.389-6.623 9.004-11.31 10.595-4.687-1.591-11.31-6.206-11.31-10.595V4.84L12.5 1.278 23.81 4.84v13.285z"
          fill="#5A5665"
        />
        <g fill="#F63A55" stroke="#F63A55" strokeWidth={0.5}>
          <path d="M16.867 16.791l-1.471-.544a.275.275 0 00-.305.082c-.721.836-1.642 1.296-2.591 1.296-.95 0-1.87-.46-2.591-1.296a.276.276 0 00-.305-.082l-1.471.544c-1.494.552-2.497 2.05-2.497 3.729v.446c0 .167.128.304.286.304h13.156c.158 0 .286-.137.286-.304v-.446c0-1.68-1.003-3.178-2.497-3.729zm-10.673 3.86v-.141c0-1.421.849-2.69 2.113-3.156l1.297-.479c.81.87 1.828 1.347 2.882 1.347s2.071-.477 2.882-1.347l1.298.48c1.263.466 2.112 1.734 2.112 3.155v.142H6.194z" />
          <path d="M15.89 10.794c.002-.026.002-.053.002-.079 0-2-1.534-3.625-3.42-3.625s-3.42 1.626-3.42 3.625c0 .026 0 .053.002.079-.34.197-.572.747-.572 1.432 0 .84.35 1.48.82 1.51.518 1.82 1.766 3.022 3.17 3.022 1.405 0 2.652-1.203 3.17-3.023.47-.03.82-.669.82-1.51 0-.684-.232-1.234-.572-1.431zm-.3 2.325a.272.272 0 00-.258-.023.298.298 0 00-.169.207c-.399 1.705-1.48 2.85-2.69 2.85-1.212 0-2.293-1.145-2.692-2.85a.3.3 0 00-.278-.22.305.305 0 00-.166.05c-.071 0-.285-.322-.285-.907 0-.554.192-.871.273-.904a.285.285 0 00.239-.086.314.314 0 00.08-.245l-.007-.075c-.007-.067-.015-.132-.015-.2 0-1.667 1.279-3.022 2.85-3.022 1.572 0 2.85 1.355 2.85 3.021 0 .069-.008.134-.015.2l-.008.076c-.008.09.021.18.082.245a.282.282 0 00.23.087l.009-.001c.08.033.272.35.272.904 0 .585-.21.906-.303.893z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgCommunity;
