import { useEffect, SyntheticEvent, PropsWithChildren } from 'react';

import c from 'classnames';

import { ReactComponent as CrossRemove } from 'assets/cross-remove.svg';
import ModalBackButton from 'components/modals/ModalBackButton';
import { useIsMobile } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './FeedModal.module.scss';

export const FeedModal = (props: PropsWithChildren<FeedModalProps>) => {
  const { className = '', containerClass = '', modalWrapperClass = '', onClose = () => {} } = props;
  const isMobile = useIsMobile();

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      document.body.style.overflow = '';
    };
  });

  const onClickMask = (event: SyntheticEvent) => {
    const target = event.target as HTMLBaseElement;
    if (target.dataset?.type === 'feed-modal-mask') {
      onClose();
    }
  };

  return (
    <div
      data-type="feed-modal-mask"
      className={c(className, styles.FeedModal, modalWrapperClass)}
      onClick={onClickMask}
      onKeyUp={(e) => isKeyboardEvent(e) && onClickMask(e)}
      role="button"
      tabIndex={0}
    >
      {isMobile ? (
        <ModalBackButton isVisible closeModal={onClose} />
      ) : (
        <button className={styles.CloseButton} onClick={() => onClose()}>
          <CrossRemove />
        </button>
      )}
      <div className={c(styles.ContainerClass, containerClass)}>{props.children}</div>
    </div>
  );
};

interface FeedModalProps {
  className?: string;
  containerClass?: string;
  modalWrapperClass?: string;
  onClose?: () => void;
}
