import { useCallback } from 'react';

import DocumentSigningItem, { DocumentToSign } from '../DocumentSigningItem';
import Wrap from '../Wrap';
import Button from '../buttons/Button';
import Title from '../page/PageTitle';

import styles from './DocumentSigningList.module.scss';

export type DocumentGrouping = {
  id: number;
  name?: string;
  documents: DocumentToSign[];
};

type DocumentSigningListProps = {
  documentGroups: DocumentGrouping[];
  acceptTerm: (groupId: number, documentId: number, checked: boolean) => void;
  allTermsAccepted?: () => void;
};

const DocumentSigningList = ({
  documentGroups,
  acceptTerm,
  allTermsAccepted,
}: DocumentSigningListProps) => {
  const isAllTermsSelected = useCallback(() => {
    return !documentGroups.some((group) => group.documents.some((doc) => !doc.accepted));
  }, [documentGroups]);

  return (
    <div className={styles.ListWrapper}>
      <Wrap>
        {documentGroups.map((group) => (
          <section key={group.id} className={styles.ListSection}>
            <Title className={styles.Title}>{group.name}</Title>
            {group.documents.map((document) => (
              <DocumentSigningItem
                key={document.id}
                document={document}
                acceptTerm={(documentId, checked) => {
                  acceptTerm(group.id, documentId, checked);
                }}
              />
            ))}
          </section>
        ))}
      </Wrap>
      <Wrap isScrollable innerWrapClassName={styles.ConfirmButtonWrapper}>
        <Button
          data-testid="confirmButton"
          isFormBtn
          isFullWidth
          className={styles.ConfirmButton}
          disabled={!isAllTermsSelected()}
          onClick={() => allTermsAccepted && allTermsAccepted()}
        >
          Confirm
        </Button>
      </Wrap>
    </div>
  );
};

export default DocumentSigningList;
