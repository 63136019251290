import c from 'classnames';
// @ts-ignore
import { SinglePost } from 'react-activity-feed';

import { FEED } from 'consts/constants';

import {
  Activity,
  FeedActivityProps,
  POST_TYPES,
  RefreshFeedCallbackFunction,
} from '../Feed.types';
import { FeedModal } from '../FeedModal';
import { SinglePostBody } from '../SinglePostBody';

import styles from './FullPost.module.scss';

const FullPost = ({ activity, onClose = () => {}, refreshFeed }: FullPostProps) => {
  if (!activity) {
    return null;
  }

  const { mediaImages = [], mediaVideos = [], type } = activity;
  const isPinnedPost = type === POST_TYPES.PINNED_POST;

  const medias = [
    ...mediaVideos.map((v) => ({ type: 'video', publicId: v })),
    ...mediaImages.map((i) => ({ type: 'image', publicId: i })),
  ];

  const feedGroup = isPinnedPost ? FEED.PINNED_FEED : FEED.GLOBAL_FEED;

  return (
    <FeedModal
      containerClass={c(styles.FullPost, { [styles.NoMedia]: !medias.length })}
      onClose={onClose}
    >
      <SinglePost
        activityId={activity.id}
        feedGroup={feedGroup}
        options={{ withReactionCounts: true, withOwnReactions: false }}
        Activity={(feedProps: FeedActivityProps) => (
          <SinglePostBody feedProps={feedProps} medias={medias} refreshFeed={refreshFeed} />
        )}
      />
    </FeedModal>
  );
};

interface FullPostProps {
  activity?: Activity;
  onClose?: () => void;
  refreshFeed?: RefreshFeedCallbackFunction;
}

export default FullPost;
