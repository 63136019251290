import Youtube from 'components/Youtube';
import { useAdminSetting } from 'utils/hooks';

import Modal from '../ModalFullScreen';

import styles from './VideoModal.module.scss';

const VideoModal = ({ closeModal }: Props) => {
  const moduleSettings = useAdminSetting('dashboard-modules');

  return (
    <Modal onClose={closeModal} modalClassName={styles.VideoModal} show>
      <Youtube videoId={moduleSettings?.values.video_banner_link ?? ''} title="season video" />
      <h4 className={styles.VideoTitle}>{moduleSettings?.values.video_banner_title}</h4>
    </Modal>
  );
};

type Props = {
  closeModal: () => void;
};

export default VideoModal;
