import { memo, useState, useCallback, ChangeEventHandler, FormEventHandler } from 'react';

import Label from 'components/Label';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import { useSendAuthEmail } from 'utils/apiMutationHooks';
import { getErrorMessage } from 'utils/helpers';
import { useToast } from 'utils/hooks';

import styles from './EmailTokenLogin.module.css';

export const EmailTokenLogin = memo(() => {
  const [email, setEmail] = useState('');
  const toast = useToast();

  const onEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setEmail(e.currentTarget.value),
    [],
  );

  const [sendAuthEmail] = useSendAuthEmail({ useErrorBoundary: false });

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      try {
        await sendAuthEmail({ body: { email } });
        toast({
          text: 'Email sent!',
          type: 'success',
        });
      } catch (error) {
        toast({
          text: getErrorMessage(error) || 'Something went wrong…',
          type: 'error',
        });
      }
    },
    [email, sendAuthEmail, toast],
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Label>
        Email
        <Input
          placeholder="smith@x-team.com"
          type="email"
          required
          value={email}
          onChange={onEmailChange}
        />
      </Label>
      <Button isBig type="submit">
        Login
      </Button>
    </form>
  );
});
EmailTokenLogin.displayName = 'EmailTokenLogin';
