import { useState } from 'react';

import { Story, Meta } from '@storybook/react';
import { withDesign } from 'storybook-addon-designs';

import { NotificationIndicator } from '../../Atoms/NotificationIndicator';
import { Notifications, NotificationsProps } from './Notifications';

export default {
  title: 'Organisms/Notifications',
  component: Notifications,
  argTypes: {
    notificationsData: {
      type: { required: true },
      description: 'Defines the data that will render inside the notifications.',
      table: {
        defaultValue: { summary: '[]' },
        type: { summary: 'NotificationItemProps[]' },
      },
      control: {
        type: 'object',
      },
    },
    isOpen: {
      type: { required: true },
      description: 'Defines whether the notifications should be open or not.',
      table: {
        defaultValue: { summary: 'false' },
      },
      control: {
        type: 'boolean',
      },
    },
    wrapperClassName: {
      type: { required: false },
      description: 'Defines the classes that will be appended to the wrapper.',
      control: {
        type: 'text',
      },
    },
    onMarkAllReadClick: {
      type: { required: false },
      description: `Fired when user presses on the Mak all as read header button.`,
      action: 'clicked',
      control: {
        type: 'object',
        disabled: true,
      },
    },
  },
  decorators: [withDesign],
} as Meta;

const Template: Story<NotificationsProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ width: '100%', height: '100vh', margin: '0 auto' }}>
      <NotificationIndicator
        onClick={() => setIsOpen(!isOpen)}
        state="active"
        hasUnread={props.notificationsData.length > 0}
      />
      <Notifications
        isOpen={isOpen}
        notificationsData={props.notificationsData}
        wrapperClassName={props.wrapperClassName}
        onMarkAllReadClick={props.onMarkAllReadClick}
      />
    </div>
  );
};

export const NotificationsDefault = Template.bind({});
NotificationsDefault.storyName = 'Default';
NotificationsDefault.args = {
  notificationsData: [
    {
      imgSrc: 'https://thispersondoesnotexist.com/image',
      name: 'Robert Fox',
      action: 'commented your post',
      date: new Date(),
      unread: true,
    },
    {
      imgSrc: undefined,
      name: 'Ronald Rich',
      action: 'commented your post',
      date: new Date(),
      unread: false,
    },
    {
      imgSrc: 'https://thispersondoesnotexist.com/image',
      name: 'Guy Hawkins',
      action: 'reacted on your post',
      date: new Date(),
      unread: true,
    },
    {
      imgSrc: 'https://thispersondoesnotexist.com/image',
      name: 'Bessie Cooper',
      action: 'reacted on your post',
      date: new Date(),
      unread: true,
    },
    {
      imgSrc: 'https://thispersondoesnotexist.com/image',
      name: 'Theresa Webb',
      action: 'is now following you',
      date: new Date(),
      unread: false,
    },
  ],
};
NotificationsDefault.argTypes = {
  notificationsData: { control: { disable: true } },
  wrapperClassName: { control: { disable: true } },
  onMarkAllReadClick: { control: { disable: true } },
};
NotificationsDefault.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/4Kf6IUeVieub05iWIFgVcE/Modals?node-id=402%3A1518',
  },
};

export const NotificationsEmpty = Template.bind({});
NotificationsEmpty.storyName = 'Empty';
NotificationsEmpty.args = {
  ...NotificationsDefault.args,
  notificationsData: [],
};
NotificationsEmpty.argTypes = {
  ...NotificationsDefault.argTypes,
};
NotificationsEmpty.parameters = {
  ...NotificationsDefault.parameters,
};
