import { memo } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'assets/logo-lgbt-pride.svg';

import styles from './Logo.module.scss';

type LogoProps = {
  closeMenu?: () => {};
};

const Logo = ({ closeMenu }: LogoProps) => {
  return (
    <Link to="/" onClick={closeMenu} tabIndex={0} role="link">
      <LogoIcon title="X-Team" className={styles.Logo} />
    </Link>
  );
};

export default memo(Logo);
