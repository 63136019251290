import { memo, MouseEventHandler, KeyboardEventHandler } from 'react';

import { SyntheticEvent } from 'hoist-non-react-statics/node_modules/@types/react';

// eslint-disable-next-line import/no-restricted-paths

import isKeyboardEvent from 'utils/keyboardEvent';

import { Image } from '../../Atoms/Image';
import { Paragraph } from '../../Atoms/Paragraph';

import styles from './AnnouncementCard.module.scss';

type CardClickHandler = (
  e: SyntheticEvent<HTMLDivElement, Event> | undefined,
) => (MouseEventHandler<HTMLDivElement | undefined> & KeyboardEventHandler<HTMLDivElement>) | void;

type ActionClickHandler = (
  e: SyntheticEvent<HTMLButtonElement> | undefined,
) => MouseEventHandler<HTMLButtonElement | undefined> | void;

interface CardProps {
  imageSrc?: string;
  title: string;
  description?: string;
  userName: string;
  userAvatarSrc: string;
  theme?: ThemeOptions;
  showActions?: boolean;
  onCardClick: CardClickHandler;
  onEditClick?: ActionClickHandler;
  onPlayClick?: ActionClickHandler;
}

export enum ThemeOptions {
  LIGHT = 'light',
  DARK = 'dark',
}

export type AnnouncementCardProps = CardProps;

export const AnnouncementCard = memo<AnnouncementCardProps>(
  ({ theme = ThemeOptions.LIGHT, showActions = false, ...props }) => {
    const handleOnPlayClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      props.onPlayClick && props.onPlayClick(event);
    };

    const handleOnPlayEdit = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      props.onEditClick && props.onEditClick(event);
    };

    return (
      <div
        className={styles.CardWrapper}
        role="button"
        tabIndex={0}
        onKeyUp={(e) => isKeyboardEvent(e) && props.onCardClick(e)}
        onClick={props.onCardClick}
      >
        {props.imageSrc && (
          <Image
            variant="squared"
            overlayer
            className={styles.CommonAbsolutePositioning}
            src={props.imageSrc}
            alt="background"
          />
        )}

        <div className={getCardImagePlaceholderClassName(props.imageSrc, theme)} />

        {props.imageSrc && <div className={styles.CardOverlay} />}

        <div className={getCardOverlayBorderClassName(props.imageSrc, theme)} />

        <div
          className={
            showActions ? styles.CardContentWrapper : styles.CardContentWrapperWithoutAction
          }
        >
          {showActions && (
            <div className={styles.CardActions}>
              <button className={styles.CardButton} onClick={(e) => handleOnPlayClick(e)}>
                <svg viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0V14L11 7L0 0Z" fill="white" />
                </svg>
              </button>

              <button className={styles.CardButton} onClick={(e) => handleOnPlayEdit(e)}>
                <svg viewBox="0 0 4 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4Z"></path>
                  <path d="M2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6Z"></path>
                  <path d="M0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z"></path>
                </svg>
              </button>
            </div>
          )}

          <div className={styles.CardFooter}>
            <Paragraph variant="label" className={getCardTitleClassName(props.imageSrc, theme)}>
              {props.title}
            </Paragraph>

            <Paragraph
              variant="ui-regular"
              className={getCardDescriptionClassName(props.imageSrc, theme)}
            >
              {props.description}
            </Paragraph>

            <div className={styles.CardAvatarArea}>
              <Image
                variant="circle"
                src={props.userAvatarSrc}
                className={styles.CardAvatarImage}
                alt={`avatar ${props.userName}`}
              />

              <Paragraph
                variant="ui-regular"
                className={getCardAvatarNameClassName(props.imageSrc, theme)}
              >
                {props.userName}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const isThemeLight = (imageSrc: string | undefined, theme: ThemeOptions) =>
  !imageSrc && theme === ThemeOptions.LIGHT;

const isThemeDark = (imageSrc: string | undefined, theme: ThemeOptions) =>
  !imageSrc && theme === ThemeOptions.DARK;

const getCardImagePlaceholderClassName = (imageSrc: string | undefined, theme: ThemeOptions) => {
  if (isThemeLight(imageSrc, theme)) return styles.CardImagePlaceholderLight;
  else if (isThemeDark(imageSrc, theme)) return styles.CardImagePlaceholderDark;

  return '';
};

const getCardOverlayBorderClassName = (imageSrc: string | undefined, theme: ThemeOptions) => {
  if (isThemeLight(imageSrc, theme)) return styles.CardOverlayBorderOnlyLight;
  else if (isThemeDark(imageSrc, theme)) return styles.CardOverlayBorderOnlyDark;

  return '';
};

const getCardTitleClassName = (imageSrc: string | undefined, theme: ThemeOptions) => {
  if (imageSrc) return styles.CardTitle;
  else if (isThemeLight(imageSrc, theme)) return styles.CardTitleLight;
  else if (isThemeDark(imageSrc, theme)) return styles.CardTitleDark;

  return '';
};

const getCardDescriptionClassName = (imageSrc: string | undefined, theme: ThemeOptions) => {
  if (imageSrc) return styles.CardDescription;
  else if (isThemeLight(imageSrc, theme)) return styles.CardDescriptionLight;
  else if (isThemeDark(imageSrc, theme)) return styles.CardDescriptionDark;

  return '';
};

const getCardAvatarNameClassName = (imageSrc: string | undefined, theme: ThemeOptions) => {
  if (imageSrc) return styles.CardAvatarName;
  else if (isThemeLight(imageSrc, theme)) return styles.CardAvatarNameLight;
  else if (isThemeDark(imageSrc, theme)) return styles.CardAvatarNameDark;

  return '';
};
