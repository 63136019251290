import { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { userLoggingInActionCreator, userLoggedInActionCreator } from 'store/actionCreators';
import { getErrorMessage } from 'utils/helpers';
import { useToast } from 'utils/hooks';
import { getApiMutationHook } from 'utils/xhqFetcher';

export const useAcceptInvitation = getApiMutationHook<any, undefined, { token: string }>(
  '/sessions/accept',
  { method: 'POST' },
);

export const AcceptInvitationLogin = memo(() => {
  const {
    params: { token },
  } = useRouteMatch<{ token: string }>();
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const toast = useToast();
  const [acceptInvitation] = useAcceptInvitation();

  useEffect(() => {
    const run = async () => {
      dispatch(userLoggingInActionCreator());
      try {
        const session = await acceptInvitation({ body: { token } });
        if (!session) {
          throw new Error();
        }
        dispatch(userLoggedInActionCreator(session));
      } catch (error) {
        toast({
          text: getErrorMessage(error) || 'Error logging in',
          type: 'error',
        });
        replace('/login');
      }
    };
    run();
  }, [dispatch, replace, toast, token, acceptInvitation]);

  return null;
});
