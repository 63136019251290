import { PropsWithChildren, LabelHTMLAttributes } from 'react';

import classNames from 'classnames';

import styles from './Label.module.scss';

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  isPayment?: boolean;
  halfWidth?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  isPaymentDisplay?: boolean;
  submitStep?: boolean;
  unleashBudget?: boolean;
  reimburseField?: boolean;
  reimburseLabel?: boolean;
  manualDeduction?: boolean;
  manualBonus?: boolean;
  isModal?: boolean;
  required?: boolean;
  multiline?: boolean;
};

const Label = (props: PropsWithChildren<LabelProps>) => {
  const {
    className,
    children,
    isPayment,
    halfWidth,
    noPadding,
    noMargin,
    isPaymentDisplay,
    submitStep,
    unleashBudget,
    reimburseField,
    reimburseLabel,
    manualDeduction,
    manualBonus,
    isModal,
    multiline,
    required,
    ...restProps
  } = props;
  const Classes = classNames(
    styles.Label,
    multiline && styles.Label_multiline,
    isPayment && styles.Label_payment,
    halfWidth && styles.Label_halfwidth,
    noPadding && styles.Label_noPadding,
    noMargin && styles.Label_noMargin,
    isPaymentDisplay && styles.Label_paymentDisplay,
    submitStep && styles.Label_submitStep,
    unleashBudget && styles.Label_unleashBudget,
    reimburseField && styles.Label_reimburseField,
    reimburseLabel && styles.Label_reimburseLabel,
    manualDeduction && styles.Label_manualDeduction,
    manualBonus && styles.Label_manualBonus,
    isModal && styles.Label_modal,
    required && styles.Label_required,
    className,
  );

  return (
    <label className={Classes} {...restProps}>
      {children}
    </label>
  );
};

export default Label;
