export const GOOGLE_READY = 'GOOGLE_READY';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const USER_DATA_UPDATED = 'USER_DATA_UPDATED';

export const PAYMENT_METHOD = {
  PAYPAL: 'paypal',
  AUSTRALIAN_LOCAL_BANK_TRANSFER: 'australian_local_bank_transfer',
  US_LOCAL_BANK_TRANSFER: 'us_local_bank_transfer',
  US_INTERNATIONAL_WIRED_TRANSFER: 'us_international_wired_transfer',
  EU_LOCAL_BANK_TRANSFER: 'european_local_bank_transfer',
  OTHER_AUD: 'other_aud',
  OTHER_USD: 'other_usd',
} as const;

export const INVOICE_PAYMENT_TYPE = {
  AIRWALLEX: 'airwallex',
  NON_AIRWALLEX: 'non-airwallex',
} as const;

export const SHARED_ACCOUNT_FIELDS = {
  paymentAccountOwnerName: { label: 'Account Owner Name', type: 'text' },
  paymentAccountOwnerAddress: { label: 'Account Owner Street', type: 'text' },
  paymentAccountOwnerCity: { label: 'Account Owner City', type: 'text' },
  paymentAccountOwnerZipcode: { label: 'Account Owner ZIP code', type: 'text' },
  paymentAccountOwnerCountry: { label: 'Account Owner Country', type: 'dropdown' },
};

export const paymentMethodsMap = {
  [PAYMENT_METHOD.PAYPAL]: {
    label: 'PayPal - not supported',
    paymentVia: INVOICE_PAYMENT_TYPE.NON_AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentEmail: { label: 'Paypal Email Address', type: 'email' },
    },
  },
  [PAYMENT_METHOD.AUSTRALIAN_LOCAL_BANK_TRANSFER]: {
    label: 'Australian Local Payment (AUD)',
    paymentVia: INVOICE_PAYMENT_TYPE.AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentBsbNumber: { label: 'BSB Number', type: 'text' },
      paymentAccountNumber: { label: 'Account Number', type: 'text' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
    },
  },
  [PAYMENT_METHOD.US_LOCAL_BANK_TRANSFER]: {
    label: 'US Local Payment (USD)',
    paymentVia: INVOICE_PAYMENT_TYPE.AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber: { label: 'Account Number', type: 'text' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
      paymentRoutingNumber: { label: 'Routing Number (U.S.)', type: 'text' },
    },
  },
  [PAYMENT_METHOD.US_INTERNATIONAL_WIRED_TRANSFER]: {
    label: 'International Wire Payment (USD)',
    paymentVia: INVOICE_PAYMENT_TYPE.AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber: { label: 'Account Number', type: 'text' },
      paymentBankCountryCode: { label: 'Bank Country Code', type: 'dropdown' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
      paymentSwiftCode: { label: 'Swift Code', type: 'text' },
    },
  },
  [PAYMENT_METHOD.EU_LOCAL_BANK_TRANSFER]: {
    label: 'EUR Local Payment (EU Only)',
    paymentVia: INVOICE_PAYMENT_TYPE.AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentBankCountryCode: { label: 'Bank Country Code', type: 'dropdown' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
      paymentSwiftCode: { label: 'Swift Code', type: 'text' },
      paymentAccountIban: { label: 'Bank Account IBAN', type: 'text' },
    },
  },
  [PAYMENT_METHOD.OTHER_AUD]: {
    label: 'Other Payment (AUD)',
    paymentVia: INVOICE_PAYMENT_TYPE.NON_AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber: { label: 'Account Number', type: 'text' },
      paymentBankCountryCode: { label: 'Bank Country Code', type: 'dropdown' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
      paymentSwiftCode: { label: 'Swift Code', type: 'text', optional: true },
    },
  },
  [PAYMENT_METHOD.OTHER_USD]: {
    label: 'Other Payment (USD)',
    paymentVia: INVOICE_PAYMENT_TYPE.NON_AIRWALLEX,
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber: { label: 'Account Number', type: 'text' },
      paymentBankCountryCode: { label: 'Bank Country Code', type: 'dropdown' },
      paymentBankName: { label: 'Bank Name', type: 'text' },
      paymentBankAddress: { label: 'Bank Address', type: 'text' },
      paymentSwiftCode: { label: 'Swift Code', type: 'text', optional: true },
    },
  },
} as const;

export const paymentWideFields = ['paymentBankAddress', 'paymentAccountOwnerAddress'] as const;

export const INVOICE_STATUS = {
  APPROVED: 'approved',
  SUBMITTED: 'submitted',
  PAID: 'paid',
  DRAFT: 'draft',
  QUEUED: 'queued',
  PROCESSING_PAYMENT: 'processing_payment',
  FAILED: 'failed',
} as const;

export const ACTIVITY_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
} as const;

export const mapStatusToBoolean = {
  true: true,
  false: false,
} as const;

export const STATUS_BOOLEAN = {
  ACTIVE: true,
  INACTIVE: false,
} as const;

export const mapBooleanToStatus = {
  [String(STATUS_BOOLEAN.ACTIVE)]: 'Active',
  [String(STATUS_BOOLEAN.INACTIVE)]: 'Inactive',
} as const;

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const mapStatusToLabel = {
  [STATUS.ACTIVE]: 'Active',
  [STATUS.INACTIVE]: 'Inactive',
} as const;

export const mapUserStatusToLabel = {
  [String(ACTIVITY_STATUS.ACTIVE)]: 'Active',
  [String(ACTIVITY_STATUS.INACTIVE)]: 'Inactive',
} as const;

export const mapStatusToId = {
  [STATUS.ACTIVE]: 1,
  [STATUS.INACTIVE]: 2,
} as const;

export const MISSION_REWARD = {
  COIN: 'coin',
  STAR: 'star',
  VAULT_ITEM: 'vault item',
} as const;

// @todo fetch from the API
export const activityStatuses = [
  {
    label: 'Active',
    value: ACTIVITY_STATUS.ACTIVE,
    className: 'statusPaid',
  },
  {
    label: 'Inactive',
    value: ACTIVITY_STATUS.INACTIVE,
    className: 'statusApproved',
  },
] as const;

export const NOTES_STATUS = {
  VIEW_ALL: false,
  OPEN_NOTES_ONLY: true,
};

export const notesSatuses = [
  {
    label: 'View all',
    value: NOTES_STATUS.VIEW_ALL,
  },
  {
    label: 'Open notes only',
    value: NOTES_STATUS.OPEN_NOTES_ONLY,
  },
];

// @todo fetch from the API
export const confirmationStatuses = [
  {
    label: 'Approved',
    value: INVOICE_STATUS.APPROVED,
    className: 'statusApproved',
  },
  // {
  //   label: 'Disapproved',
  //   value: PAYMENT_STATUS.DISAPPROVED,
  //   className: 'statusSubmitted'
  // },
  {
    label: 'Submitted',
    value: INVOICE_STATUS.SUBMITTED,
    className: 'statusSubmitted',
  },
  { label: 'Paid', value: INVOICE_STATUS.PAID, className: 'statusPaid' },
] as const;

export const BREAKPOINTS = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
} as const;

export const DRAG_EVENTS = {
  start: 'start',
  end: 'end',
} as const;

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';
export const ADD_MODAL_TOAST = 'ADD_MODAL_TOAST';
export const REMOVE_MODAL_TOAST = 'REMOVE_MODAL_TOAST';
export const REMOVE_ALL_MODAL_TOASTS = 'REMOVE_ALL_MODAL_TOASTS';

export const VAULT_ITEM_TYPES = {
  NORMAL_ITEM: 'normal item',
  CHARITY: 'charity',
  CUSTOM_CHARITY: 'custom-charity',
  ARENA_PASS: 'arena-pass',
  PRINTFUL: 'printful',
  EXPERIENCES: 'experiences',
  DIGITAL: 'digital',
  XVIP: 'XVIP',
  BOUNTIES_COLLECTION_PASS: 'bounties-collection-pass',
} as const;

export const VAULT_ITEM_GOALS = {
  NONE: 'none',
  PURCHASES_GOAL: 'purchases goal',
  VALUE_GOAL: 'value goal',
} as const;

export const RARITY_TYPES = {
  COMMON: 'common',
  RARE: 'rare',
  LEGENDARY: 'legendary',
} as const;

export const DEFAULT_REFERRAL_REIMBURSEMENT_RATE = 500;

export const DEBOUNCE_WAIT_INTERVAL = 500;

export const USER_ROLE = {
  USER: 'user',
  SUPER_ADMIN: 'super admin',
  ADMIN: 'admin',
  COMMUNITY_TEAM: 'community team',
  MANAGER: 'manager',
  CORE_MANAGER: 'core manager',
  PEOPLE_OPS: 'people ops',
  RECRUITER: 'recruiter',
  XVIP_USER: 'XVIP User',
} as const;

export const USER_TYPE = {
  CAVALRY: 'cavalry',
  DEVELOPER: 'developer',
  OTHER: 'other',
} as const;

export const INVOICE_ITEM_TYPES = {
  DEDUCTION: 'deduction',
  MANUAL_DEDUCTION: 'manual_deduction',
  MANUAL_BONUS: 'manual_bonus',
} as const;

export const INVOICE_CATEGORY = {
  CONTENT_CREATION: 'Content Creation',
  GST: 'GST',
  HOURS_WORKED: 'Hours Worked',
  PER_DIEM: 'Per Diem',
  REFERRAL: 'Referral',
  SOFTWARE: 'Software',
  TRAVEL_COSTS: 'Travel Costs',
  UNLEASH_PLUS: 'Unleash+',
  OTHER: 'Other',
} as const;

export const MOOD_FACE = {
  VERYSAD: '-2',
  SAD: '-1',
  NEUTRAL: '0',
  HAPPY: '1',
  VERYHAPPY: '2',
} as const;

export const HEALTH_CHECK_INTERVAL = 5000;

export const VACATION_REQUEST_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
} as const;

export const vacationRequestStatusMap = {
  [VACATION_REQUEST_STATUS.PENDING]: 'Pending',
  [VACATION_REQUEST_STATUS.APPROVED]: 'Approved',
  [VACATION_REQUEST_STATUS.DECLINED]: 'Declined',
} as const;

export const CONTACTS_FOR_INVOICES = [
  {
    NAME: 'Luis',
    SLACK_ID: 'ULCRZLN95',
    TYPE: 'user',
  },
  {
    NAME: '#support-desk',
    SLACK_ID: 'CCSHSBUUT',
    TYPE: 'channel',
  },
] as const;

export const BANKING_DETAILS_UPDATE_WARNING_THRESHOLD_DAYS = 31;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BUSINESS_DAYS_UNTIL_INVOICE_SUBMISSION = 1;

export const NATIONALITY_REGEX = /([a-zA-Z]){2,}/;
export const PHONE_REGEX = /^(\+?\d{1,3}[\s.-]?)?(\(?\d{2,4}\)?)?[\s.-]?\d{2,4}[\s.-]?\d{4}$/;

export const YOUTUBE_VIDEO_STATE = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
};

export const DATE_FORMAT_UTC = 'YYYY-MM-DDT00:00:00.000Z';
export const DATE_FORMAT_SHORT = 'YYYY-MM-DD';
export const DATE_FORMAT_MONTH_LABEL = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_LABEL_SHORT = 'MMM YY';
export const DATE_FORMAT_MONTH_DAY_LABEL = 'MMM DD';
export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';
export const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} hh:mm A`;

export const BOUNTY_PATH_TYPES = {
  CREATIVE: 'Creative',
  EXPLORATION: 'Exploration',
  GROWTH: 'Growth',
  FITNESS: 'Fitness',
  GAMER: 'Gamer',
  CLUBS: 'Clubs',
  UNLEASHER: 'Unleasher',
};

export const PROJECT_TYPES = {
  EXTERNAL: 'external',
  INTERNAL_BENCH: 'internal-bench',
  INTERNAL_OPERATIONS: 'internal-operations',
} as const;

export const CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
  EUR: 'EUR',
  AUD: 'AUD',
} as const;

export const DateFilterTypeName = {
  ALL: 'ALL TIME',
  TODAY: 'TODAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  RANGE: 'RANGE',
};

export const CompanyFilterTypeName = {
  XWP: 'XWP',
  XTEAM: 'X-TEAM',
  ALL_COMPANIES: 'ALL',
};

export const BOUNTY_TYPE = {
  STANDARD: 'STANDARD',
  USER_CREATED: 'USER_CREATED',
  PRIVATE: 'PRIVATE',
  XVIP: 'XVIP',
} as const;

export const BOUNTY_LIMIT = 10;

export const MANAGE_LISTS_MODAL = 'MANAGE_LISTS_MODAL';
export const ADD_BOUNTY_TO_LIST_MODAL = 'ADD_BOUNTY_TO_LIST_MODAL';

export const DEFAULT_UNLEASH_BUDGET = 2500;

export const CURRENCIES_SYMBOLS: { [key: string]: string } = {
  [CURRENCIES.USD]: '$',
  [CURRENCIES.CAD]: '$',
  [CURRENCIES.EUR]: '€',
  [CURRENCIES.AUD]: '$',
};

export const FEED = {
  USER_FEED: 'user',
  GLOBAL_FEED: 'global',
  PINNED_FEED: 'pinned',
  SUGGESTED: 'SUGGESTED',
};

export const MISSION_INFO = {
  key: 'all',
  label: 'All missions',
  icon: undefined,
};

export const USER_ID_PREFIX = 'XHQ';

export enum CONTRACTING_OPTIONS {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export const CONTRACTING_OPTIONS_LABELS = {
  [CONTRACTING_OPTIONS.INDIVIDUAL]: 'I contracted with X-Team as an individual',
  [CONTRACTING_OPTIONS.COMPANY]: 'I contracted with X-Team through a Company',
};

export const EMPLOYMENT_TYPE = {
  EMPLOYEE: '1',
  CONTRACTOR: '2',
  COMPANY: '3',
};

export const EMPLOYMENT_TYPE_NAME = {
  EMPLOYEE: 'Employee',
  CONTRACTOR: 'Contractor',
  COMPANY: 'Company',
};

export const PAYMENT_TYPE = {
  US_LOCAL_PAYMENT: 1,
  EUR_LOCAL_PAYMENT: 2,
  OTHER_PAYMENT_AUD: 3,
  OTHER_PAYMENT_USD: 4,
  PAYPAL: 5,
  AUD_LOCAL_PAYMENT: 6,
  INTERNATIONAL_WIRE_PAYMENT: 7,
};

export const PAYMENT_VIA = {
  AIRWALLEX: '1',
  NON_AIRWALLEX: '2',
};

export const SUBMIT_INVOICE_STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5,
};
