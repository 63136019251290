import { memo, MouseEventHandler } from 'react';

import cx from 'classnames';

import { ReactComponent as Empty } from 'design-system/assets/Notifications/notifications-empty.svg';

import { Button } from '../../Atoms/Buttons';
import { Paragraph } from '../../Atoms/Paragraph';
import { NotificationItem, NotificationItemProps } from '../../Molecules/NotificationItem';

import styles from './Notifications.module.scss';

export interface NotificationsProps {
  notificationsData: NotificationItemProps[];
  isOpen: boolean;
  wrapperClassName?: string;
  onMarkAllReadClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Notifications = memo<NotificationsProps>((props: NotificationsProps) => {
  const showNoNotifications = !props.notificationsData || props.notificationsData.length === 0;
  const wrapperClasses = cx(styles.Wrapper, props.wrapperClassName);

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className={wrapperClasses}>
      <div className={styles.Header}>
        <Paragraph variant="label">Notifications</Paragraph>
        {!showNoNotifications && (
          <Button
            onClick={props.onMarkAllReadClick}
            className={styles.HeaderButton}
            layout="autolayout"
            as="button"
            variant="tertiary"
          >
            Mark all as read
          </Button>
        )}
      </div>

      {!showNoNotifications &&
        props.notificationsData.map((notification, index) => (
          <NotificationItem
            key={`${index}-${notification.name}`}
            imgSrc={notification.imgSrc}
            name={notification.name}
            action={notification.action}
            date={notification.date}
            unread={notification.unread}
            onClick={notification.onClick}
          />
        ))}

      {showNoNotifications && (
        <div className={styles.EmptyWrapper}>
          <Empty />
          <Paragraph variant="bold">No notifications</Paragraph>
          <Paragraph variant="regular">We will notify you when someting arrives</Paragraph>
        </div>
      )}
    </div>
  );
});
