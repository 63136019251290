import { Children, memo, ReactElement } from 'react';

import cx from 'classnames';

import { useIsMobile } from 'utils/hooks';

import { Heading } from '../../Atoms/Heading';
import { StickyContainer } from '../StickyContainer';

import styles from './Page.module.scss';

export interface PageProps {
  children: ReactElement[];
  className?: string;
  title?: string | ReactElement;
  stickySideContent?: boolean;
}

export const Page = memo<PageProps>(({ children, className, stickySideContent = true, title }) => {
  const isMobile = useIsMobile();
  const [firstChild, ...otherChildren] = Children.toArray(children);
  const hasSiblings = otherChildren.length > 0;

  const containerClasses = cx(styles.Page, className, { [styles.WithSideContent]: hasSiblings });
  const sideContentClasses = cx(styles.SideContent, { [styles.SkipLine]: !!title });

  return (
    <div className={containerClasses}>
      {title && (
        <div className={styles.PageTitle}>
          {typeof title === 'string' ? <Heading level="h3">{title}</Heading> : title}
        </div>
      )}

      {hasSiblings && (
        <div className={sideContentClasses}>
          <StickyContainer topOffset={40} enabled={stickySideContent && !isMobile}>
            {firstChild}
          </StickyContainer>
        </div>
      )}

      <div className={styles.MainContent}>{hasSiblings ? otherChildren : firstChild}</div>
    </div>
  );
});
