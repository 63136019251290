import { Fragment, useState, useCallback, useEffect } from 'react';

import { get, cloneDeep } from 'lodash';
import { connect, HandleThunkActionCreator } from 'react-redux';
import { Redirect } from 'react-router-dom';

import DocumentSigningList, { DocumentGrouping } from 'components/DocumentSigningList';
import Wrap from 'components/Wrap';
import Title from 'components/page/PageTitle';
import { addToast } from 'store/actionCreators';
import {
  signDocumentApiActionCreator,
  getUserDocumentsToSignActionCreator,
} from 'store/reduxRestApi';
import { Document } from 'types/types';

import { ToastMessages } from './DocumentSigningMessages';

import styles from './DocumentSigning.module.scss';

type DocumentSigningProps = {
  documentsToSign: Document[];
  addToast: typeof addToast;
  signDocumentApiActionCreator: HandleThunkActionCreator<typeof signDocumentApiActionCreator>;
  getUserDocumentsToSignActionCreator: HandleThunkActionCreator<
    typeof getUserDocumentsToSignActionCreator
  >;
};

function groupDocumentsByProject(documentsToSign: Document[]): DocumentGrouping[] {
  return documentsToSign
    .map((document) => ({
      id: document._project!.id,
      name: document._project!.name,
      documents: [{ ...document, accepted: false }],
    }))
    .reduce((docsGrouped, currentDocument) => {
      const groupFound = docsGrouped.find((group) => group.id === currentDocument.id);
      if (groupFound) {
        groupFound.documents = groupFound.documents.concat(currentDocument.documents);
        return docsGrouped;
      }
      docsGrouped.push(currentDocument);
      return docsGrouped;
    }, Array<DocumentGrouping>());
}

const DocumentSigning = ({
  documentsToSign,
  addToast,
  signDocumentApiActionCreator,
  getUserDocumentsToSignActionCreator,
}: DocumentSigningProps) => {
  const [documentGrouping, setDocumentGrouping] = useState(Array<DocumentGrouping>());

  useEffect(() => {
    addToast(ToastMessages.noLongerWorkWarning);
    setDocumentGrouping(groupDocumentsByProject(documentsToSign));
  }, [addToast, documentsToSign]);

  const acceptTerm = useCallback(
    (projectId: number, documentId: number, checked: boolean) => {
      const newDocumentGrouping = cloneDeep(documentGrouping);
      const group = newDocumentGrouping.find((group) => group.id === projectId)!;
      const document = group.documents.find((doc) => doc.id === documentId)!;

      document.accepted = checked;

      setDocumentGrouping(newDocumentGrouping);
    },
    [documentGrouping],
  );

  const confirmTerms = async () => {
    try {
      const documentIds = documentsToSign.map((document) => document.id);
      await signDocumentApiActionCreator({ body: documentIds });
      await getUserDocumentsToSignActionCreator();
    } catch {
      addToast(ToastMessages.updateTermsError);
    }
  };

  return !documentsToSign.length ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <Wrap isWide isFullWidth innerWrapClassName={styles.TitleWrapper}>
        <Title className={styles.Title} isRed>
          <span>By checking the checkboxes, you confirm that you have read, </span>
          <span>understood and accepted the rules listed in the following documents:</span>
        </Title>
      </Wrap>
      <DocumentSigningList
        documentGroups={documentGrouping}
        acceptTerm={acceptTerm}
        allTermsAccepted={confirmTerms}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  documentsToSign: get(state.getUserDocumentsToSign, 'response.data', []),
});

const mapDispatchToProps = {
  addToast,
  signDocumentApiActionCreator,
  getUserDocumentsToSignActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSigning);
