const SvgComponent = (props) => {
  return (
    <svg viewBox="0 0 29 29" width="100%" {...props}>
      <g id="icon_Gauntlet" fill="none" fillRule="evenodd">
        <g id="Banking-info-icon-">
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1" />
          </mask>
        </g>
        <g id="Group-54" transform="translate(5 4)">
          <path
            d="M11.7020122,10.4091061 L19.0389733,10.4091061 L19.8727793,5.04991269 L11.7020122,0.842923244 L11.7020122,10.4091061 Z"
            id="Fill-12"
            stroke="#5A5665"
          />
          <path
            d="M8.7561543,10.4091061 L8.7561543,0.842923244 L0.585387156,5.04991269 L1.41919313,10.4091061 L8.7561543,10.4091061 Z"
            id="Fill-13"
            stroke="#F63A55"
          />
          <path
            d="M11.7020122,13.4459391 L11.7020122,21.4773932 L17.956054,17.3699166 L18.5666025,13.4459391 L11.7020122,13.4459391 Z"
            id="Fill-14"
            stroke="#F63A55"
          />
          <path
            d="M8.7561543,13.4459391 L1.89156394,13.4459391 L2.50211248,17.3699166 L8.7561543,21.4773932 L8.7561543,13.4459391 Z"
            id="Fill-15"
            stroke="#5A5665"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgComponent;
