const SvgComponent = (props) => (
  <svg viewBox="0 0 26 23" width="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h26v26H0z" />
    </defs>
    <g transform="translate(0 -2)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path stroke="#5A5665" mask="url(#b)" d="M8.5 18.5h9v3h-9z" />
      <path
        d="M24.62 13.015l.38.77V24H1V13.635L5.325 6h.659m14.005 0h.556l4.074 7.015h-6.731l-1.368 2.277H9.61l-1.718-2.277H1.714"
        stroke="#5A5665"
        mask="url(#b)"
      />
      <path
        d="M5.982 9.465L6 6.459h14l-.018 3.006h-14zm12.882-3.05H7.212V3h11.652v3.415z"
        stroke="#5A5665"
        mask="url(#b)"
      />
      <path d="M5.5 12.498l16-.049V9.5h-16v2.998z" stroke="#F63A55" mask="url(#b)" />
    </g>
  </svg>
);

export default SvgComponent;
