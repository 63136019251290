const SvgComponent = (props) => (
  <svg viewBox="0 0 22 30" height="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path id="a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M20.787 17.233l.096 4.05c-.666 1.14-1.788 2.725-3.368 4.756v2.54L13 28.562l.024-1.583-2.36.023-.033 1.518-4.605-.006-.015-3.248c-.65-.27-1.809-1.597-3.479-3.981h-.508a1 1 0 0 1-1-1v-2.82a1 1 0 0 1 1-1h.934c.247-1.658 1.107-2.835 2.58-3.531.088-1.341-.218-2.29-.917-2.848-1.05-.836 3.133.005 4.127 1.21l2.302-.146"
        stroke="#5A5665"
        mask="url(#b)"
      />
      <circle stroke="#F63A55" mask="url(#b)" cx={17} cy={13} r={4} />
      <circle stroke="#F63A55" mask="url(#b)" cx={11.5} cy={4.5} r={2.5} />
    </g>
  </svg>
);

export default SvgComponent;
