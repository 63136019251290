import c from 'classnames';

import { ReactComponent as ThinArrowIcon } from 'assets/icons/ThinArrow.svg';

import styles from './ModalBackButton.module.scss';

type ModalBackButtonProps = {
  closeModal: () => void;
  label?: string;
  isSticky?: boolean;
  isVisible?: boolean;
};

const ModalBackButton = ({
  closeModal,
  isVisible,
  isSticky,
  label = 'Go Back',
}: ModalBackButtonProps) => {
  if (!isVisible) return null;

  return (
    <div className={styles.BackButtonWrapper}>
      {isSticky && (
        <div data-testid="modalBackButtonFallbackContent" className={styles.FallbackContentSpace} />
      )}
      <button
        className={c(styles.BackButton, isSticky && styles.StickyWrapper)}
        onClick={closeModal}
      >
        <ThinArrowIcon /> {label}
      </button>
    </div>
  );
};

export default ModalBackButton;
