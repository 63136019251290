import { get } from 'lodash';

import { profileValidationSchema } from 'helpers/validationSchemas';
import { UserApiModel } from 'types/types';
import { isLooselyValidEmail } from 'utils/helpers';

const basicFields = [
  'dateOfBirth',
  'nationality',
  'countryCallingCode',
  'contractingOption',
  'telephone',
];

const fields = [
  ...basicFields,
  '_emergencyContact.name',
  '_emergencyContact.email',
  '_emergencyContact.countryCallingCode',
  '_emergencyContact.telephone',
];

export const hasFilledBasicFields = (user: Partial<UserApiModel>) => {
  return basicFields.every((path) => Boolean(get(user, path)));
};

export const hasUserFilledRequiredFields = (user: Partial<UserApiModel>) => {
  return (
    fields.every((path) => Boolean(get(user, path))) &&
    isLooselyValidEmail(user._emergencyContact?.email ?? '')
  );
};

export const hasUserFilledRequiredProfileFields = (user: Partial<UserApiModel>) => {
  return profileValidationSchema.isValidSync(user, { abortEarly: true });
};

export const hasOnlyContractingOptionsFieldEmpty = (user: Partial<UserApiModel>) => {
  const invalidFields: string[] = [];

  fields.every((path) => {
    if (!Boolean(get(user, path))) {
      invalidFields.push(path);
    }
    return path;
  });

  return invalidFields && invalidFields.length === 1 && invalidFields[0] === 'contractingOption';
};
