import { useState } from 'react';

import c from 'classnames';
import { useFormik } from 'formik';
// @ts-ignore
import { EmojiPicker } from 'react-activity-feed';
import Avatar from 'react-avatar';
import TextareaAutosize from 'react-textarea-autosize';
import * as Yup from 'yup';

import Button from 'components/buttons/Button';
import { useGetPostParticipants } from 'utils/apiMutationHooks';
import { useCurrentUser } from 'utils/hooks';

import { ReactComponent as EmojiIcon } from '../Emoji.svg';
import { Activity, AddReactionCallbackFunction, Emoji } from '../Feed.types';

import styles from './PostComment.module.scss';

const EMOJI_ID = 'emoji_button';

const schema = Yup.object({
  text: Yup.string().trim().required('Required'),
});

const targetFeeds = (
  activity: Activity,
  email?: string,
  participantIds: ReadonlyArray<string | undefined | null> = [],
) => {
  return participantIds
    .filter(
      (participantId) =>
        participantId !== activity.actor.id &&
        participantId !== email?.replace('@', '').replace('.', ''),
    )
    .map((participantId) => `notification:${participantId}`);
};

export const PostComment = ({
  activity,
  onSubmit,
  onSuccess,
  emojiPickerPosition = 'bottom',
}: Props) => {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [getParticipants] = useGetPostParticipants();
  const user = useCurrentUser();

  const formik = useFormik({
    initialValues: { text: '' },
    validationSchema: schema,
    async onSubmit(values, helper) {
      const { text } = values;

      await onSubmit(
        'comment',
        activity,
        {
          text,
          publisherAvatar: user?.avatarUrl,
          teamName: user?._team?.name,
          teamIconLocation: user?._team?.icon?.location,
          // notification properties
          actor: user?.displayName,
          actorAvatar: user?.avatarUrl,
          object: activity.id,
          time: new Date().toISOString(),
          verb: 'comment',
        },
        {
          targetFeeds: [`notification:${activity.actor.id}`],
        },
      );

      const postParticipants = await getParticipants({ params: { postId: activity.id } });

      await onSubmit(
        'notification',
        activity,
        {
          // notification properties
          actor: user?.displayName,
          actorAvatar: user?.avatarUrl,
          object: activity.id,
          time: new Date().toISOString(),
          verb: 'post_user_commented',
        },
        {
          targetFeeds: targetFeeds(activity, user?.email, postParticipants?.participantIds || []),
        },
      );
      helper.resetForm();

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.PostComment}>
      <div className={styles.Field}>
        <TextareaAutosize
          name="text"
          value={formik.values.text}
          onFocus={() => setInputIsFocused(true)}
          onBlur={(e) => {
            if ((e.relatedTarget as HTMLTextAreaElement)?.id === EMOJI_ID) return;

            setInputIsFocused(false);
          }}
          className={c({
            [styles.FocusTextarea]: inputIsFocused || formik.values.text.length > 0,
          })}
          placeholder="Add comment..."
          onChange={formik.handleChange}
        />

        {user && (
          <div className={styles.AvatarContainer}>
            <Avatar
              round
              src={user.avatarUrl}
              name={user.displayName}
              maxInitials={2}
              size="32px"
            />
          </div>
        )}
        <div className={styles.Icon}>
          <EmojiIcon onClick={() => setInputIsFocused(true)} />
        </div>
        <div
          id={EMOJI_ID}
          className={c(
            styles.EmojiPickerButton,
            emojiPickerPosition === 'top' && styles.TopEmojiPicker,
          )}
          role="button"
          tabIndex={0}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
        >
          <EmojiPicker
            onSelect={({ native }: Emoji) =>
              formik.setFieldValue('text', formik.values.text + native, true)
            }
          />
        </div>
        <Button
          className={styles.PostButton}
          type="submit"
          disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
        >
          Post
        </Button>
      </div>
    </form>
  );
};

export type Props = {
  activity: Activity;
  onSubmit: AddReactionCallbackFunction;
  onSuccess?: () => void;
  emojiPickerPosition?: 'top' | 'bottom';
};
