import c from 'classnames';
import { NavLink } from 'react-router-dom';

import coinAnimation from 'assets/coin.gif';
import starIcon from 'assets/stars.png';
import { PROFILE } from 'consts/routing';
import { useVaultStash } from 'contexts/VaultStashContext';

import { User, Bounties } from '../../images';
import { ReactComponent as Refer } from '../../images/refer.svg';

import styles from '../UserProfileMenuOptions/UserProfileMenu.module.scss';

type XVipUserProfileMenuOptionProps = {
  toggleOpened: () => void;
};

export const XVipUserProfileMenuOptions: React.FC<XVipUserProfileMenuOptionProps> = ({
  toggleOpened,
}) => {
  const vaultStash = useVaultStash();

  return (
    <div className={c(styles.MenuSection, styles.Links)}>
      <NavLink
        data-testid="navLinkProfileAccount"
        to={PROFILE.PROFILE_PATH}
        className={styles.Link}
        onClick={toggleOpened}
      >
        <div className={styles.LinkImage}>
          <User />
        </div>
        <div className={styles.LinkText}>My Account</div>
      </NavLink>
      <NavLink to={PROFILE.BOUNTIES_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Bounties />
        </div>
        <div className={styles.LinkText}>Collected Bounties</div>
      </NavLink>

      <NavLink to={PROFILE.REFER_PATH} className={styles.Link} onClick={toggleOpened}>
        <div className={styles.LinkImage}>
          <Refer />
        </div>
        <div className={styles.LinkText}>Refer</div>
      </NavLink>

      <div className={styles.AddInfo}>
        <div className={styles.AddInfoItem}>
          <div className={styles.UnleashText}>Coin stash:</div>
          <div>
            {vaultStash.coins}{' '}
            <img src={coinAnimation} className={styles.CoinAnimation} alt="coin animation" />
          </div>
        </div>
        <div className={styles.AddInfoItem}>
          <div className={styles.UnleashText}>Star stash:</div>
          <div>
            {vaultStash.stars}{' '}
            <img src={starIcon} className={styles.CoinAnimation} alt="star icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
