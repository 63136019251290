import { UploaderOptions, MediaMeta, MediaProgress } from '../Feed.types';

const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const cloudinaryUploadPreset = 'ml_default';
const cloudinaryApiKey = process.env.REACT_APP_CLOUDINARY_API_KEY || '';

export interface CloudinaryResponse {
  access_mode?: string;
  asset_id?: string;
  bytes: number;
  format: string;
  existing?: boolean;
  height: number;
  width: number;
  original_filename?: string;
  public_id?: string;
  resource_type: string;
  metadata?: {};
  secure_url: string;
}

export interface SignatureData {
  signature: string;
  timestamp: string;
  mediaTags: string;
  publicId: string;
}

export interface UploadedMedia {
  file: File;
  publicId: string;
  type: string;
  meta: MediaMeta;
}

export interface Feed {
  text: string;
  title?: string;
  bounty?: number;
  objective?: number;
  isPinned?: boolean;
  mediaPreset?: string;
  mediaImages?: string[];
  mediaVideos?: string[];
  mediaImagesMeta?: MediaMeta[];
  mediaVideosMeta?: MediaMeta[];
}

export const uploadPostHelper = async (options: UploaderOptions) => {
  const uploadedMedia = await uploadMedia(options);
  return createFeedPost(options, uploadedMedia);
};

export const createFeedPost = async (options: UploaderOptions, uploadedMedia: UploadedMedia[]) => {
  const feed: Feed = {
    ...options.post,
    isPinned: options.isPinned,
  };

  if (options.bountyOrObjective?.bounty) {
    feed.bounty = options.bountyOrObjective.id;
  }

  if (options.bountyOrObjective?.bounty === false) {
    feed.objective = options.bountyOrObjective?.id;
  }

  if (uploadedMedia.length) {
    feed.mediaPreset = cloudinaryUploadPreset;
    feed.mediaImages = getMediaPublicIds(uploadedMedia, 'image');
    feed.mediaVideos = getMediaPublicIds(uploadedMedia, 'video');
    feed.mediaImagesMeta = getMediaMeta(uploadedMedia, 'image');
    feed.mediaVideosMeta = getMediaMeta(uploadedMedia, 'video');
  } else {
    feed.mediaImages = [];
    feed.mediaImagesMeta = [];
  }

  if (!options.activity) {
    return createActivity(options, feed);
  }

  return updateActivity(options, feed);
};

export const getMediaPublicIds = (uploadedMedia: UploadedMedia[], type: string) =>
  uploadedMedia.filter((media) => media.type === type).map((media) => media.publicId);

export const getMediaMeta = (uploadedMedia: UploadedMedia[], type: string) =>
  uploadedMedia.filter((media) => media.type === type).map((media) => media.meta);

export const uploadMedia = async (options: UploaderOptions) => {
  const uploadedMedia: UploadedMedia[] = [];

  if (options.mediaFiles?.length) {
    const tags = ['feed'];
    if (options.bountyOrObjective?.bounty) tags.push('bounty');

    const mediaTags = tags.join(',');

    const progress: MediaProgress[] = [];

    const promises = [...options.mediaFiles].map(async (file, index) => {
      const mediaProgress: MediaProgress = {
        file,
        total: 0,
        loaded: 0,
        progress: 0,
      };

      progress.push(mediaProgress);

      const signatureOptions = {
        publicId: `${file.name}-${index + 1}`,
        mediaTags,
        uploadPreset: cloudinaryUploadPreset,
      };

      const responseSignature = await options.generateCloudinaryAuthSignature({
        body: signatureOptions,
      });

      const signatureData = {
        signature: responseSignature.signature,
        timestamp: responseSignature.timestamp,
        publicId: signatureOptions.publicId,
        mediaTags,
      };

      const response = (await fileUpload(
        file,
        signatureData,
        options,
        mediaProgress,
        progress,
      )) as CloudinaryResponse;

      uploadedMedia.push({
        file,
        publicId: signatureOptions.publicId,
        type: response.resource_type,
        meta: {
          bytes: response.bytes,
          format: response.format,
          height: response.height,
          width: response.width,
        },
      });
    });

    await Promise.all(promises).catch(() => {
      throw new Error('Error uploading Media.');
    });
  }

  return uploadedMedia;
};

// https://cloudinary.com/documentation/image_upload_api_reference#upload_method
export const fileUpload = async (
  file: File,
  signatureData: SignatureData,
  options: UploaderOptions,
  mediaProgress: MediaProgress,
  progress: MediaProgress[],
) => {
  return new Promise((resolve, reject) => {
    const url = `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.upload.addEventListener('progress', (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
      mediaProgress.total = event.total;
      mediaProgress.loaded = event.loaded;
      mediaProgress.progress = Math.round((event.loaded * 100.0) / event.total);
      if (options.onMediaProgress) options.onMediaProgress(progress);
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 0) {
        reject(xhr.responseText);
      }
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // File uploaded successfully
          const response = JSON.parse(xhr.responseText) as CloudinaryResponse;
          resolve(response);
          // const url = response.secure_url;
        } else {
          reject(xhr.statusText);
        }
      }
    };

    fd.append('upload_preset', cloudinaryUploadPreset);
    fd.append('tags', signatureData.mediaTags); // TODO: For grouping
    fd.append('public_id', signatureData.publicId);

    fd.append('api_key', cloudinaryApiKey);
    fd.append('timestamp', signatureData.timestamp);
    fd.append('signature', signatureData.signature);
    fd.append('file', file);
    xhr.send(fd);
  });
};

const createActivity = (options: UploaderOptions, feed: Feed) => {
  return options.submitFeedPost({ body: feed }, options.mutationOptions);
};

const updateActivity = (options: UploaderOptions, feed: Feed) => {
  return options.updatePinnedFeedPost(
    {
      params: { postId: options.activity?.id },
      body: feed,
    },
    options.mutationOptions,
  );
};
