const Cv = (props) => (
  <svg viewBox="0 0 23 27" height="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M4.20784 10.9111H8.57258C8.7835 10.9111 8.95449 10.7401 8.95449 10.5292V9.41183C8.95439 8.6543 8.59889 7.94071 7.99425 7.48436C8.78987 6.61592 8.75068 5.27223 7.9058 4.45163C7.06092 3.63103 5.71666 3.63103 4.87178 4.45163C4.0269 5.27223 3.98771 6.61592 4.78333 7.48436C4.18011 7.94159 3.8258 8.6549 3.82593 9.41183V10.5292C3.82593 10.7401 3.99692 10.9111 4.20784 10.9111ZM6.39021 4.60538C7.17087 4.60538 7.80373 5.23824 7.80373 6.0189C7.80373 6.79957 7.17087 7.43242 6.39021 7.43242C5.60954 7.43242 4.97669 6.79957 4.97669 6.0189C4.97777 5.23906 5.6095 4.60704 6.38934 4.6056L6.39021 4.60538ZM4.58976 9.41183C4.59083 8.81484 4.91365 8.2648 5.43433 7.97277C6.03622 8.27106 6.74289 8.27106 7.34478 7.97277C7.8665 8.26392 8.19006 8.81437 8.19066 9.41183V10.1473H4.58976V9.41183Z"
        fill="#F63A55"
      />
      <path
        d="M22.2173 0.750001H0.981139C0.770554 0.750482 0.600097 0.92133 0.600098 1.13192V26.2291C0.600098 26.4401 0.771086 26.611 0.982012 26.611H22.2182C22.4291 26.611 22.6001 26.4401 22.6001 26.2291V1.13192C22.6001 1.03047 22.5597 0.9332 22.4879 0.861552C22.4161 0.789904 22.3188 0.749769 22.2173 0.750001ZM21.8354 25.8472H1.36393V1.51383H21.8345L21.8354 25.8472Z"
        fill="#5A5665"
      />
      <path
        d="M3.38191 14.0625H19.8169C20.0278 14.0625 20.1988 13.8915 20.1988 13.6806C20.1988 13.4696 20.0278 13.2986 19.8169 13.2986H3.38191C3.17099 13.2986 3 13.4696 3 13.6806C3 13.8915 3.17099 14.0625 3.38191 14.0625Z"
        fill="#5A5665"
      />
      <path
        d="M3.38191 17.2146H19.8169C20.0278 17.2146 20.1988 17.0436 20.1988 16.8327C20.1988 16.6218 20.0278 16.4508 19.8169 16.4508H3.38191C3.17099 16.4508 3 16.6218 3 16.8327C3 17.0436 3.17099 17.2146 3.38191 17.2146Z"
        fill="#5A5665"
      />
      <path
        d="M3.38191 23.5193H19.8169C20.0278 23.5193 20.1988 23.3483 20.1988 23.1373C20.1988 22.9264 20.0278 22.7554 19.8169 22.7554H3.38191C3.17099 22.7554 3 22.9264 3 23.1373C3 23.3483 3.17099 23.5193 3.38191 23.5193Z"
        fill="#5A5665"
      />
      <path
        d="M3.38191 20.3671H19.8169C20.0278 20.3671 20.1988 20.1961 20.1988 19.9852C20.1988 19.7743 20.0278 19.6033 19.8169 19.6033H3.38191C3.17099 19.6033 3 19.7743 3 19.9852C3 20.1961 3.17099 20.3671 3.38191 20.3671Z"
        fill="#5A5665"
      />
      <path
        d="M11.5992 4.60563H19.8167C20.0276 4.60563 20.1986 4.43464 20.1986 4.22371C20.1986 4.01279 20.0276 3.8418 19.8167 3.8418H11.5992C11.3883 3.8418 11.2173 4.01279 11.2173 4.22371C11.2173 4.43464 11.3883 4.60563 11.5992 4.60563Z"
        fill="#5A5665"
      />
      <path
        d="M11.5992 7.75779H19.8167C20.0276 7.75779 20.1986 7.5868 20.1986 7.37587C20.1986 7.16495 20.0276 6.99396 19.8167 6.99396H11.5992C11.3883 6.99396 11.2173 7.16495 11.2173 7.37587C11.2173 7.5868 11.3883 7.75779 11.5992 7.75779Z"
        fill="#5A5665"
      />
      <path
        d="M11.5992 10.9103H19.8167C20.0276 10.9103 20.1986 10.7393 20.1986 10.5283C20.1986 10.3174 20.0276 10.1464 19.8167 10.1464H11.5992C11.3883 10.1464 11.2173 10.3174 11.2173 10.5283C11.2173 10.7393 11.3883 10.9103 11.5992 10.9103Z"
        fill="#5A5665"
      />
    </g>
  </svg>
);

export default Cv;
