import Button from 'components/buttons/Button';

import { LoadMoreButtonProps } from '../Feed.types';
import { ReactComponent as RefreshIcon } from '../Refresh.svg';

import styles from './FeedPaginatorButton.module.scss';

export const FeedPaginatorButton = (props: LoadMoreButtonProps) => {
  return (
    <Button
      isFullWidth
      isFormBtn
      isGray
      onClick={props.onClick}
      className={styles.FeedPaginatorButton}
      isLoading={props.refreshing}
    >
      <span>Load more activities</span> <RefreshIcon className={styles.RefreshIcon} />
    </Button>
  );
};
