import { forwardRef, MouseEvent } from 'react';

import classNames from 'classnames';

import Button from '../buttons/Button';

import styles from './Youtube.module.scss';

export type YoutubeProps = {
  videoId: string;
  title: string;
  popup?: boolean;
  onClosePopupClick?: (event: MouseEvent<HTMLElement>) => void;
};

const Youtube = forwardRef<HTMLIFrameElement, YoutubeProps>(function Youtube(
  { videoId, title, popup = false, onClosePopupClick },
  ref,
) {
  const videoEmbedClasses = classNames(styles.VideoEmbed, popup && styles.PopupPlayer);
  const closeButtonClasses = classNames(styles.ClosePopupButton, popup && styles.isPlaying);

  return (
    <div className={styles.VideoContainer}>
      <div data-testid="youtubeVideoEmbed" className={videoEmbedClasses}>
        <iframe
          ref={ref}
          id="youtube-iframe"
          seamless
          src={`//www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={title}
        />
        {popup && (
          <Button
            data-testid="popupCloseButton"
            className={closeButtonClasses}
            isClose
            onClick={onClosePopupClick}
          />
        )}
      </div>
    </div>
  );
});

export default Youtube;
