// react elements were created by "svgr"

import Accounting from './Accounting';
import Achievements from './Achievements';
import Achievements2 from './Achievements2';
import Add from './Add';
import Admin from './Admin';
import Answers from './Answers';
import Approvals from './Approvals';
import Banking from './Banking';
import Bets from './Bets';
import Bounties from './Bounties';
import Coin from './Coin';
import Community from './Community';
import Companies from './Companies';
import Contact from './Contact';
import Cv from './Cv';
import Dashboard from './Dashboard';
import Groups from './Groups';
import Help from './Help';
import Invoices from './Invoices';
import Legends from './Legends';
import Logout from './Logout';
import Menu from './Menu';
import Missions from './Missions';
import News from './News';
import Objectives from './Objectives';
import Projects from './Projects';
import Questions from './Questions';
import Raids from './Raids';
import Refer from './Refer';
import Reports from './Reports';
import RunPayments from './RunPayments';
import Settings from './Settings';
import Teams from './Teams';
import Tshirt from './Tshirt';
import Unleash from './Unleash';
import User from './User';
import Users from './Users';
import Vacation from './Vacation';
import Vault from './Vault';
import { default as XVip } from './XVip';
export { default as Menu } from './Menu';
export { default as User } from './User';
export { default as Cv } from './Cv';
export { default as Logout } from './Logout';
export { default as Banking } from './Banking';
export { default as Invoices } from './Invoices';
export { default as Vacation } from './Vacation';
export { default as Coin } from './Coin';
export { default as Bounties } from './Bounties';
export { default as Vault } from './Vault';
export { default as Unleash } from './Unleash';
export { default as Bets } from './Bets';

const menu2Images = {
  Accounting,
  Achievements,
  Achievements2,
  Add,
  Admin,
  Answers,
  Approvals,
  Banking,
  Bets,
  Bounties,
  Coin,
  Community,
  Companies,
  Contact,
  Cv,
  Dashboard,
  Groups,
  Help,
  Invoices,
  Legends,
  Logout,
  Menu,
  Missions,
  News,
  Objectives,
  Projects,
  Questions,
  Raids,
  Refer,
  Reports,
  RunPayments,
  Settings,
  Teams,
  Tshirt,
  Unleash,
  User,
  Users,
  Vacation,
  Vault,
  XVip,
};

export default menu2Images;
