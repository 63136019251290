import styles from './TeamIcon.module.scss';

export interface TeamIconProps {
  teamName?: string | null;
  teamIconLocation?: string | null;
}

const TeamIcon = ({ teamName, teamIconLocation }: TeamIconProps) => {
  if (!teamName || !teamIconLocation) return null;

  return <img src={teamIconLocation} className={styles.TeamIcon} alt={teamName} />;
};

export default TeamIcon;
