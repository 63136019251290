import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Value.module.scss';

type ValueProps = {
  children: ReactNode;
  className?: string;
  isNote?: boolean;
  isPayment?: boolean;
  isPaymentDisplay?: boolean;
};

function Value({ children, className, isNote, isPayment, isPaymentDisplay, ...rest }: ValueProps) {
  const RowClasses = classNames(
    className,
    styles.Value,
    isNote && styles.Value_note,
    isPayment && styles.Value_payment,
    isPaymentDisplay && styles.Value_paymentDisplay,
  );

  return (
    <div className={RowClasses} {...rest}>
      {children}
    </div>
  );
}

export default Value;
