import * as reducers from 'reducers';
import { combineReducers } from 'redux';

import locationReducer from './location';

export const makeRootReducer = (initialState) => {
  const red = {
    location: locationReducer,
    ...reducers,
  };

  // @todo delete this
  for (let i in initialState) {
    if (!red[i]) {
      red[i] = (state) => state;
    }
  }

  return combineReducers(red);
};

export default makeRootReducer;
