const RunPayments = (props) => (
  <svg viewBox="0 0 409 422" style={{ width: '2.3rem', margin: 0 }} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="contract-(2)" fillRule="nonzero">
        <path
          d="M324.988,205.6 L324.988,63.332 C324.987073,61.4758393 324.248967,59.6960404 322.936,58.384 L266.604,2.052 C265.29196,0.739032601 263.512161,0.000926805503 261.656,-2.84217094e-14 L7.32,-2.84217094e-14 C3.45400675,-2.84217094e-14 0.32,3.13400675 0.32,7 L0.32,415 C0.32,418.865993 3.45400675,422 7.32,422 L318,422 C321.865993,422 325,418.865993 325,415 L325,382.116 C371.481446,379.019103 407.618949,340.444459 407.68,293.86 C407.618661,247.270452 371.474813,208.692887 324.988,205.6 Z M268.656,23.9 L301.092,56.332 L268.656,56.332 L268.656,23.9 Z M14.32,408 L14.32,14 L254.656,14 L254.656,63.332 C254.654938,65.1888408 255.392095,66.9699353 256.70508,68.2829202 C258.018065,69.5959051 259.799159,70.333062 261.656,70.332 L310.988,70.332 L310.988,205.8 C265.523072,210.050217 230.765271,248.208842 230.765271,293.872 C230.765271,339.535158 265.523072,377.693783 310.988,381.944 L310.988,408.012 L14.32,408 Z M244.744,293.852001 C244.748418,252.726023 278.090021,219.389792 319.215999,219.392001 C360.341978,219.39421 393.679999,252.734021 393.68,293.86 C393.633696,334.97303 360.313037,368.28832 319.2,368.328 C278.074022,368.321373 244.739582,334.97798 244.744,293.852001 Z"
          id="Shape"
          fill="#5A5665"
        />
        <path
          d="M334.2,271.936 C334.2,275.801993 337.334007,278.936 341.2,278.936 C345.065993,278.936 348.2,275.801993 348.2,271.936 C348.175686,258.643761 339.103373,247.075737 326.2,243.884 L326.2,234.528 C326.2,230.662007 323.065993,227.528 319.2,227.528 C315.334007,227.528 312.2,230.662007 312.2,234.528 L312.2,243.92 C299.350816,247.139531 290.339274,258.687607 290.339274,271.934 C290.339274,285.180393 299.350816,296.728469 312.2,299.948 L312.2,328.884 C307.351578,326.3155 304.310622,321.2867 304.288,315.8 C304.288,311.934007 301.153993,308.8 297.288,308.8 C293.422007,308.8 290.288,311.934007 290.288,315.8 C290.325969,329.051194 299.35674,340.584745 312.212,343.8 L312.212,353.172 C312.212,357.037993 315.346007,360.172 319.212,360.172 C323.077993,360.172 326.212,357.037993 326.212,353.172 L326.212,343.8 C339.090547,340.600738 348.132349,329.037975 348.132349,315.768 C348.132349,302.498025 339.090547,290.935262 326.212,287.736 L326.212,258.8 C331.105665,261.357432 334.180808,266.414401 334.2,271.936 L334.2,271.936 Z M304.352,271.936 C304.371718,266.467674 307.390005,261.45102 312.212,258.872 L312.212,285 C307.390632,282.420321 304.372594,277.404081 304.352,271.936 L304.352,271.936 Z M334.136,315.8 C334.117801,321.29529 331.070751,326.33269 326.212,328.9 L326.212,302.7 C331.070119,305.26798 334.116915,310.304959 334.136,315.8 L334.136,315.8 Z"
          id="Shape"
          fill="#F63A55"
        />
        <path
          d="M210.656,236.696 L64.336,236.696 C60.4700068,236.696 57.336,239.830007 57.336,243.696 C57.336,247.561993 60.4700068,250.696 64.336,250.696 L210.656,250.696 C214.521993,250.696 217.656,247.561993 217.656,243.696 C217.656,239.830007 214.521993,236.696 210.656,236.696 Z"
          id="Path"
          fill="#5A5665"
        />
        <path
          d="M64.336,292.668 L152,292.668 C155.865993,292.668 159,289.533993 159,285.668 C159,281.802007 155.865993,278.668 152,278.668 L64.336,278.668 C60.4700068,278.668 57.336,281.802007 57.336,285.668 C57.336,289.533993 60.4700068,292.668 64.336,292.668 L64.336,292.668 Z"
          id="Path"
          fill="#5A5665"
        />
        <path
          d="M210.656,320.64 L64.336,320.64 C60.4700068,320.64 57.336,323.774007 57.336,327.64 C57.336,331.505993 60.4700068,334.64 64.336,334.64 L210.656,334.64 C214.521993,334.64 217.656,331.505993 217.656,327.64 C217.656,323.774007 214.521993,320.64 210.656,320.64 Z"
          id="Path"
          fill="#5A5665"
        />
        <path
          d="M152,362.6 L64.336,362.6 C60.4700068,362.6 57.336,365.734007 57.336,369.6 C57.336,373.465993 60.4700068,376.6 64.336,376.6 L152,376.6 C155.865993,376.6 159,373.465993 159,369.6 C159,365.734007 155.865993,362.6 152,362.6 Z"
          id="Path"
          fill="#5A5665"
        />
        <path
          d="M64.336,204 L261.656,204 C265.521993,204 268.656,200.865993 268.656,197 L268.656,97.192 C268.656,93.3260068 265.521993,90.192 261.656,90.192 L64.336,90.192 C60.4700068,90.192 57.336,93.3260068 57.336,97.192 L57.336,197 C57.336,200.865993 60.4700068,204 64.336,204 Z M71.336,104.2 L254.656,104.2 L254.656,190 L71.336,190 L71.336,104.2 Z"
          id="Shape"
          fill="#5A5665"
        />
      </g>
      <path
        d="M238,293.488327 C238.006447,248.4794 274.496908,211.996777 319.505836,212 C364.514763,212.003223 400.999999,248.491071 401,293.499999 C400.946908,338.496033 364.478532,374.956576 319.482488,375 C274.473561,374.990329 237.993553,338.497254 238,293.488327 Z"
        id="Path"
        stroke="#F63A55"
        strokeWidth="15"
      />
    </g>
  </svg>
);

export default RunPayments;
