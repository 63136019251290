const SvgComponent = (props) => (
  <svg viewBox="0 0 28 20" width="100%" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14 5.5h10.445M14 9.5h10.445M14 13.5h10.445"
        stroke="#555260"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM11 14H3c0-2.21 1.773-4 3.96-4h.08C9.226 10 11 11.79 11 14z"
        stroke="#F63A55"
      />
      <path stroke="#5A5665" d="M.5.5h27v19H.5z" />
    </g>
  </svg>
);

export default SvgComponent;
