import c from 'classnames';

import CommunityDashboard from 'components/Home/CommunityDashboard';

import styles from './styles/Home.module.scss';

const Home = () => {
  return (
    <div data-testid="homeContainer" className={c(styles.Root, styles.CommunityBackground)}>
      <CommunityDashboard />
    </div>
  );
};

export default Home;
